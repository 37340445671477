// Main footer styles
// =============================================================================
.mn-ftr {
	&_bdy {
		background-color: $siteCol-f-wht;
	}
	&_inr {
		@extend %extend--inner-box-1200;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_bdy {
			padding: #{2.5 / $baseMblFz + 0em} 0 #{3 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_bdy {
			padding: #{4 / $basePcFz + 0em} 0 #{5 / $basePcFz + 0em};
		}
	}
}

.rct-f-cnt-sct {
	border-top: 1px solid $siteCol-gry02;
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: #{2.5 / $baseMblFz + 0em};
		padding-top: #{3 / $baseMblFz + 0em};
		&_inr {
			&:nth-child(n+2) {
				margin-top: #{1.8 / $baseMblFz + 0em};
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-top: #{3.5 / $basePcFz + 0em};
		padding-top: #{4 / $basePcFz + 0em};
		display: flex;
		justify-content: space-between;
		&_inr {
			width: 100%;
			&.-min {
				max-width: #{27 / $basePcFz + 0em};
				flex-shrink: 0;
			}
			&.-lrg {
				padding: #{1 / $basePcFz + 0em} 0 0 75 / 1200 * 100%;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_inr {
			&.-lrg {
				padding-left: 80 / 970 * 100%;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_inr {
			&.-min {
				width: 240 / 591 * 100%;
			}
		}
	}
}

.rct-f-cnt-lst {
	line-height: 1.5;
	display: flex;
	&_trm {
		color: $siteCol-blu01;
		display: flex;
		align-items: center;
		&::after {
			content: '';
			width: 100%;
			height: 1px;
			margin-left: #{1 / $basePcFz + 0em};
			background-color: currentColor;
			opacity: 0.75;
			display: none;
		}
		&-txt {
			display: block;
			flex-shrink: 0;
		}
	}
	&_dsc {
		margin-top: #{1.2 / $basePcFz + 0em};
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		flex-wrap: wrap;
		justify-content: space-between;
		&_itm {
			width: calc((100% - #{2 / $baseMblFz + 0em}) / 2);
			&:nth-child(n+3) {
				margin-top: #{1.8 / $baseMblFz + 0em};
			}
		}
		&_trm {
			&::after {
				display: block;
			}
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		display: block;
		&_itm {
			width: 100%;
			&:nth-child(n+2) {
				margin-top: #{1.8 / $baseMblFz + 0em};
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		justify-content: flex-end;
		&_itm {
			&:nth-child(n+2) {
				margin-left: 75 / 855 * 100%;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		display: block;
		&_itm {
			&:nth-child(n+2) {
				margin: #{2 / $basePcFz + 0em} 0 0;
			}
		}
		&_trm {
			&::after {
				display: block;
			}
		}
	}
}

.rct-f-cnt-inr-lst {
	font-size: #{1.2 / $basePcFz + 0em};
	line-height: 1.5;
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_itm {
			&:nth-child(n+2) {
				margin-top: #{1.2 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		&_itm {
			width: calc((100% - #{2 / 1.05 + 0em}) / 2);
			&:nth-child(n+2) {
				margin-top: 0;
			}
			&:nth-child(n+3) {
				margin-top: #{1.2 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_itm {
			&:nth-child(n+2) {
				margin-top: #{1.2 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		display: flex;
		flex-wrap: wrap;
		&_itm {
			width: calc((100% - 2em * 2) / 3);
			&:nth-child(n+2) {
				margin: 0 0 0 2em;
			}
			&:nth-child(n+4) {
				margin-top: #{1.2 / $basePcFz + 0em};
			}
			&:nth-child(3n+1) {
				margin-left: 0em;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		justify-content: space-between;
		&_itm {
			width: calc((100% - 2em) / 2);
			&:nth-child(n+2) {
				margin: 0;
			}
			&:nth-child(n+3) {
				margin-top: #{1.2 / $basePcFz + 0em};
			}
		}
	}
}

.rct-f-stlt-unt {
	line-height: 1.5;
	display: none;
	&_crp {
		font: {
			style: italic;
			weight: $fwNsnsJp-bold;
		}
		&-lnk {
			&::after {
				color: $siteCol-blu01;
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: #{2.5 / $baseMblFz + 0em};
		.-lrg & {
			display: block;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-top: #{2 / $basePcFz + 0em};
		.-min & {
			display: block;
		}
	}
}

.rct-f-stlt-lst {
	font-size: #{1.2 / $basePcFz + 0em};
	line-height: 1.5;
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: 2 / 1.05 + 0em;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		&_itm {
			width: calc((100% - #{2 / 1.05 + 0em}) / 2);
			&:nth-child(n+3) {
				margin-top: #{1.2 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-top: 4 / 1.2 + 0em;
		.sns-acct + & {
			margin-top: 2.5 / 1.2 + 0em;
		}
		&_itm {
			&:nth-child(n+2) {
				margin-top: #{1.2 / $basePcFz + 0em};
			}
		}
	}
}

.rct-f-add-lst {
	width: 100%;
	margin-top: #{6.5 / $basePcFz + 0em};
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: #{3 / $basePcFz + 0em};
	&.-inr {
		margin-top: 0;
	}
	&.-sgk-os {
		position: relative;
		&::before {
			content: 'SUGIKOは下記スポーツチームの\Aオフィシャルサポーターです';
			width: 100%;
			font: {
				size: #{1 / $basePcFz + 0em};
				weight: $fwNsnsJp-bold;
			}
			line-height: 1.3;
			text-align: center;
			white-space: pre;
			display: block;
			position: absolute;
			left: 0;
			bottom: calc(100% + 0.5em);
		}
	}
	&_lnk {
		width: #{8 / $basePcFz + 0em};
		display: block;
		&.-hbp {
			width: #{20 / $basePcFz + 0em};
		}
	}
	&_cr {
		margin-top: 0.75em;
		font-size: #{1 / $basePcFz + 0em};
		line-height: 1;
		display: block;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		font-size: clamp(1.2rem, #{14 / 425 * 100vw}, 1.4rem);
		.rct-f-cnt-sct_inr & {
			display: none;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		display: none;
		justify-content: flex-end;
		.rct-f-cnt-sct_inr & {
			display: flex;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		display: flex;
		justify-content: center;
		.rct-f-cnt-sct_inr & {
			display: none;
		}
	}
}

.fxd-ftr {
	width: 100%;
	background-color: $siteCol-f-wht;
	display: flex;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 2000;
	backface-visibility: hidden;
	.is-actv & {
		transform: translate3d(0, 100%, 0) !important;
	}
	.is-load & {
		transition:
			opacity $ovrDur $easeOutQuint,
			visibility $ovrDur $easeOutQuint,
			transform $ovrDur $easeOutQuint;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		height: $mbl-fxd-ftr-hgt;
		padding: #{0.6 / $baseMblFz + 0em};
		& > * {
			&:nth-child(n+2) {
				margin-left: #{0.4 / $baseMblFz + 0em};
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		height: $pc-fxd-ftr-hgt;
		padding: #{0.8 / $basePcFz + 0em};
		transform: translate3d(0, 100%, 0);
		&.is-shw {
			transform: translate3d(0, 0, 0);
		}
		& > * {
			&:nth-child(n+2) {
				margin-left: #{0.6 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		transform: translate3d(0, 0, 0);
	}
}

.cr {
	height: 6 / 1.2 + 0em;
	color: $siteCol-f-wht;
	font-size: #{1.2 / $basePcFz + 0em};
	line-height: 1.3;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}