// device size
$deviceWidthArr: (
	'mbl-s': 374px,
	'mbl-m': 425px,
	'mbl-l': 670px,
	
	'tblt-s': 671px,
	'tblt-m': 860px,
	'tblt-l': 1050px,
	
	'pc-s': 671px,
	'pc-xm': 1240px,
	'pc-m': 1370px,
);

// media queries
// Ex. @include mediaQueries-minW('pc-s') { }
@mixin mediaQueries-minW($value) {
	@media screen and (min-width: map-get($deviceWidthArr, $value)) {
		@content;
	}
}
// Ex. @include mediaQueries-maxW('mbl-l') { }
@mixin mediaQueries-maxW($value) {
	@media screen and (max-width: map-get($deviceWidthArr, $value)) {
		@content;
	}
}
// Ex. @include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { }
@mixin mediaQueries-minW-to-maxW($minWidth, $maxWidth) {
	@media screen and (min-width: map-get($deviceWidthArr, $minWidth)) and (max-width: map-get($deviceWidthArr, $maxWidth)) {
		@content;
	}
}

// general options
$quot: "'";

$baseCenterPos: 50%;

$mbl-pad: 20px;
$tblt-pad: 40px;
$pc-pad: 60px;

$mbl-mdlCls-size: 36px;
$pc-mdlCls-size: 46px;

// site color
$siteCol-ytb-red: #ff0000;
$siteCol-ytb-blk: #212121;
$siteCol-fb-blu: #1877f2;
$siteCol-tw-blu: #1d9bf0;
$siteCol-line-grn: #4cc764;

$siteCol-ig-gra01: #427eff;
$siteCol-ig-gra02: #f13f79;
$siteCol-ig-gra03: #ffdb2c;
$siteCol-ig-gra04: #ff6922;
$siteCol-ig-gra05: #ff5860;

$siteCol-sgk-blu: #16246d;

$siteCol-sdgs-no7: #f8bb06;
$siteCol-sdgs-no11: #f3a121;
$siteCol-sdgs-no12: #d29121;
$siteCol-sdgs-no13: #427837;

$siteCol-red01: red;

$siteCol-pnk01: #fb4b82;

$siteCol-blu01: #1050e2;
$siteCol-blu02: #38679d;
$siteCol-blu03: #0f75be;
$siteCol-blu04: #4082ff;
$siteCol-l-blu01: #1caef7;
$siteCol-l-blu02: #e6eeff;
$siteCol-l-blu03: #657bb5;
$siteCol-l-blu04: #5c7faf;
$siteCol-l-blu05: #234e89;
$siteCol-l-blu06: #b1c0dd;
$siteCol-l-blu07: #b1c9dd;
$siteCol-l-blu08: #cedbf4;
$siteCol-l-blu09: #c1efff;
$siteCol-d-blu01: #031f6f;
$siteCol-d-blu02: #1f2c74;
$siteCol-ash-blu01: #7a87a3;
$siteCol-ash-blu02: #88a3ce;
$siteCol-ash-blu03: #2e4469;

$siteCol-grn01: #1f6500;
$siteCol-l-grn01: #1fd3ac;
$siteCol-d-grn01: #022440;
$siteCol-d-grn02: #3a7e9c;

$siteCol-nvy01: #060d1f;

$siteCol-yel01: #f2b724;

$siteCol-org01: #f0703d;

$siteCol-gry01: #777;
$siteCol-gry02: #bbb;
$siteCol-gry03: #eaeaea;
$siteCol-gry04: #5f5f5f;
$siteCol-gry05: #eae6d8;
$siteCol-gry06: #727f8e;

$siteCol-wht01: #f1f1f1;
$siteCol-blk01: #222;

$siteCol-f-wht: #fff;
$siteCol-k-blk: #000;

// transition duration time
$ovrDur: 450ms;
$mdlDur: 250ms;
$spinnerDur: 1.25s;

// for selection style
// Ex. @include selectionStyle($shadowCol: #000, $bgCol: #111);
@mixin selectionStyle($shadowCol: none, $bgCol: #b3d4fc) {
	text-shadow: $shadowCol;
	background-color: $bgCol;
}

// font options
$ffTyp01: 'Poppins', 'YakuHanJP_Noto', 'Noto Sans JP', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', YuGothic, '游ゴシック体', 'Yu Gothic', '游ゴシック', Meiryo, 'メイリオ', Osaka, 'MS PGothic', 'ＭＳ Ｐゴシック', sans-serif;
$ffTyp02: 'Font Awesome\ 5 Free', 'YakuHanMP_Noto', 'Noto Serif JP', 'Hiragino Mincho ProN', 'ヒラギノ明朝 ProN W3', YuMincho, '游明朝', 'Yu Mincho', 'HG明朝E', 'MS PMincho', 'ＭＳ Ｐ明朝', serif;
$ffTyp03: 'Font Awesome\ 5 Free', 'YakuHanJP', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'Noto Sans Japanese', YuGothic, '游ゴシック体', 'Yu Gothic', '游ゴシック', Meiryo, 'メイリオ', Osaka, 'MS PGothic', 'ＭＳ Ｐゴシック', sans-serif;

$fz8: 0.8rem;
$fz9: 0.9rem;
$fz10: 1rem;
$fz11: 1.1rem;
$fz12: 1.2rem;
$fz13: 1.3rem;
$fz14: 1.4rem;
$fz15: 1.5rem;
$fz16: 1.6rem;
$fz17: 1.7rem;
$fz18: 1.8rem;
$fz19: 1.9rem;
$fz20: 2rem;
$fz21: 2.1rem;
$fz23: 2.3rem;
$fz25: 2.5rem;
$fz27: 2.7rem;
$fz29: 2.9rem;
$fz30: 3rem;
$fz31: 3.1rem;
$fz32: 3.2rem;
$fz33: 3.3rem;
$fz34: 3.4rem;
$fz35: 3.5rem;
$fwn: 400;
$fwb: 600;

// base font size
// Ex-01. font-size: #{$baseMblFz}rem;
// Ex-02. @include baseFontSize($fz15);
$baseMblFz: 1.4;
$basePcFz: 1.6;

@mixin baseFontSize($value) {
	font-size: $value;
}

// font weight for NotoSansJP
// Ex. font-weight: $fwNsnsJp-thin;
$fwNsnsJp-thin: 100;
$fwNsnsJp-light: 300;
$fwNsnsJp-regular: 400;
$fwNsnsJp-medium: 500;
$fwNsnsJp-bold: 700;
$fwNsnsJp-black: 900;

// font weight for NotoSerifJP
// Ex. font-weight: $fwNsrfJp-exlight;
$fwNsrfJp-exlight: 200;
$fwNsrfJp-light: 300;
$fwNsrfJp-regular: 400;
$fwNsrfJp-medium: 500;
$fwNsrfJp-smbold: 600;
$fwNsrfJp-bold: 700;
$fwNsrfJp-black: 900;

$baseFwn: $fwNsnsJp-medium;
$baseFwb: $fwNsnsJp-bold;

// base line height
// Ex. line-height: $baseLh;
$baseLh: 1.9;

// notes common styles
@mixin ntsCmnStyl($val: 0.3) {
	color: $siteCol-gry01;
	font: {
		size: #{($basePcFz - $val) / $basePcFz + 0em};
		weight: $fwNsnsJp-bold;
	}
	line-height: 1.5;
}

// for background image
// Ex. background-image: imgUrl('value.jpg');
$imgPath: '../img/';
@function imgUrl($fileName) {
	@return url(#{$imgPath}#{$fileName});
}

// icomoon options
$icmnId: fmumff;

@mixin useIcomoon {
	font: {
		family: 'icomoon';
		style: normal;
		weight: normal;
		variant: normal;
	}
	line-height: 1;
	speak: none;
	text-transform: none;
}

$icnArr: (
	'blnk': \e900,
	'arw': \e901,
	'crs': \e902,
	'lst': \e903,
);

// for background gradient
// Ex. @include bgGradOpt($baseCol: rgba(255, 255, 255, 1), $stratCol: rgba(255, 255, 255, 1), $endCol: rgba(0, 0, 0, 1), $deg: 0deg, $stratPos: 100%, $endPos: 0%);
@mixin bgGradOpt($baseCol: rgba(255, 255, 255, 1), $stratCol: rgba(255, 255, 255, 1), $endCol: rgba(0, 0, 0, 1), $deg: 0deg, $stratPos: 100%, $endPos: 0%) {
	background: {
		color: $baseCol;
		image: linear-gradient(
			$deg,
			$endCol $endPos,
			$stratCol $stratPos
		);
	}
}

// css transition easing
// Ex. transition: opacity 600ms $easeInSine;
$easeInSine: cubic-bezier(0.12, 0, 0.39, 0);
$easeOutSine: cubic-bezier(0.61, 1, 0.88, 1);
$easeInOutSine: cubic-bezier(0.37, 0, 0.63, 1);

$easeInQuad: cubic-bezier(0.11, 0, 0.5, 0);
$easeOutQuad: cubic-bezier(0.5, 1, 0.89, 1);
$easeInOutQuad: cubic-bezier(0.45, 0, 0.55, 1);

$easeInCubic: cubic-bezier(0.32, 0, 0.67, 0);
$easeOutCubic: cubic-bezier(0.33, 1, 0.68, 1);
$easeInOutCubic: cubic-bezier(0.65, 0, 0.35, 1);

$easeInQuart: cubic-bezier(0.5, 0, 0.75, 0);
$easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);
$easeInOutQuart: cubic-bezier(0.76, 0, 0.24, 1);

$easeInQuint: cubic-bezier(0.64, 0, 0.78, 0);
$easeOutQuint: cubic-bezier(0.22, 1, 0.36, 1);
$easeInOutQuint: cubic-bezier(0.83, 0, 0.17, 1);

$easeInExpo: cubic-bezier(0.7, 0, 0.84, 0);
$easeOutExpo: cubic-bezier(0.16, 1, 0.3, 1);
$easeInOutExpo: cubic-bezier(0.87, 0, 0.13, 1);

$easeInCirc: cubic-bezier(0.55, 0, 1, 0.45);
$easeOutCirc: cubic-bezier(0, 0.55, 0.45, 1);
$easeInOutCirc: cubic-bezier(0.85, 0, 0.15, 1);

$easeInBack: cubic-bezier(0.36, 0, 0.66, -0.56);
$easeOutBack: cubic-bezier(0.34, 1.56, 0.64, 1);
$easeInOutBack: cubic-bezier(0.68, -0.6, 0.32, 1.6);

// base contents width 01
// Ex. @include mainCntWidth01($maxWidth: 980px);
@mixin mainCntWidth01($maxWidth: 1000px) {
	max-width: $maxWidth;
	margin: 0 auto;
}

// base contents width 02
// Ex. @include mainCntWidth02($width: calc(100% - 24px), $maxWidth: 980px);
@mixin mainCntWidth02($width: calc(100% - 40px), $maxWidth: 1000px) {
	width: $width;
	max-width: $maxWidth;
}

// base contents width
// Ex. @include cntCenter(0px 20px);
@mixin cntCenter($value: 0 auto) {
	margin: $value;
}

// other options
@mixin txtMargin($baseMargin: 0px, $inheritFz: 1em, $inheritLh: $baseLh) {
	margin: calc(#{$baseMargin} - (((#{$inheritFz} * #{$inheritLh}) - #{$inheritFz}) / 2)) 0;
}
@mixin txtMt($baseMargin: 0px, $inheritFz: 1em, $inheritLh: $baseLh) {
	margin-top: calc(#{$baseMargin} - (((#{$inheritFz} * #{$inheritLh}) - #{$inheritFz}) / 2));
}
@mixin txtMb($baseMargin: 0px, $inheritFz: 1em, $inheritLh: $baseLh) {
	margin-bottom: calc(#{$baseMargin} - (((#{$inheritFz} * #{$inheritLh}) - #{$inheritFz}) / 2));
}

$mbl-nav-hgt: #{6 / $baseMblFz + 0em};
$pc-nav-hgt: #{9 / $basePcFz + 0em};

$mbl-fxd-ftr-hgt: #{6.2 / $baseMblFz + 0em};
$pc-fxd-ftr-hgt: #{8 / $basePcFz + 0em};