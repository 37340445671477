// General styles
// =============================================================================
.cnt-bdy {
	overflow: hidden;
	position: relative;
}

.nts {
	@include ntsCmnStyl;
	&[data-nts-styl] {
		display: flex;
		align-items: baseline;
		&::before {
			content: attr(data-nts-styl);
			flex-shrink: 0;
		}
	}
	&.-rght,
	&.utl-tar {
		&,
		&[data-nts-styl] {
			text-align: right;
			display: block;
		}
	}
	&.-ctr,
	&.utl-tac {
		&,
		&[data-nts-styl] {
			text-align: center;
			display: block;
		}
	}
	&.-inl,
	&.utl-di {
		&,
		&[data-nts-styl] {
			display: inline;
		}
	}
}

.nts-lst {
	@include ntsCmnStyl;
	&_itm {
		&[data-nts-styl] {
			display: flex;
			align-items: baseline;
			&::before {
				content: attr(data-nts-styl);
				flex-shrink: 0;
			}
		}
		&.-rght,
		&.utl-tar {
			&,
			&[data-nts-styl] {
				text-align: right;
				display: block;
			}
		}
		&.-ctr,
		&.utl-tac {
			&,
			&[data-nts-styl] {
				text-align: center;
				display: block;
			}
		}
	}
}

.blt-lst {
	&.-crr {
		text-align: left;
	}
	&_itm {
		&[data-blt-styl] {
			display: flex;
			align-items: baseline;
			.-crr & {
				padding-right: 0.25em;
				display: inline-flex;
			}
			&::before {
				content: attr(data-blt-styl);
				padding-right: 0.25em;
				flex-shrink: 0;
				.-crr & {
					color: $siteCol-ash-blu01;
				}
			}
		}
		&.-rght,
		&.utl-tar {
			&,
			&[data-nts-styl] {
				text-align: right;
				display: block;
			}
		}
		&.-ctr,
		&.utl-tac {
			&,
			&[data-nts-styl] {
				text-align: center;
				display: block;
			}
		}
	}
}

.ord-lst {
	counter-reset: lst-idx;
	&_itm {
		display: flex;
		align-items: baseline;
		counter-increment: lst-idx;
		&::before {
			content: counter(lst-idx)'.';
			padding-right: 0.25em;
			flex-shrink: 0;
		}
		&.-rght,
		&.utl-tar {
			text-align: right;
			display: block;
		}
		&.-ctr,
		&.utl-tac {
			text-align: center;
			display: block;
		}
	}
}

.sns-lst {
	line-height: 0;
	display: flex;
	align-items: center;
	&_itm {
		&:nth-child(n+2) {
			margin-left: #{1.5 / $basePcFz + 0em};
		}
	}
	&_lnk {
		width: #{2 / $basePcFz + 0em};
		display: block;
	}
}

.sns-acct {
	margin-top: 15px;
	font: {
		style: italic;
		// size: #{1.2 / $basePcFz + 0em};
		size: $fz14;
		weight: $fwNsnsJp-bold;
	}
	line-height: 1.1;
	& + & {
		margin-top: 0.5em;
	}
	&_lnk {
		display: flex;
		align-items: center;
	}
	&_icn {
		width: 30px;
		height: 30px;
		margin-right: 15px;
		line-height: 0;
		border-radius: 100em;
		overflow: hidden;
		display: block;
		flex-shrink: 0;
		position: relative;
		& > * {
			position: relative;
			z-index: 10;
		}
		.-ig & {
			background: {
				image: linear-gradient(
					135deg,
					rgba($siteCol-ig-gra01, 1) 0%,
					rgba($siteCol-ig-gra02, 1) 70%
				);
			}
			&::after {
				content: '';
				width: 80 / 50 * 100%;
				height: 80 / 50 * 100%;
				background: {
					image: radial-gradient(
						rgba($siteCol-ig-gra03, 0.8) 10%,
						rgba($siteCol-ig-gra04, 0.65) 55%,
						rgba($siteCol-ig-gra05, 0.1) 70%
					);
				}
				display: block;
				position: absolute;
				top: 15 / 50 * 100%;
				left: -22 / 50 * 100%;
				z-index: 0;
			}
		}
		.-ig-sld & {
			border-radius: 0;
		}
	}
}

.flx-box {
	display: flex;
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&.-mbl-db {
			display: block;
		}
		&.-after-mbr-lst {
			& > * {
				&:nth-child(2) {
					margin-top: #{3 / $basePcFz + 0em};
				}
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&.-after-mbr-lst {
			align-items: center;
		}
	}
}