// General button styles
// =============================================================================
.btn {
	width: 100%;
	margin: 0 auto;
	color: $siteCol-f-wht;
	font-weight: $fwNsnsJp-bold;
	line-height: 1.5;
	text-align: center;
	background-color: $siteCol-k-blk;
	display: block;
	&.-nws-unt {
		width: #{5.2 / $basePcFz + 0em};
		height: #{5.2 / $basePcFz + 0em};
		color: $siteCol-blu01;
		line-height: 1;
		border-radius: 50%;
		background-color: rgba($siteCol-blu01, 0);
		overflow: hidden;
		position: absolute;
		top: $baseCenterPos;
		right: #{0.8 / $basePcFz + 0em};
		transform: translate3d(0, -#{$baseCenterPos}, 0);
		.is-load & {
			transition: 
				color $ovrDur $easeOutQuint,
				background-color $ovrDur $easeOutQuint;
		}
		.ua-mbl & {
			&:active {
				color: $siteCol-f-wht;
				background-color: rgba($siteCol-blu01, 1);
			}
		}
		body:not(.ua-mbl) & {
			&:hover {
				color: $siteCol-f-wht;
				background-color: rgba($siteCol-blu01, 1);
			}
		}
	}
	&.-ent {
		line-height: 1.3;
		&.-blu {
			background-color: $siteCol-l-blu01;
		}
		&.-grn {
			background-color: $siteCol-l-grn01;
		}
		&.-yel {
			background-color: $siteCol-yel01;
		}
	}
	&.-more {
		max-width: #{14 / $basePcFz + 0em};
		margin: 0;
		color: $siteCol-blu01;
		font-style: italic;
		background-color: transparent;
		&.-wht {
			color: $siteCol-f-wht;
		}
		&.-absl {
			width: 100%;
			height: 100%;
			max-width: none;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 50;
		}
	}
	&.-sqr {
		color: $siteCol-d-blu01;
		border: 2px solid $siteCol-d-blu01;
		background-color: rgba($siteCol-d-blu01, 0);
		.is-load & {
			transition: 
				color $ovrDur $easeOutQuint,
				background-color $ovrDur $easeOutQuint,
				opacity $ovrDur $easeOutQuint;
		}
		&.-blu {
			color: $siteCol-f-wht;
			background-color: $siteCol-d-blu01;
		}
		&.-wht {
			color: $siteCol-f-wht;
			border-color: $siteCol-f-wht;
			background-color: rgba($siteCol-f-wht, 0);
			&.is-actv {
				color: $siteCol-blu01;
				background-color: rgba($siteCol-f-wht, 1);
			}
			.ua-mbl & {
				&:active {
					color: $siteCol-blu01;
					background-color: rgba($siteCol-f-wht, 1);
				}
			}
			body:not(.ua-mbl) & {
				&:hover {
					color: $siteCol-blu01;
					background-color: rgba($siteCol-f-wht, 1);
				}
			}
		}
		&.is-actv {
			color: $siteCol-f-wht;
			background-color: rgba($siteCol-d-blu01, 1);
		}
		.ua-mbl & {
			&:active {
				color: $siteCol-f-wht;
				background-color: rgba($siteCol-d-blu01, 1);
			}
		}
		body:not(.ua-mbl) & {
			&:hover {
				color: $siteCol-f-wht;
				background-color: rgba($siteCol-d-blu01, 1);
			}
		}
	}
	&.-rnd-sqr {
		max-width: #{20 / $basePcFz + 0em};
		color: $siteCol-f-wht;
		border-radius: #{1 / $basePcFz + 0em};
		background-color: $siteCol-d-blu01;
		overflow: hidden;
		&.-sld {
			color: $siteCol-d-blu01;
			border: 1px solid currentColor;
			background-color: transparent;
			.-ft & {
				color: $siteCol-blu03;
			}
			.is-load & {
				transition: background-color $ovrDur $easeOutQuint;
				.btn_lnk {
					transition: color $ovrDur $easeOutQuint;
				}
			}
			.ua-mbl & {
				&:active {
					background-color: currentColor;
					.btn_txt {
						color: $siteCol-f-wht;
					}
				}
			}
			body:not(.ua-mbl) & {
				&:hover {
					background-color: currentColor;
					.btn_lnk {
						color: $siteCol-f-wht;
					}
				}
			}
		}
	}
	&.-d-sqr {
		max-width: #{60 / $basePcFz + 0em};
		background-color: $siteCol-blu01;
	}
	&.-tr-tbl {
		height: #{6 / $basePcFz + 0em};
		margin: 0;
		text-align: justify;
		background-color: $siteCol-l-blu03;
		&.-lrg {
			height: #{8 / $basePcFz + 0em};
		}
		&.-vrl {
			width: #{6 / $basePcFz + 0em};
			height: #{34 / $basePcFz + 0em};
			writing-mode: vertical-rl;
			flex-shrink: 0;
			&.-lrg {
				width: #{8 / $basePcFz + 0em};
			}
		}
		&.-col-blu02 {
			background-color: $siteCol-l-blu04;
		}
		&.-col-blu03 {
			background-color: $siteCol-l-blu05;
		}
	}
	&_lnk,
	&_inr,
	&_wrp {
		width: 100%;
		height: 100%;
		padding: 1em;
		color: currentColor;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&_lnk {
		.-nws-unt & {
			padding: 0;
			&::before {
				content: '\e903';
				font-size: #{2.6 / $basePcFz + 0em};
				@include useIcomoon;
				transform: translate3d(-0.02em, 0, 0);
			}
		}
		.-ent & {
			flex-direction: column;
			position: relative;
		}
		.-ent.-min & {
			min-height: #{3.8 / $basePcFz + 0em};
			padding: 0.25em 0.5em;
			&::after {
				content: '';
				width: calc(50% - 2.75em);
				height: 1px;
				background-color: currentColor;
				display: block;
				position: absolute;
				top: $baseCenterPos;
				right: 0;
				transform: translate3d(0, -#{$baseCenterPos}, 0);
				.fxd-ftr & {
					display: none;
				}
			}
		}
		.-ent.-lrg & {
			padding: #{1 / $basePcFz + 0em};
		}
		.-more & {
			padding: 0;
			.emp-lst_itm & {
				&::after {
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
		.-sqr & {
			padding: 1em 0.5em;
			.ssgl-lst & {
				padding: 1em 1.5em;
			}
		}
		.-rnd-sqr & {
			padding: 0.5em 0.5em 0.5em 1em;
			justify-content: space-between;
			&::after {
				content: '▶';
				display: block;
				transform: translate3d(0, 0.05em, 0);
			}
		}
		.-d-sqr & {
			min-height: #{15 / $basePcFz + 0em};
			padding: #{2.6 / $basePcFz + 0em};
			flex-direction: column;
			position: relative;
			&::after {
				content: '';
				width: calc(100% - #{1 / $basePcFz + 0em} * 2);
				height: calc(100% - #{1 / $basePcFz + 0em} * 2);
				border: 1px solid currentColor;
				display: block;
				position: absolute;
				top: $baseCenterPos;
				left: $baseCenterPos;
				transform: translate3d(-#{$baseCenterPos}, -#{$baseCenterPos}, 0);
			}
		}
		.-tr-tbl & {
			padding: 0.5em 1em;
			justify-content: space-between;
			&::after {
				content: '\e901';
				font-size: #{4 / $basePcFz + 0em};
				@include useIcomoon;
				display: block;
				transform: rotate(90deg);
			}
		}
	}
	&_inr {
		.-more & {
			padding: 0;
			justify-content: flex-start;
			&::after {
				content: '';
				width: 100%;
				height: 1px;
				margin-left: #{1 / $basePcFz + 0em};
				background-color: currentColor;
				display: block;
			}
			.is-load & {
				transition: 
					filter $ovrDur $easeOutQuint,
					transform $ovrDur $easeOutQuint;
			}
		}
		.-more.-lrg & {
			&::after {
				height: 2px;
			}
		}
		.-more.-absl & {
			height: auto;
			max-width: #{14 / $basePcFz + 0em};
			position: absolute;
			right: 0;
			bottom: #{2 / $basePcFz + 0em};
			z-index: 10;
			.int-inr-oth-sct & {
				&::before {
					content: '';
					width: calc(100% + 3em);
					height: 110%;
					background-color: $siteCol-f-wht;
					display: block;
					position: absolute;
					top: $baseCenterPos;
					right: -2em;
					z-index: -1;
					transform: 
						translate3d(0, -#{$baseCenterPos}, 0)
						skew(-16deg);
				}
			}
		}
		.-more:active & {
			.ua-mbl & {
				filter: brightness(125%);
				transform: translate3d(0.25em, 0, 0);
				&::after {
					animation: more-btn-act $ovrDur * 1.2 $easeInOutQuint;
				}
			}
		}
		.-more:hover & {
			body:not(.ua-mbl) & {
				filter: brightness(125%);
				transform: translate3d(0.25em, 0, 0);
				&::after {
					animation: more-btn-act $ovrDur * 1.2 $easeInOutQuint;
				}
			}
		}
	}
	&_txt {
		display: block;
		.-ent & {
			&.-en {
				font-style: italic;
			}
		}
		.-ent.-min & {
			font-size: #{1.1 / $basePcFz + 0em};
			&.-en {
				transform: translate3d(-0.12em, 0, 0);
			}
			.fxd-ftr & {
				font-size: inherit;
			}
		}
		.-ent.-lrg & {
			display: flex;
			align-items: center;
			&.-jp {
				width: 100%;
				height: 100%;
				padding: 0.5em;
				font-size: #{3 / $basePcFz + 0em};
				border: 1px solid currentColor;
				justify-content: center;
				.-nav-mdl & {
					font-size: #{2.4 / $basePcFz + 0em};
				}
			}
			&.-en {
				font-size: #{1.4 / $basePcFz + 0em};
				position: absolute;
				right: 1.1 / 1.4 + 0em;
				bottom: calc(4% + #{1.1 / 1.4 + 0em});
				&::after {
					content: '';
					width: 4 / 1.4 + 0em;
					height: 1px;
					margin-left: 0.6 / 1.4 + 0em;
					background-color: currentColor;
					display: block;
				}
			}
		}
		.-more & {
			flex-shrink: 0;
		}
		.-more.-lrg & {
			font-size: #{2 / $basePcFz + 0em};
		}
		.-d-sqr & {
			display: flex;
			align-items: center;
			&.-lrg {
				font: {
					size: #{3 / $basePcFz + 0em};
					style: italic;
				}
				line-height: 1.1;
			}
			&.-min {
				margin-top: 1 / 2 + 0em;
				font-size: #{2 / $basePcFz + 0em};
			}
			&.-en {
				font: {
					size: #{1.4 / $basePcFz + 0em};
					style: italic;
				}
				position: absolute;
				right: 1.1 / 1.4 + 0em;
				bottom: calc(4% + #{1.1 / 1.4 + 0em});
				&::after {
					content: '';
					width: 4 / 1.4 + 0em;
					height: 1px;
					margin-left: 0.6 / 1.4 + 0em;
					background-color: currentColor;
					display: block;
				}
			}
		}
		.-tr-tbl & {
			font-size: #{2 / $basePcFz + 0em};
		}
		.-tr-tbl.-vrl & {
			margin: auto 0;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&.-d-sqr {
			margin-top: #{4 / $baseMblFz + 0em};
		}
		&_txt {
			.-ent.-lrg & {
				&.-jp {
					min-height: 8.5 / 1.6875 + 0em;
					.-nav-mdl & {
						min-height: 7.5 / 1.35 + 0em;
					}
				}
			}
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		&.-d-sqr {
			font-size: 14 / 425 * 100vw;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&.-d-sqr {
			margin-top: #{8 / $basePcFz + 0em};
		}
		&_txt {
			.-ent.-lrg & {
				&.-jp {
					min-height: 20 / 3 + 0em;
					.-nav-mdl & {
						min-height: 13 / 2.4 + 0em;
					}
				}
			}
			.-sqr & {
				font-size: #{1.8 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&.-d-sqr {
			margin-top: 80 / 860 * 100vw;
		}
	}
}

@keyframes more-btn-act {
	0% {
		transform: scale(1, 1);
		transform-origin: right;
	}
	50% {
		transform: scale(0, 1);
		transform-origin: right;
	}
	50.1% {
		transform: scale(0, 1);
		transform-origin: left;
	}
	100% {
		transform: scale(1, 1);
		transform-origin: left;
	}
}

.menu-btn {
	width: #{4 / $basePcFz + 0em};
	height: #{4 / $basePcFz + 0em};
	margin-left: auto;
	color: $siteCol-f-wht;
	&_lnk {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&_bar {
		height: 3px;
		background-color: currentColor;
		display: block;
		&:nth-child(2) {
			margin: 8px 0;
		}
		&:nth-child(odd) {
			transform: translate3d(-#{3 / 26 * 100%}, 0, 0);
		}
		&:nth-child(even) {
			transform: translate3d(#{3 / 26 * 100%}, 0, 0);
		}
		&-unt {
			width: 26 / 40 * 100%;
			display: block;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-left: auto;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-left: #{3 / $basePcFz + 0em};
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		margin-left: auto;
	}
}

.btn-otr {
	width: 100%;
	&.-ent {
		display: flex;
		justify-content: space-between;
		& > * {
			width: calc((100% - #{1 / $basePcFz + 0em} * 2) / 3);
			margin: 0;
		}
		&.-nav {
			max-width: #{38 / $basePcFz + 0em};
			margin-left: auto;
		}
		&.-ftr {
			max-width: #{122 / $basePcFz + 0em};
			margin: 0 auto;
			padding: 0 #{1 / $basePcFz + 0em};
			position: relative;
			z-index: 0;
			&::after {
				content: '';
				width: 100vw;
				height: 50%;
				background-color: $siteCol-f-wht;
				display: block;
				position: absolute;
				bottom: -1px;
				left: $baseCenterPos;
				z-index: -1;
				transform: translate3d(-#{$baseCenterPos}, 0, 0);
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&.-ent {
			&.-nav {
				display: none;
			}
			&.-nav-mdl,
			&.-ftr {
				font-size: 9 / 425 * 100vw;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&.-ent {
			&.-nav {
				display: none;
			}
			&.-nav-mdl,
			&.-ftr {
				font-size: 16 / 860 * 100vw;
			}
		}
	}
}

.bnr {
	&.-abt {
		overflow: hidden;
		position: relative;
		z-index: 5;
		&::after {
			content: '';
			width: 100%;
			height: 100%;
			background-color: $siteCol-nvy01;
			opacity: 0.4;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
			.is-load & {
				transition: opacity $ovrDur $easeOutQuint;
			}
		}
		.ua-mbl & {
			&:active {
				&::after {
					opacity: 0.6;
				}
			}
		}
		body:not(.ua-mbl) & {
			&:hover {
				&::after {
					opacity: 0.6;
				}
			}
		}
	}
	&_lnk {
		display: block;
	}
}

.frm-btn-otr {
	max-width: #{64 / $basePcFz + 0em};
	margin: #{3 / $basePcFz + 0em} auto 0;
	& > * {
		&:nth-child(n+2) {
			margin-top: #{1.5 / $basePcFz + 0em};
		}
	}
}