// Main navigation styles
// =============================================================================
.mn-nav {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2500;
	&_bdy {
		width: 100%;
		display: flex;
		align-items: center;
		&.-add-bg {
			background: {
				color: $siteCol-d-blu01;
				image: linear-gradient(
					90deg,
					rgba($siteCol-blu01, 0.9) 0%,
					rgba($siteCol-d-blu01, 0.9) 100%
				);
			}
		}
		&.-fxd {
			position: fixed;
			top: 0;
			left: 0;
			transform: translate3d(0, -100%, 0);
			backface-visibility: hidden;
			transition:
				opacity $ovrDur $easeOutQuint,
				visibility $ovrDur $easeOutQuint,
				transform $ovrDur $easeOutQuint;
			&.is-shw {
				transform: translate3d(0, 0, 0);
				.ua-mbl & {
					transition:
						opacity $ovrDur $ovrDur * 0.667 $easeOutQuint,
						visibility $ovrDur $ovrDur * 0.667 $easeOutQuint,
						transform $ovrDur $ovrDur * 0.667 $easeOutQuint;
				}
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_bdy {
			height: $mbl-nav-hgt;
			padding: 0 #{1.5 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_bdy {
			height: $pc-nav-hgt;
			padding: 0 #{3 / $basePcFz + 0em};
		}
	}
}

.nav-mdl {
	width: 100vw;
	height: 100vh;
	color: $siteCol-blk01;
	background-color: rgba($siteCol-f-wht, 0.8);
	opacity: 0;
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9000;
	transform: translate3d(0, 0, 0);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	&.is-opn {
		opacity: 1;
		visibility: visible;
		.is-load & {
			transition:
				opacity $mdlDur $easeOutQuint,
				visibility $mdlDur $easeOutQuint;
		}
	}
	.is-load & {
		transition:
			opacity $mdlDur * 2 $mdlDur / 3 $easeOutQuint,
			visibility $mdlDur * 2 $mdlDur / 3 $easeOutQuint;
	}
	.ua-mbl & {
		height: 100dvh;
	}
	&_bdy {
		height: 100%;
		max-width: #{128 / $basePcFz + 0em};
		margin: 0 auto;
		opacity: 0;
		overflow: scroll;
		position: relative;
		z-index: 10;
		transform: scale(0.75);
		.is-opn & {
			opacity: 1;
			transform: scale(1);
			.is-load & {
				transition:
					opacity $mdlDur $mdlDur / 1.2 $easeOutBack,
					transform $mdlDur $mdlDur / 1.2 $easeOutBack;
			}
		}
		.is-load & {
			transition:
				opacity $mdlDur $easeOutBack,
				transform $mdlDur $easeOutBack;
		}
	}
	&_inr {
		display: flex;
		flex-wrap: wrap;
	}
	&_cls {
		overflow: hidden;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 15;
		cursor: pointer;
		transform: 
			translate3d(-#{$baseCenterPos}, #{$baseCenterPos}, 0)
			rotate(45deg);
		&::before,
		&::after {
			content: '';
			background-color: $siteCol-blu01;
			opacity: 0;
			display: block;
			position: absolute;
			top: $baseCenterPos;
			left: $baseCenterPos;
			transform: translate3d(-#{$baseCenterPos}, -#{$baseCenterPos}, 0);
			.is-opn & {
				opacity: 1;
				.is-load & {
					transition: opacity $mdlDur $mdlDur * 1.8 $easeOutQuint;
				}
			}
			.is-load & {
				transition: opacity $mdlDur $easeOutQuint;
			}
		}
		&::before {
			width: 100%;
			height: 2px;
		}
		&::after {
			width: 2px;
			height: 100%;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_bdy {
			padding: #{3 / $baseMblFz + 0em} #{2 / $baseMblFz + 0em} #{4 / $baseMblFz + 0em};
		}
		&_inr {
			margin-top: #{1.5 / $baseMblFz + 0em};
			flex-direction: column;
			& > * {
				width: 100%;
				&:nth-child(2) {
					margin-top: 3.5 / 0.9 + 0em;
				}
				&:nth-child(3) {
					margin-top: #{3 / $baseMblFz + 0em};
					order: 25;
				}
				&:nth-child(4) {
					margin-top: 25 / 425 * 100vw;
				}
			}
		}
		&_cls {
			width: $mbl-mdlCls-size;
			height: $mbl-mdlCls-size;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_bdy {
			padding: #{4 / $basePcFz + 0em} #{4 / $basePcFz + 0em} #{6 / $basePcFz + 0em};
		}
		&_inr {
			margin-top: #{3 / $basePcFz + 0em};
			& > * {
				&:first-child {
					width: calc(100% - #{25 / $basePcFz + 0em});
					order: 5;
				}
				&:nth-child(2) {
					width: 100%;
					margin-top: #{6 / $basePcFz + 0em};
					order: 10;
				}
				&:nth-child(3) {
					width: #{25 / $basePcFz + 0em};
					flex-shrink: 0;
					order: 0;
				}
				&:nth-child(4) {
					width: 100%;
					margin-top: #{3 / $basePcFz + 0em};
					order: 15;
				}
			}
		}
		&_cls {
			width: $pc-mdlCls-size;
			height: $pc-mdlCls-size;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_inr {
			font-size: 16 / 1050 * 100vw;
			& > * {
				&:first-child {
					width: calc(100% - #{22 / $basePcFz + 0em});
				}
				&:nth-child(3) {
					width: #{22 / $basePcFz + 0em};
				}
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_inr {
			font-size: inherit;
		}
	}
}

.rct-n-cnt-lst {
	line-height: 1.5;
	&_trm {
		color: $siteCol-blu01;
		font-size: #{2 / $basePcFz + 0em};
		display: flex;
		align-items: center;
		cursor: pointer;
		&::after {
			display: block;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_itm {
			border-bottom: 2px solid rgba($siteCol-blu01, 0.15);
		}
		&_trm {
			padding: 2 / 1.75 + 0em 0;
			justify-content: space-between;
			&::after {
				content: '\e902';
				font-size: 2.4 / 1.75 + 0em;
				@include useIcomoon;
				.is-load & {
					transition: transform $ovrDur $easeOutQuint;
				}
			}
			&.is-opn {
				&::after {
					transform: rotate(45deg);
				}
			}
		}
		&_dsc {
			padding: #{0.6 / $baseMblFz + 0em} 0 #{3 / $baseMblFz + 0em};
			display: none;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_itm {
			&:nth-child(n+2) {
				margin-top: #{2.6 / $basePcFz + 0em};
			}
		}
		&_trm {
			pointer-events: none;
			&::after {
				content: '';
				width: 100%;
				height: 1px;
				margin-left: #{1 / $basePcFz + 0em};
				background-color: currentColor;
				opacity: 0.75;
			}
			&-txt {
				display: block;
				flex-shrink: 0;
			}
		}
		&_dsc {
			margin-top: #{1.2 / $basePcFz + 0em};
		}
	}
}

.rct-n-cnt-inr-lst {
	line-height: 1.5;
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_itm {
			&:nth-child(n+2) {
				margin-top: #{1.5 / $baseMblFz + 0em};
			}
		}
		&_lnk {
			display: flex;
			justify-content: space-between;
			&::after {
				content: '\e901';
				font-size: #{2 / $baseMblFz + 0em};
				@include useIcomoon;
				align-self: center;
				transform: translate3d(-#{0.2 / 2 + 0em}, 0, 0);
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		display: flex;
		flex-wrap: wrap;
		&_itm {
			width: calc((100% - 2em * 2) / 3);
			&:nth-child(n+2) {
				margin: 0 0 0 2em;
			}
			&:nth-child(n+4) {
				margin-top: #{1.2 / $basePcFz + 0em};
			}
			&:nth-child(3n+1) {
				margin-left: 0em;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		font-size: #{1.5 / $basePcFz + 0em};
		justify-content: space-between;
		&_itm {
			width: calc((100% - 2em) / 2);
			&:nth-child(n+2) {
				margin: 0;
			}
			&:nth-child(n+3) {
				margin-top: #{1.2 / $basePcFz + 0em};
			}
		}
	}
}

.rct-n-stlt-unt {
	line-height: 1.5;
	&_crp {
		font: {
			style: italic;
			weight: $fwNsnsJp-bold;
		}
		&-lnk {
			&::after {
				color: $siteCol-blu01;
			}
		}
	}
}

.rct-n-stlt-lst {
	font-size: #{1.4 / $basePcFz + 0em};
	line-height: 1.5;
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: 2 / 1.225 + 0em;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		&_itm {
			width: calc((100% - #{2 / 1.225 + 0em}) / 2);
			&:nth-child(n+3) {
				margin-top: #{1.2 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-maxW('mbl-s') { // mobile size = S //
		font-size: 12.25 / 375 * 100vw;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-top: 4 / 1.4 + 0em;
		&_itm {
			&:nth-child(n+2) {
				margin-top: #{1.2 / $basePcFz + 0em};
			}
		}
	}
}

.ssgl-lst {
	max-width: #{124 / $basePcFz + 0em};
	margin: 0 auto;
	display: flex;
	&::before,
	&::after {
		content: '';
		display: block;
		flex-shrink: 0;
	}
	.-network & {
		width: 100%;
		position: relative;
		z-index: 50;
	}
	&-otr {
		background-color: $siteCol-d-blu01;
		overflow: scroll;
		display: flex;
		.-network & {
			background-color: rgba($siteCol-d-blu01, 0.8);
		}
		.-network.-nw-04 & {
			position: relative;
			z-index: 25;
		}
	}
	&_itm {
		min-width: #{14 / $basePcFz + 0em};
		flex-shrink: 0;
		.-welfare & {
			min-width: 0;
		}
	}
	&_lnk {
		display: block;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&::before,
		&::after {
			width: #{1 / $baseMblFz + 0em};;
		}
		&-otr {
			padding: #{1 / $baseMblFz + 0em} 0;
		}
		&_itm {
			.-welfare & {
				width: #{10 / $baseMblFz + 0em};
			}
			&:nth-child(n+2) {
				margin-left: #{1 / $baseMblFz + 0em};
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&::before,
		&::after {
			width: #{2 / $basePcFz + 0em};;
		}
		.-data & {
			width: 95%;
			max-width: #{86 / $basePcFz + 0em};
			flex-wrap: wrap;
			justify-content: center;
			&::before,
			&::after {
				display: none;
			}
		}
		&-otr {
			padding: #{2 / $basePcFz + 0em} 0;
		}
		&_itm {
			.-data & {
				margin: #{0.5 / $basePcFz + 0em};
			}
			.-welfare & {
				width: #{19 / $basePcFz + 0em};
			}
			&:nth-child(n+2) {
				margin-left: #{2 / $basePcFz + 0em};
				.-data & {
					margin-left: #{0.5 / $basePcFz + 0em};
				}
				.-welfare & {
					margin-left: #{1.2 / $basePcFz + 0em};
				}
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_itm {
			.-welfare & {
				width: #{14 / $basePcFz + 0em};
			}
			&:nth-child(n+2) {
				margin-left: #{1 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		.-data & {
			width: 100%;
			max-width: #{124 / $basePcFz + 0em};
			flex-wrap: nowrap;
			justify-content: flex-start;
			&::before,
			&::after {
				display: block;
			}
		}
		&_itm {
			.-data & {
				margin: 0;
			}
			&:nth-child(n+2) {
				.-data & {
					margin-left: #{1 / $basePcFz + 0em};
				}
			}
		}
	}
}