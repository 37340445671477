// Modal styles
// =============================================================================
.mdl {
	width: 100vw;
	height: 100vh;
	opacity: 0;
	visibility: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9000;
	transform: translate3d(0, 0, 0);
	&.is-opn {
		opacity: 1;
		visibility: visible;
		.is-load & {
			transition:
				opacity $mdlDur $easeOutQuint,
				visibility $mdlDur $easeOutQuint;
		}
	}
	.is-load & {
		transition:
			opacity $mdlDur * 2 $mdlDur / 3 $easeOutQuint,
			visibility $mdlDur * 2 $mdlDur / 3 $easeOutQuint;
	}
	&_bdy {
		width: 90vw;
		height: 100%;
		max-width: 1000px;
		opacity: 0;
		overflow: hidden;
		position: relative;
		z-index: 10;
		transform: scale(0.75);
		&.-rnd {
			border-radius: #{0.8 / $basePcFz + 0em};
		}
		.-ytb & {
			height: auto;
			overflow: visible;
			display: flex;
			align-items: center;
		}
		.is-opn & {
			opacity: 1;
			transform: scale(1);
			.is-load & {
				transition:
					opacity $mdlDur $mdlDur / 1.2 $easeOutBack,
					transform $mdlDur $mdlDur / 1.2 $easeOutBack;
			}
		}
		.is-load & {
			transition:
				opacity $mdlDur $easeOutBack,
				transform $mdlDur $easeOutBack;
		}
	}
	&_inr {
		height: 100%;
		background-color: $siteCol-f-wht;
		overflow: scroll;
	}
	&_cls {
		overflow: hidden;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 15;
		cursor: pointer;
		transform: 
			translate3d(-#{$baseCenterPos}, #{$baseCenterPos}, 0)
			rotate(45deg);
		&::before,
		&::after {
			content: '';
			background-color: $siteCol-f-wht;
			opacity: 0;
			display: block;
			position: absolute;
			top: $baseCenterPos;
			left: $baseCenterPos;
			transform: translate3d(-#{$baseCenterPos}, -#{$baseCenterPos}, 0);
			.is-opn & {
				opacity: 1;
				.is-load & {
					transition: opacity $mdlDur $mdlDur * 1.8 $easeOutQuint;
				}
			}
			.is-load & {
				transition: opacity $mdlDur $easeOutQuint;
			}
		}
		&::before {
			width: 100%;
			height: 2px;
		}
		&::after {
			width: 2px;
			height: 100%;
		}
	}
	&_ovrly {
		width: 100%;
		height: 100%;
		background-color: $siteCol-nvy01;
		opacity: 0.9;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;
		transform: translate3d(0, 0, 0);
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_bdy {
			max-height: calc(100% - #{$mbl-mdlCls-size * 4});
		}
		&_cls {
			width: $mbl-mdlCls-size;
			height: $mbl-mdlCls-size;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_bdy {
			max-height: calc(100% - #{$pc-mdlCls-size * 4});
		}
		&_cls {
			width: $pc-mdlCls-size;
			height: $pc-mdlCls-size;
		}
	}
}