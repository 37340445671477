// Main header styles
// =============================================================================
.mn-hdr {
	position: relative;
	&.-top {
		color: $siteCol-f-wht;
		background-color: $siteCol-nvy01;
	}
	&.-sub {
		background-color: $siteCol-f-wht;
	}
	&_bdy {
		@extend %extend--inner-box-1200;
		padding: {
			top: #{2.5 / $basePcFz + 0em};
			bottom: #{3.5 / $basePcFz + 0em};
		}
	}
	&_intr {
		font-weight: $fwNsnsJp-bold;
		line-height: 1.5;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&.-sub {
			padding-top: $mbl-nav-hgt;
		}
		&_intr {
			margin-top: 1em;
			font-size: #{2 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		&_bdy {
			padding: {
				top: 22 / 425 * 100vw;
				bottom: 30 / 425 * 100vw;
			}
		}
	}
	@include mediaQueries-maxW('mbl-s') { // mobile size = S //
		&_intr {
			font-size: 20 / 375 * 100vw;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&.-sub {
			padding-top: $pc-nav-hgt;
		}
		&_bdy {
			.-mbr-top & {
				padding: {
					top: #{8 / $basePcFz + 0em};
					// bottom: #{3.5 / $basePcFz + 0em};
				}
			}
		}
		&_inr {
			.-mbr-top & {
				width: 550 / 1200 * 100%;
				// padding-right: 30 / 1200 * 100%;
			}
		}
		&_intr {
			margin-top: 3.5 / 2.4 + 0em;
			font-size: #{2.4 / $basePcFz + 0em};
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_bdy {
			font-size: 16 / 1050 * 100vw;
			.-mbr-top & {
				// font-size: 16 / 1050 * 100vw;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_bdy {
			// font-size: 16 / 860 * 100vw;
		}
	}
}

.kv-hdr {
	height: 100vh;
	background: {
		image: imgUrl('img_bg_kv.webp');
		repeat: no-repeat;
		position: center top;
		size: cover;
	}
	overflow: hidden;
	position: relative;
	.no-webp & {
		background-image: imgUrl('img_bg_kv.jpg');
	}
	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
	}
	&::before {
		width: 100%;
		height: 100%;
		background: {
			image: imgUrl('img_bg_noise.png');
			position: center;
			size: #{50 / $basePcFz + 0em} #{50 / $basePcFz + 0em};
		}
		mix-blend-mode: soft-light;
		opacity: 0.25;
		top: 0;
		left: 0;
		z-index: 5;
	}
	&::after {
		width: #{160 / $basePcFz + 0em};
		height: #{120 / $basePcFz + 0em};
		background: {
			image: imgUrl('img_kv_sgk_rct.webp');
			repeat: no-repeat;
			position: center top;
			size: cover;
		}
		left: $baseCenterPos;
		z-index: 0;
		transform: translate3d(-#{$baseCenterPos}, 0, 0);
		.no-webp & {
			background-image: imgUrl('img_kv_sgk_rct.png');
		}
	}
	&_ttl {
		width: 100%;
		max-width: #{45 / $basePcFz + 0em};
		line-height: 1;
		position: absolute;
		left: $baseCenterPos;
		z-index: 100;
		transform: translate3d(-#{$baseCenterPos}, 0, 0);
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		max-height: 44 / 0.65 + 0em;
		min-height: 36 / 0.65 + 0em;
		font-size: 6.5 / 425 * 100vw;
		&::after {
			top: 40px;
		}
		&_ttl {
			top: calc(#{12.4 / 0.65 + 0em} + 40px);
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		max-height: #{100 / $basePcFz + 0em};
		min-height: #{74 / $basePcFz + 0em};
		&::after {
			top: #{2 / $basePcFz + 0em};
		}
		&_ttl {
			top: #{32.5 / $basePcFz + 0em};
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		font-size: 16 / 1050 * 100vw;
	}
}

.hdr-nws {
	color: $siteCol-blk01;
	line-height: 1.5;
	display: flex;
	align-items: center;
	&-unt {
		width: 100%;
		padding: #{2 / $basePcFz + 0em} #{7.8 / $basePcFz + 0em} #{2 / $basePcFz + 0em} #{3 / $basePcFz + 0em};
		border: 2px solid $siteCol-blu01;
		border-radius: 100em;
		background-color: $siteCol-f-wht;
		position: absolute;
		z-index: 500;
	}
	&_trm {
		color: $siteCol-gry01;
		font-size: #{1.4 / $basePcFz + 0em};
		flex-shrink: 0;
	}
	&_dsc {
		width: 100%;
		margin-left: #{2 / $basePcFz + 0em};
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&-unt {
			width: calc(100% - #{3 / $baseMblFz + 0em});
			bottom: #{2 / $baseMblFz + 0em};
			left: $baseCenterPos;
			transform: translate3d(-#{$baseCenterPos}, 0, 0);
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&-unt {
			max-width: #{60 / $basePcFz + 0em};
			right: #{3 / $basePcFz + 0em};
			bottom: #{4 / $basePcFz + 0em};
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&-unt {
			width: calc(100% - #{6 / $basePcFz + 0em});
			max-width: none;
			right: auto;
			left: $baseCenterPos;
			transform: translate3d(-#{$baseCenterPos}, 0, 0);
		}
	}
}