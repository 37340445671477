// YpuTube styles
// =============================================================================
%extend {
	&--ytb {
		&-bdy {
			padding-top: 9 / 16 * 100%;
			position: relative;
		}
		&-mov {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

.ytb {
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	border-radius: #{0.5 / $basePcFz + 0em};
	overflow: hidden;
	position: relative;
	z-index: 0;
	&_bdy {
		@extend %extend--ytb-bdy;
	}
	&_mov {
		@extend %extend--ytb-mov;
	}
}

.ytb-thmb {
	max-width: #{86 / $basePcFz + 0em};
	margin: 0 auto;
	color: rgba($siteCol-ytb-blk, 0.8);
	border-radius: #{0.5 / $basePcFz + 0em};
	overflow: hidden;
	position: relative;
	cursor: pointer;
	.is-load & {
		transition: color $ovrDur / 2 $easeOutQuint;
	}
	.ua-mbl & {
		&:active {
			color: $siteCol-ytb-red;
		}
	}
	body:not(.ua-mbl) & {
		&:hover {
			color: $siteCol-ytb-red;
		}
	}
	.sgk-rct-mov & {
		width: 100%;
		height: 100%;
		max-width: none;
		position: absolute;
		top: 0;
		left: 0;
	}
	&_lnk {
		display: block;
		.sgk-rct-mov & {
			width: 100%;
			height: 100%;
			.is-load & {
				transition: background-color $ovrDur / 2 $easeOutQuint;
			}
			.ua-mbl & {
				&:active {
					background-color: rgba($siteCol-nvy01, 0.45);
				}
			}
			body:not(.ua-mbl) & {
				&:hover {
					background-color: rgba($siteCol-nvy01, 0.45);
				}
			}
		}
	}
	&_icn {
		width: #{6.8 / $basePcFz + 0em};
		display: block;
		position: absolute;
		top: $baseCenterPos;
		left: $baseCenterPos;
		z-index: 5;
		transform: translate3d(-#{$baseCenterPos}, -#{$baseCenterPos}, 0);
	}
	&_inf {
		display: block;
		.sgk-rct-mov & {
			position: absolute;
		}
	}
	&_name {
		color: $siteCol-f-wht;
		display: block;
		font-weight: $fwNsnsJp-bold;
		.sgk-rct-mov & {
			margin-top: 1 / 2.8 + 0em;
			font-size: #{2.8 / $basePcFz + 0em};
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_icn {
			.sgk-rct-mov & {
				font-size: 14 / 425 * 100vw;
			}
		}
		&_inf {
			.sgk-rct-mov & {
				font-size: 10 / 425 * 100vw;
				bottom: 8%;
				left: 6%;
			}
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		&_icn {
			.sgk-rct-mov & {
				font-size: inherit;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_icn {
			.sgk-rct-mov & {
				font-size: #{2 / $basePcFz + 0em};
			}
		}
		&_inf {
			.sgk-rct-mov & {
				bottom: 8%;
				left: 6%;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_icn {
			.sgk-rct-mov & {
				font-size: inherit;
			}
		}
		&_inf {
			.sgk-rct-mov & {
				font-size: 16 / 860 * 100vw;
			}
		}
	}
}

.ytb-bg-smpl {
	// height: 100vh;
	margin-top: 5em;
	padding: 2em 1.5em 4em;
	color: $siteCol-f-wht;
	text-align: center;
	overflow: hidden;
	position: relative;
}

.ytb-bg {
	width: 100%;
	height: 9 / 16 * 100vw;
	min: {
		width: 100%;
		height: 100%;
	}
	background-color: $siteCol-nvy01;
	position: absolute;
	top: $baseCenterPos;
	left: $baseCenterPos;
	z-index: -1;
	transform: translate3d(-#{$baseCenterPos}, -#{$baseCenterPos}, 0);
	&-otr {
		overflow: hidden;
		&.-fxd {
			width: 100vw;
			height: 100vh;
			overflow: hidden;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 0;
			transform: translate3d(0, 0, 0);
		}
	}
	&_bdy {
		@extend %extend--ytb-bdy;
	}
	&_mov {
		@extend %extend--ytb-mov;
		opacity: 0.5;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&.-mbl-fxd {
			position: fixed;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&.-pc-fxd {
			position: fixed;
		}
	}
}