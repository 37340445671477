// Window entrance styles
// =============================================================================
.ldr-win {
	width: 100vw;
	height: 100vh;
	color: $siteCol-f-wht;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9000;
	transform: translate3d(0, 0, 0);
	&:after {
		content: '';
		width: 100%;
		height: 100%;
		background-color: $siteCol-f-wht;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		transform: scale(0, 1);
		transform-origin: 0 0;
		.is-load & {
			animation: xBndApprFx 0.6s 0.4s $easeInOutQuint both;
		}
	}
	.is-load & {
		opacity: 0;
		visibility: hidden;
		transition:
			opacity $mdlDur 1.1s $easeOutQuint,
			visibility $mdlDur 1.1s $easeOutQuint;
	}
	&_bdy {
		width: 100%;
		height: 100%;
		background: {
			color: $siteCol-d-blu01;
			image: linear-gradient(
				90deg,
				rgba($siteCol-blu01, 0.9) 0%,
				rgba($siteCol-d-blu01, 0.9) 100%
			);
		}
		position: relative;
		&:before {
			content: '';
			width: 100%;
			height: 100%;
			background: {
				image: imgUrl('img_bg_noise.png');
				position: center;
				size: #{50 / $basePcFz + 0em} #{50 / $basePcFz + 0em};
			}
			mix-blend-mode: soft-light;
			opacity: 0.3;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
		}
		.is-load & {
			animation: xBndItmApprFx 0.6s 0.4s $easeInOutQuint both reverse;
		}
	}
}

.loader {
	// font-size: #{2 / $basePcFz + 0em};
	line-height: 1;
	text-align: center;
	position: absolute;
	top: $baseCenterPos;
	left: $baseCenterPos;
	backface-visibility: hidden;
	transform: translate3d(-#{$baseCenterPos}, -#{$baseCenterPos}, 0);
	.is-load & {
		opacity: 0;
		transition: opacity $ovrDur * 1 $easeOutQuint;
	}
	&_txt {
		width: 4.4em;
		margin: 1.2em auto 0;
		font: {
			size: #{1.2 / $basePcFz + 0em};
			// weight: $fwNsnsJp-bold;
		}
		position: relative;
		&-rdr {
			display: block;
			overflow: hidden;
			position: absolute;
			bottom: 0;
			left: 100%;
			animation: reader-stp 1s 1s steps(2) infinite;
		}
	}
}

@keyframes reader-stp {
	to {
		height: 0;
	}
}

.orbit-spinner {
	width: #{5.5 / $basePcFz + 0em};
	height: #{5.5 / $basePcFz + 0em};
	border-radius: 50%;
	perspective: #{80 / $basePcFz + 0em};
	&_line {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		position: absolute;
		&:first-child {
			border-bottom: #{0.3 / $basePcFz + 0em} solid currentColor;
			top: 0;
			left: 0;
			animation: orbit-spinner-fx-1 $spinnerDur linear infinite;
		}
		&:nth-child(2) {
			border-right: #{0.3 / $basePcFz + 0em} solid currentColor;
			top: 0;
			right: 0;
			animation: orbit-spinner-fx-2 $spinnerDur linear infinite;
		}
		&:nth-child(3) {
			border-top: #{0.3 / $basePcFz + 0em} solid currentColor;
			right: 0;
			bottom: 0;
			animation: orbit-spinner-fx-3 $spinnerDur linear infinite;
		}
	}
}

@keyframes orbit-spinner-fx-1 {
	0% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}
@keyframes orbit-spinner-fx-2 {
	0% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}
@keyframes orbit-spinner-fx-3 {
	0% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}