// General logo styles
// =============================================================================
.logo {
	width: 100%;
	line-height: 1;
	display: block;
	&.-sgk-rct,
	&.-sgk-rct-vrt {
		&.-wht {
			filter: brightness(0%) invert(100%);
		}
		&.-blk {
			filter: brightness(0%) invert(0%);
		}
	}
	&.-sgk-rct {
		max-width: #{27 / $basePcFz + 0em};
		color: $siteCol-k-blk;
		font-weight: $fwNsnsJp-bold;
	}
	&.-sgk-rct-vrt {
		max-width: #{23.2 / $basePcFz + 0em};
		.hst-bynd & {
			max-width: #{19 / $basePcFz + 0em};
		}
		.-group & {
			margin-bottom: #{5 / $basePcFz + 0em};
		}
	}
	&.-ytb {
		max-width: #{16 / $basePcFz + 0em};
	}
	&_lnk, &_txt,
	&_inr, &_wrp {
		width: 100%;
		height: 100%;
		display: block;
	}
	&_lnk {
		.-sgk-rct & {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	&_txt {
		.-sgk-rct & {
			&.-logo {
				max-width: calc(100% - #{13 / $basePcFz + 0em});
			}
			&.-txt {
				width: auto;
				height: auto;
				font: {
					size: #{1.4 / $basePcFz + 0em};
					style: italic;
				}
				transform: translate3d(0, 0.05em, 0);
			}
		}
	}
	@include mediaQueries-maxW('mbl-s') { // mobile size = S //
		&.-sgk-rct {
			&.-nav {
				font-size: 14 / 375 * 100vw;
			}
			&.-nav-mdl {
				font-size: #{1.3 / $baseMblFz + 0em};
			}
		}
	}
}