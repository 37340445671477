// Base styles: opinionated defaults
// =============================================================================
html {
	color: $siteCol-blk01;
}

body {
	// min-width: 1000px;
	line-height: $baseLh;
	background: {
		color: $siteCol-d-blu01;
		image: linear-gradient(
			90deg,
			rgba($siteCol-blu01, 0.9) 0%,
			rgba($siteCol-d-blu01, 0.9) 100%
		);
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		min-width: 320px;
		font-size: #{$baseMblFz}rem;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		font-size: #{$basePcFz}rem;
	}
}

a {
	&:not(.logo_lnk):not(.btn_lnk):not(.sns-acct_lnk):not(.rct-f-add-lst_lnk):not(.ent-new-grad-lst_lnk):not(.nw-lst_lnk) {
		&[target="_blank"] {
			&::after {
				content: '\e900';
				padding-left: 0.4em;
				@include useIcomoon;
				display: inline-block;
				transform: translate3d(0, 0.05em, 0);
			}
		}
	}
}

img, picture, source {
	pointer-events: none;
}

::-moz-selection {
	@include selectionStyle;
}
::selection {
	@include selectionStyle;
}