// Main general content styles
// =============================================================================
.mn-cnt {
	&_add-bg {
		&.-bg-wht {
			background-color: $siteCol-f-wht;
		}
		&.-bg-l-blu01 {
			background-color: $siteCol-l-blu06;
		}
		&.-bg-l-blu02 {
			background-color: $siteCol-l-blu07;
		}
		&.-bg-l-blu03 {
			background-color: $siteCol-l-blu08;
		}
		.-group & {
			overflow: hidden;
			position: relative;
			&::before,
			&::after {
				content: '';
				width: 100%;
				height: 100%;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
			}
			&::before {
				background: {
					image: imgUrl('img_bg_noise.png');
					position: center;
					size: #{50 / $basePcFz + 0em} #{50 / $basePcFz + 0em};
				}
				mix-blend-mode: soft-light;
				opacity: 0.2;
				z-index: 5;
			}
			&::after {
				background: {
					image: imgUrl('img_bg_grp.webp');
					repeat: no-repeat;
					position: center;
					size: cover;
				}
				opacity: 0.4;
				z-index: 0;
				.no-webp & {
					background-image: imgUrl('img_bg_grp.jpg');
				}
			}
			& > * {
				position: relative;
				z-index: 20;
			}
		}
		.-network & {
			overflow: hidden;
			position: relative;
			z-index: 0;
			&::after {
				content: '';
				width: 100%;
				height: 100%;
				background: {
					image: imgUrl('img_bg_nw.webp');
					repeat: no-repeat;
					position: center;
					size: cover;
				}
				opacity: 0.65;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
				.no-webp & {
					background-image: imgUrl('img_bg_nw.jpg');
				}
			}
		}
	}
	&_bdy {
		@extend %extend--inner-box-1200;
		// @extend %extend--px-axis;
		.-philosophy & {
			padding-top: 0;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_bdy {
			padding: {
				top: #{4 / $baseMblFz + 0em};
				bottom: #{4 / $baseMblFz + 0em};
			}
			.-training & {
				padding-top: 0;
			}
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		
	}
	@include mediaQueries-maxW('mbl-s') { // mobile size = S //
		
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_add-bg {
			.-network & {
				min-height: #{105 / $basePcFz + 0em};
			}
		}
		&_bdy {
			padding: {
				top: #{8 / $basePcFz + 0em};
				bottom: #{8 / $basePcFz + 0em};
			}
			.-training & {
				padding-top: #{4 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-minW('pc-m') { // desktop size = M //
		
	}
	@include mediaQueries-minW('pc-l') { // desktop size = L //
		
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_add-bg {
			.-network & {
				min-height: 0;
			}
		}
	}
}

.prm-ttl {
	&.-wht {
		color: $siteCol-f-wht;
	}
	.msg-t-art & {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		&::after {
			content: '';
			width: 100%;
			height: 100%;
			background-color: rgba($siteCol-f-wht, 0.9);
			box-shadow: 1.2em 1.2em 0.8em -1em rgba($siteCol-nvy01, 0.2);
			display: block;
			position: absolute;
			top: $baseCenterPos;
			left: $baseCenterPos;
			z-index: 0;
			transform: 
				translate3d(-#{$baseCenterPos}, -#{$baseCenterPos}, 0)
				rotate(45deg);
		}
	}
	.abt-art & {
		position: absolute;
		top: 0;
		right: 40 / 1200 * 100%;
		z-index: 50;
		transform: translate3d(0, -36%, 0);
	}
	&_txt {
		display: block;
		&.-en {
			color: $siteCol-blu01;
			font-style: italic;
			line-height: 1.1;
			.-wht & {
				color: inherit;
			}
			.-ash-blu & {
				color: $siteCol-ash-blu01;
			}
		}
		&.-jp {
			margin-top: 0.8 / 2 + 0em;
			font-size: #{2 / $basePcFz + 0em};
		}
		.msg-t-art & {
			position: relative;
			z-index: 5;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		.msg-t-art & {
			width: 200 / 425 * 100vw;
			height: 200 / 425 * 100vw;
			margin: 0 auto;
		}
		&_txt {
			&.-en {
				font-size: 40 / 425 * 100vw;
			}
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		.msg-t-art & {
			width: #{20 / $baseMblFz + 0em};
			height: #{20 / $baseMblFz + 0em};
		}
		&_txt {
			&.-en {
				font-size: #{4 / $baseMblFz + 0em};
			}
		}
	}
	@include mediaQueries-maxW('mbl-s') { // mobile size = S //
		font-size: 14 / 375 * 100vw;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&.-flx {
			display: flex;
			align-items: baseline;
		}
		.msg-t-art & {
			width: #{33.8 / $basePcFz + 0em};
			height: #{33.8 / $basePcFz + 0em};
		}
		&_txt {
			&.-en {
				// font-size: #{7 / $basePcFz + 0em};
				font-size: #{6.5 / $basePcFz + 0em};
				.-flx & {
					transform: translate3d(0, 0.075em, 0);
				}
			}
			&.-jp {
				.-flx & {
					margin: 0 0 0 1em;
				}
			}
		}
	}
}

.scnd-ttl {
	color: $siteCol-blu01;
	&.-wht {
		color: $siteCol-f-wht;
	}
	&.-flx {
		display: flex;
		align-items: baseline;
	}
	&_txt {
		display: block;
		&.-en {
			font: {
				size: #{4 / $basePcFz + 0em};
				style: italic;
			}
			line-height: 1.1;
			.-flx & {
				transform: translate3d(0, 0.075em, 0);
			}
		}
		&.-jp {
			margin-top: #{0.8 / $basePcFz + 0em};
			.-flx & {
				margin: 0 0 0 #{1.5 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-maxW('mbl-s') { // mobile size = S //
		font-size: 14 / 375 * 100vw;
	}
}

.tab-lst {
	margin-bottom: 1em;
	color: $siteCol-d-blu01;
	display: flex;
	justify-content: center;
	position: relative;
	&::after {
		content: '';
		width: 100%;
		height: 2px;
		background-color: currentColor;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 5;
	}
	&_itm {
		width: 100%;
		height: #{6 / $basePcFz + 0em};
		max-width: #{22 / $basePcFz + 0em};
	}
	&_lnk {
		height: 100%;
		font: {
			size: #{1.8 / $basePcFz + 0em};
			weight: $fwNsnsJp-bold;
		}
		text-align: center;
		border: 2px solid $siteCol-f-wht;
		border: {
			top-width: #{0.8 / $basePcFz + 0em};
			bottom: 0;
		}
		background-color: $siteCol-l-blu02;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 0;
		.is-actv & {
			border-color: currentColor;
			background-color: $siteCol-f-wht;
			opacity: 1 !important;
			z-index: 10;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: 0 #{0.6 / $baseMblFz + 0em};
		&_itm {
			&:nth-child(n+2) {
				margin-left: #{0.6 / $baseMblFz + 0em};
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_itm {
			&:nth-child(n+2) {
				margin-left: #{2 / $basePcFz + 0em};
			}
		}
	}
}

.sgk-rct-mov {
	overflow: hidden;
	position: relative;
	.ytb-bg {
		min-height: 135%;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		height: 460 / 670 * 100vw;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		height: 620 / 1370 * 100vw;
	}
	@include mediaQueries-minW('pc-m') { // desktop size = M //
		height: #{62/ $basePcFz + 0em};
	}
}

.tyr-frm {
	border: 0;
}

@keyframes xBndApprFx {
	0% {
		transform: scale(0, 1);
		transform-origin: 0 0;
	}
	50% {
		transform: scale(1, 1);
		transform-origin: 0 0;
	}
	50.1% {
		transform: scale(1, 1);
		transform-origin: 100% 0;
	}
	100% {
		transform: scale(0, 1);
		transform-origin: 100% 0;
	}
}
@keyframes xBndItmApprFx {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	50.1% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

/* About
   ======================================== */
.sgk-abt-art {
	&_bdy {
		@extend %extend--inner-box-1200;
	}
	&_ttl {
		width: 100%;
		max-width: 60 / 3.4 + 0em;
		padding: 1.4 / 3.4 + 0em 2.5 / 3.4 + 0em 1.4 / 3.4 + 0em 0;
		color: $siteCol-f-wht;
		position: relative;
		z-index: 0;
		&::after {
			content: '';
			width: 150vw;
			height: 100%;
			background-color: $siteCol-d-blu01;
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			z-index: -1;
		}
	}
	&_cpy {
		* + & {
			margin-top: 1em;
		}
		&.-biz {
			font-size: #{2 / $basePcFz + 0em};
		}
	}
	&_lnk-cpy {
		font-size: #{2.4 / $basePcFz + 0em};
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: 0 0 #{4 / $baseMblFz + 0em};
		&_inr {
			& + & {
				margin-top: #{4 / $baseMblFz + 0em};
			}
		}
		&_ttl {
			margin-bottom: 4 / 2.4 + 0em;
			font-size: #{2.4 / $baseMblFz + 0em};
		}
		&_fig {
			&.-ph {
				margin-top: 1em;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{4 / $basePcFz + 0em} 0 #{8 / $basePcFz + 0em};
		&_inr {
			& + & {
				margin-top: #{8 / $basePcFz + 0em};
			}
		}
		&_flx {
			display: flex;
			align-items: center;
			justify-content: space-between;
			& > * {
				width: calc((100% - #{4 / $basePcFz + 0em}) / 2);
			}
		}
		&_ttl {
			margin-bottom: 6 / 3.4 + 0em;
			font-size: #{3.4 / $basePcFz + 0em};
			.-grp & {
				margin-bottom: 0;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_ttl {
			.-grp & {
				margin-bottom: 6 / 3.4 + 0em;
			}
		}
	}
}

.abt-biz-sct {
	&_ttl {
		color: $siteCol-d-blu01;
		font-size: #{3 / $basePcFz + 0em};
		&.-min {
			color: inherit;
			font-size: #{2.4 / $basePcFz + 0em};
			* + & {
				margin-top: 1em;
			}
		}
	}
	&_cpy {
		* + & {
			margin-top: 1em;
		}
	}
	&_cpt {
		font-weight: $fwNsnsJp-bold;
		&.-top {
			margin-bottom: 1.5 / 2.4 + 0em;
		}
		&.-btm {
			margin-top: 1.5 / 2.4 + 0em;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_inr {
			& + & {
				margin-top: #{4 / $baseMblFz + 0em};
			}
		}
		&_fig {
			.-cnsl & {
				margin-top: #{2.1 / $baseMblFz + 0em};
			}
		}
		&_cpt {
			font-size: #{1.8 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		// font-size: 14 / 425 * 100vw;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_inr {
			& + & {
				margin-top: #{8 / $basePcFz + 0em};
			}
			&.-cnsl {
				display: flex;
				justify-content: space-between;
				& > * {
					width: calc((100% - #{4 / $basePcFz + 0em}) / 2);
				}
			}
		}
		&_fig {
			&-img {
				.-cnsl & {
					height: #{20 / $basePcFz + 0em};
					& > * {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}
		&_cpt {
			font-size: #{2.4 / $basePcFz + 0em};
		}
	}
}

/* Message
   ======================================== */
.msg-t-art {
	background: {
		color: $siteCol-wht01;
		image: imgUrl('img_bg_msg.png');
		repeat: no-repeat;
		position: center top;
		size: cover;
	}
	&_bdy {
		@extend %extend--inner-box-1200;
	}
	&_ttl {
		font-size: #{3.2 / $basePcFz + 0em};
		margin-bottom: 2 / 3.2 + 0em;
	}
	&_cpy {
		line-height: 2.5;
		& + & {
			margin-top: 1em;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: {
			top: 120 / 670 * 100vw;
			bottom: #{4 / $baseMblFz + 0em};
		}
		text-align: center;
		&_cpy {
			&-unt {
				margin-top: 100 / 670 * 100vw;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: {
			top: #{11 / $basePcFz + 0em};
			bottom: #{11 / $basePcFz + 0em};
		}
		text-align: left;
		&_bdy {
			display: flex;
			align-items: center;
			& > * {
				&:first-child {
					flex-shrink: 0;
				}
			}
		}
		&_cpy {
			&-unt {
				padding-left: #{10 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		padding: {
			top: #{6 / $basePcFz + 0em};
			bottom: #{6 / $basePcFz + 0em};
		}
		font-size: 16 / 860 * 100vw;
		&_bdy {
			display: flex;
			align-items: center;
			& > * {
				&:first-child {
					font-size: #{1.4 / $basePcFz + 0em};
				}
			}
		}
	}
}

/* About SUGIKO
   ======================================== */
.abt-art {
	&_bdy {
		@extend %extend--inner-box-1200;
	}
	&_inr {
		position: relative;
		&::after {
			content: '';
			width: 100vw;
			height: 85 / 430 * 100%;
			background-color: $siteCol-f-wht;
			display: block;
			position: absolute;
			bottom: -1px;
			left: $baseCenterPos;
			transform: translate3d(-#{$baseCenterPos}, 0, 0);
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: 100 / 670 * 100vw;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-top: #{12 / $basePcFz + 0em};
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		margin-top: 120 / 1050 * 100vw;
	}
}

/* Message
   ======================================== */
.top-msg-art {
	background-color: $siteCol-wht01;
}

.top-msg-hdr {
	position: relative;
	&::after {
		content: '';
		width: 100%;
		height: 100%;
		background: {
			image: imgUrl('img_bg_top_msg.webp');
			repeat: no-repeat;
			position: center;
			size: cover;
		}
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		.no-webp & {
			background-image: imgUrl('img_bg_top_msg.jpg');
		}
	}
	&_bdy {
		@extend %extend--inner-box-1200;
		position: relative;
		z-index: 5;
	}
	&_inr {
		background-color: $siteCol-f-wht;
	}
	&_ttl {
		color: $siteCol-d-blu01;
		font-size:  #{2.8 / $basePcFz + 0em};
	}
	&_cpy {
		margin-top: 1em;
		font-size:  #{2 / $basePcFz + 0em};
	}
	&_ceo {
		margin: 0 auto;
	}
	&_name-ceo {
		max-width: 15.6 / 2 + 0em;
		margin-left: 0.5em;
		transform: translate3d(0, -0.25em, 0);
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding-top: #{4 / $baseMblFz + 0em};
		&::after {
			height: #{82 / $baseMblFz + 0em};
		}
		&_inr {
			margin-top: -#{4 / $baseMblFz + 0em};
			padding: #{3 / $baseMblFz + 0em} #{3 / $baseMblFz + 0em} #{2 / $baseMblFz + 0em};
			position: relative;
			z-index: 5;
		}
		&_ceo {
			max-width: #{48 / $baseMblFz + 0em};
			margin: 0 auto;
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		font-size: 14 / 425 * 100vw;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding-top: #{2 / $basePcFz + 0em};
		font-size: 16 / 1240 * 100vw;
		&::after {
			height: #{62 / $basePcFz + 0em};
		}
		&_bdy {
			display: flex;
			align-items: flex-start;
		}
		&_inr {
			max-width: #{53 / $basePcFz + 0em};
			padding: #{6 / $basePcFz + 0em};
			align-self: flex-end;
			transform: translate3d(0, #{4 / $basePcFz + 0em}, 0);
		}
		&_ceo {
			height: #{60 / $basePcFz + 0em};
			max-width: #{56 / $basePcFz + 0em};
			overflow: hidden;
		}
	}
	@include mediaQueries-minW('pc-xm') { // desktop size = XM //
		font-size: inherit;
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		font-size: inherit;
		&::after {
			height: #{94 / $basePcFz + 0em};
		}
		&_bdy {
			display: block;
		}
		&_inr {
			max-width: none;
			margin-top: -#{2 / $basePcFz + 0em};
			transform: translate3d(0, 0, 0);
		}
	}
}

.top-msg-sct {
	&_bdy {
		@extend %extend--inner-box-1200;
		display: flex;
	}
	&_inr {
		color: $siteCol-f-wht;
		position: relative;
		z-index: 0;
		&::after {
			content: '';
			width: 150vw;
			height: 100%;
			background-color: $siteCol-d-blu01;
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			z-index: -1;
		}
	}
	&_ttl {
		// font-size:  #{2.4 / $basePcFz + 0em};
		font-size:  #{2.8 / $basePcFz + 0em};
	}
	&_cpy {
		// margin-top: 2em;
		margin-top: 3.2 / 2 + 0em;
		font-size:  #{2 / $basePcFz + 0em};
	}
	&_ceo {
		margin: 0 auto;
		overflow: hidden;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: #{4 / $baseMblFz + 0em};
		&_bdy {
			flex-direction: column;
		}
		&_inr {
			padding: #{4 / $baseMblFz + 0em} #{2 / $baseMblFz + 0em} #{4 / $baseMblFz + 0em} 0;
			order: 5;
		}
		&_ceo {
			max-width: #{52 / $basePcFz + 0em};
			aspect-ratio: 26 / 29;
			// display: none;
			order: 0;
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		font-size: 14 / 425 * 100vw;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-top: #{8 / $basePcFz + 0em};
		font-size: 16 / 1240 * 100vw;
		&_bdy {
			align-items: center;
		}
		&_inr {
			max-width: #{68 / $basePcFz + 0em};
			padding: #{10 / $basePcFz + 0em} #{6 / $basePcFz + 0em} #{10 / $basePcFz + 0em} 0;
		}
		&_ceo {
			max-width: #{32 / $basePcFz + 0em};
			padding: 0 #{2 / $basePcFz + 0em};
			flex-shrink: 0;
		}
	}
	@include mediaQueries-minW('pc-xm') { // desktop size = XM //
		font-size: inherit;
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_inr {
			padding: #{6 / $basePcFz + 0em} #{4 / $basePcFz + 0em} #{6 / $basePcFz + 0em} 0;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		margin-top: #{6 / $basePcFz + 0em};
		font-size: inherit;
		&_bdy {
			flex-direction: column;
		}
		&_inr {
			order: 5;
		}
		&_ceo {
			max-width: #{52 / $basePcFz + 0em};
			aspect-ratio: 26 / 29;
			padding: 0;
			// display: none;
			order: 0;
		}
	}
}

.top-msg-mov-sct {
	background-color: $siteCol-f-wht;
	&_bdy {
		@extend %extend--inner-box-1000;
	}
	&_ttl {
		color: $siteCol-blu01;
		font-size:  #{2.4 / $basePcFz + 0em};
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: #{4 / $baseMblFz + 0em};
		padding: #{4 / $baseMblFz + 0em} 0;
		&_ttl {
			text-align: center;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-top: #{6 / $basePcFz + 0em};
		padding: #{6 / $basePcFz + 0em} 0;
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		
	}
}

.top-msg-mov-lst {
	margin: #{2.5 / $basePcFz + 0em} auto 0;
	&_ttl {
		margin-top: 0.6em;
		font-size:  #{2 / $basePcFz + 0em};
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		max-width: #{52 / $baseMblFz + 0em};
		&_itm {
			&:nth-child(n+2) {
				margin-top: #{2 / $baseMblFz + 0em};
			}
		}
		&_ttl {
			text-align: center;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		display: flex;
		justify-content: space-between;
		&_itm {
			width: 480 / 1000 * 100%;
			max-width: #{45 / $basePcFz + 0em};
		}
	}
}

/* Member
   ======================================== */
.mbr-art {
	&_bdy {
		@extend %extend--inner-box-1200;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding-top: 60 / 670 * 100vw;
		&.-sub {
			padding: 0 0 #{4 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding-top: #{8 / $basePcFz + 0em};
		&.-sub {
			padding: 0 0 #{8 / $basePcFz + 0em};
		}
		&_inr {
			position: relative;
			.btn.-va {
				position: absolute;
				right: 0;
				bottom: 0;
			}
		}
	}
}

.emp-lst {
	color: $siteCol-f-wht;
	&.-sub {
		margin-top: 0;
	}
	&_itm {
		height: #{24 / $basePcFz + 0em};
		display: flex;
		align-items: center;
		position: relative;
		&::after {
			content: '';
			width: 150vw;
			height: 100%;
			background-color: $siteCol-gry01;
			display: block;
			position: absolute;
			top: 0;
			z-index: 0;
			transform: skew(-12deg);
		}
		&:nth-child(odd) {
			&::after {
				left: 0;
				transform-origin: left center;
			}
		}
		&:nth-child(even) {
			&::after {
				right: 0;
				transform-origin: right center;
			}
			& > * {
				&:first-child {
					order: 5;
				}
			}
		}
		.is-load & {
			transition: filter $ovrDur $easeOutQuint;
		}
		.ua-mbl & {
			&:active {
				filter: brightness(125%);
				.emp-lst_fig {
					filter: brightness(80%);
				}
			}
		}
		body:not(.ua-mbl) & {
			&:hover {
				filter: brightness(125%);
				.emp-lst_fig {
					filter: brightness(80%);
				}
			}
		}
	}
	&_inr {
		width: 100%;
		padding: 0 #{3 / $basePcFz + 0em};
		z-index: 10;
	}
	&_name {
		transform: 
			translate3d(0.9em, 0, 0)
			skew(-12deg);
		&-txt {
			display: block;
			&.-cat {
				margin-bottom: 0.8em;
				padding: 0.4em 0.8em;
				font-size: 1.8 / 1.4 + 0em;
				line-height: 1;
				border: 1px solid currentColor;
				display: inline-block;
			}
			&.-min {
				font-size: #{1.8 / $basePcFz + 0em};
			}
			&.-lrg {
				margin-top: 0.8 / 3.2 + 0em;
				font-size: #{3.2 / $basePcFz + 0em};
			}
		}
	}
	&_fig {
		flex-shrink: 0;
		align-self: flex-end;
		z-index: 5;
		.is-load & {
			transition: filter $ovrDur $easeOutQuint;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: #{3 / $baseMblFz + 0em};
		font-size: calc(14 / #{425 - 20 * 2} * (100vw - #{$mbl-pad * 2}));
		&_itm {
			&:nth-child(n+2) {
				margin-top: #{3 / $baseMblFz + 0em};
			}
			&:nth-child(odd) {
				transform: translate3d(1.5em, 0, 0);
				.emp-lst_fig {
					transform: translate3d(-1em, 0, 0);
				}
			}
			&:nth-child(even) {
				transform: translate3d(-1.5em, 0, 0);
				.emp-lst_fig {
					transform: translate3d(1em, 0, 0);
				}
			}
		}
		&_name {
			font-size: #{1 / $baseMblFz + 0em};
		}
		&_fig {
			width: #{18 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-top: #{6 / $basePcFz + 0em};
		display: flex;
		flex: {
			wrap: wrap;
			direction: row-reverse;
		}
		justify-content: space-between;
		&_itm {
			// width: calc((100% - #{8 / $basePcFz + 0em}) / 2);
			width: 560 / 1200 * 100%;
			&:nth-child(n+2) {
				.-sub & {
					margin-top: #{4.5 / $basePcFz + 0em};
				}
			}
			&:nth-child(n+3) {
				margin-top: #{4.5 / $basePcFz + 0em};
			}
			&:nth-child(odd) {
				.-top & {
					transform: translate3d(0, -#{$baseCenterPos}, 0);
				}
				.-sub & {
					margin-top: -#{24 / $basePcFz + 0em};
					// transform: translate3d(0, calc(-100% - #{4.5 / $basePcFz + 0em}), 0);
				}
			}
		}
		&_name {
			font-size: #{1.2 / $basePcFz + 0em};
		}
		&_fig {
			width: #{22.2 / $basePcFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-xm') { // desktop size = XM //
		&_name {
			font-size: #{1.4 / $basePcFz + 0em};
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		font-size: calc(16 / #{1050 - 40 * 2} * (100vw - #{$tblt-pad * 2}));
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		margin-top: 60 / 860 * 100vw;
	}
}

.emp-min-lst {
	color: $siteCol-f-wht;
	line-height: 1.7;
	display: flex;
	flex-wrap: wrap;
	&_lnk {
		height: 100%;
		padding: 1em #{2 / $basePcFz + 0em};
		background-color: $siteCol-gry01;
		display: flex;
	}
	&_txt {
		font-weight: $fwNsnsJp-bold;
		display: block;
		&.-cat {
			// margin-bottom: 0.5em;
			padding: 0.4em 0.8em;
			line-height: 1;
			border: 1px solid currentColor;
			display: inline-block;
			transform: skew(-12deg);
		}
		&.-min {
			font-size: #{1.3 / $basePcFz + 0em};
			& > * {
				margin: 0.25em 0;
			}
		}
		&.-lrg {
			font-size: #{2.4 / $basePcFz + 0em};
			flex-shrink: 0;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin: -#{0.2 / $baseMblFz + 0em};
		&_itm {
			width: calc(100% / 2);
			padding: #{0.2 / $baseMblFz + 0em};
		}
		&_lnk {
			flex-direction: column;
			justify-content: center;
		}
		&_txt {
			&.-min {
				& > * {
					&:nth-child(2) {
						display: block !important;
					}
				}
			}
			&.-lrg {
				margin-top: auto;
			}
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		font-size: 14 / 425 * 100vw;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin: -#{1 / $basePcFz + 0em};
		&_itm {
			width: calc(100% / 3);
			padding: #{1 / $basePcFz + 0em};
			// width: calc((100% - #{2 / $basePcFz + 0em} * 2) / 3);
		}
		&_lnk {
			align-items: center;
		}
		&_txt {
			&.-min {
				width: 200 / 346 * 100%;
				max-width: 20 / 1.3 + 0em;
				margin-right: 2 / 1.3 + 0em;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_itm {
			width: calc(100% / 2);
		}
	}
}

.mbr-sct {
	min-height: #{33 / $basePcFz + 0em};
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	& > * {
		position: relative;
		z-index: 10;
	}
	&::before,
	&::after {
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
	&::before {
		background-color: $siteCol-d-blu01;
		opacity: 0.8;
		z-index: 5;
	}
	&::after {
		background: {
			repeat: no-repeat;
			position: center;
			size: cover;
		}
		z-index: 0;
		.is-load & {
			transition: transform $ovrDur $easeOutQuint;
		}
	}
	&.-tlk {
		&::after {
			background-image: imgUrl('img_tlk_thmb.jpg');
		}
	}
	&.-ps {
		&::after {
			background-image: imgUrl('img_ps_thmb.jpg');
		}
	}
	.is-load & {
		transition: filter $ovrDur $easeOutQuint;
	}
	.ua-mbl & {
		&:active {
			filter: brightness(125%);
			&::after {
				transform: scale(1.075);
			}
		}
	}
	body:not(.ua-mbl) & {
		&:hover {
			filter: brightness(125%);
			&::after {
				transform: scale(1.075);
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		& + & {
			margin-top: 2px;
		}
		&-otr {
			margin-top: #{4 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		min-height: 288 / 425 * 100vw;
		&-otr {
			margin-top: 40 / 425 * 100vw;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		width: calc((100% - 2px) / 2);
		&-otr {
			margin-top: #{10 / $basePcFz + 0em};
			display: flex;
			justify-content: space-between;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		width: 100%;
		& + & {
			margin-top: 2px;
		}
		&-otr {
			margin-top: 100 / 860 * 100vw;
			display: block;
		}
	}
}

.emp-hdr {
	line-height: 1.7;
	background-color: $siteCol-f-wht;
	position: relative;
	&_bdy {
		@extend %extend--inner-box-1200;
	}
	&_inr {
		position: relative;
		&::after {
			content: '';
			width: 150vw;
			height: 100%;
			background-color: $siteCol-gry01;
			opacity: 0.15;
			display: block;
			position: absolute;
			top: 0;
			z-index: 0;
			.-int & {
				background-color: $siteCol-l-blu02;
				opacity: 1;
			}
		}
		& > * {
			position: relative;
			z-index: 10;
		}
	}
	&_intr {
		min-height: #{22 / $basePcFz + 0em};
		color: $siteCol-f-wht;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: relative;
		&::after {
			content: '';
			width: 150vw;
			height: 100%;
			background-color: $siteCol-blu01;
			display: block;
			position: absolute;
			top: 0;
			z-index: 0;
			transform: skew(-12deg);
			transform-origin: right bottom
		}
		&-txt {
			width: 100%;
			font: {
				size: #{3.2 / $basePcFz + 0em};
				weight: $fwNsnsJp-bold;
			}
			position: relative;
			z-index: 5;
		}
	}
	&_prf {
		font-weight: $fwNsnsJp-bold;
		line-height: 1.5;
		background-color: $siteCol-f-wht;
		position: relative;
		.-int & {
			background-color: rgba($siteCol-f-wht, 0.8);
		}
	}
	&_job-typ {
		min-width: 15 / 1.8 + 0em;
		padding: 0.5em 1em;
		color: $siteCol-f-wht;
		font: {
			size: #{1.8 / $basePcFz + 0em};
			weight: $fwNsnsJp-bold;
		}
		line-height: 1;
		text-align: center;
		border-bottom-right-radius: 1.5 / 1.8 + 0em;
		background-color: $siteCol-ash-blu01;
		position: absolute;
		top: 0;
		left: 0;
	}
	&_name {
		&-unt,
		&-txt {
			display: block;
		}
		&-unt {
			&:nth-child(n+2) {
				order: 5;
			}
		}
		&-txt {
			&.-jp {
				font-size: #{6 / $basePcFz + 0em};
			}
			&.-en {
				color: $siteCol-ash-blu01;
				font: {
					size: #{3 / $basePcFz + 0em};
					style: italic;
				}
			}
			&.-pst {
				margin-bottom: 0.3em;
				color: $siteCol-ash-blu01;
				font-size: 1.2em;
			}
			&.-sj {
				color: $siteCol-f-wht;
				width: 8 / 2 + 0em;
				height: 8 / 2 + 0em;
				font-size: #{2 / $basePcFz + 0em};
				text-align: center;
				border-radius: 50%;
				background-color: $siteCol-blu01;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: $baseCenterPos;
				transform: translate3d(0, -#{$baseCenterPos}, 0);
				&.-lft {
					right: 100%;
				}
				&.-rgt {
					left: 100%;
				}
			}
		}
		.-int & {
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			&::after {
				content: '×';
				margin: 0 2 / 5.6 + 0em;
				font: {
					family: 'Noto Sans JP';
					size: #{5.6 / $basePcFz + 0em};
					weight: $fwNsnsJp-light;
				}
				line-height: 1;
				display: block;
				flex-shrink: 0;
			}
		}
		.-int.-ps & {
			align-items: flex-end;
			justify-content: space-around;
			&::after {
				display: none;
			}
		}
	}
	&_cpy {
		margin-top: 2 / 2.4 + 0em;
		font-size: #{2.4 / $basePcFz + 0em};
		.-rv &,
		.-int & {
			margin: 0 0 2 / 2.4 + 0em;
		}
	}
	&_fig {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			background: {
				image: imgUrl('img_bg_noise.png');
				position: center;
				size: #{50 / $basePcFz + 0em} #{50 / $basePcFz + 0em};
			}
			mix-blend-mode: soft-light;
			opacity: 0.2;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
		}
		& > * {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&_int-cpy {
		font: {
			size: #{2.4 / $basePcFz + 0em};
			weight: $fwNsnsJp-bold;
		}
		text-align: center;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-bottom: calc(-300 / #{670 - 20 * 2} * (100vw - #{$mbl-pad * 2}));
		.-rv & {
			margin-bottom: calc(-280 / #{670 - 20 * 2} * (100vw - #{$mbl-pad * 2}));
		}
		&_inr {
			padding: 450 / 670 * 100vw 0 calc(340 / #{670 - 20 * 2} * (100vw - #{$mbl-pad * 2}));
			&::after {
				left: $baseCenterPos;
				transform: translate3d(-#{$baseCenterPos}, 0, 0);
			}
		}
		&_intr {
			padding: 0 #{2 / $baseMblFz + 0em};
			font-size: calc(14 / #{540 - 20 * 2} * (100vw - #{$mbl-pad * 2}));
			&::after {
				right: #{6 / $baseMblFz + 0em};
			}
		}
		&_prf {
			padding: #{2 / $baseMblFz + 0em} #{2.5 / $baseMblFz + 0em};
			&-unt {
				width: calc(100% - #{2 / $baseMblFz + 0em} * 2);
				margin: #{4 / $baseMblFz + 0em} auto 0;
			}
			.-member & {
				padding-top: #{4.5 / $baseMblFz + 0em};
			}
			.-int & {
				max-width: 56 / 1.2 + 0em;
				margin: 0 auto;
				font-size: calc(12 / #{670 - 20 * 2} * (100vw - #{$mbl-pad * 2}));
			}
		}
		&_name {
			.-int.-ps & {
				font-size: 1.1 / 1.2 + 0em;
				flex-wrap: wrap;
			}
			&-unt {
				.-int.-ps & {
					width: 50%;
					&:nth-child(n+3) {
						margin-top: 2em;
					}
				}
			}
		}
		&_fig {
			height: #{62 / $baseMblFz + 0em};
			font-size: 14 / 670 * 100vw;
			& > * {
				object-position: calc(50% - #{30 / $baseMblFz + 0em}) top;
				.-int.-ps.-interview-02 & {
					object-position: calc(50% - #{32.8 / $baseMblFz + 0em}) top;
				}
			}
		}
		&_int-cpy {
			margin-top: 2.5 / 2.1 + 0em;
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		font-size: calc(14 / #{425 - 20 * 2} * (100vw - #{$mbl-pad * 2}));
		&_name {
			&-txt {
				&.-jp {
					font-size: #{4.6 / $baseMblFz + 0em};
				}
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-bottom: -#{4 / $basePcFz + 0em};
		.-rv &,
		.-int & {
			margin-bottom: 0;
		}
		&_inr {
			padding: #{10 / $basePcFz + 0em} 0 #{6 / $basePcFz + 0em};
			&::after {
				right: 0;
				.-int & {
					right: auto;
					left: $baseCenterPos;
					transform: translate3d(-#{$baseCenterPos}, 0, 0);
				}
			}
		}
		&_intr {
			// padding: #{3 / $basePcFz + 0em} #{2 / $basePcFz + 0em} #{3 / $basePcFz + 0em} #{6 / $basePcFz + 0em};
			width: 50%;
			&::after {
				right: 0;
			}
			&-txt {
				max-width: 54 / 3.2 + 0em;
			}
		}
		&_prf {
			padding: #{4 / $basePcFz + 0em};
			&-unt {
				width: calc(100% - #{4 / $basePcFz + 0em} * 2);
				max-width: #{108 / $basePcFz + 0em};
				margin: #{6 / $basePcFz + 0em} auto 0;
				display: flex;
				flex-wrap: wrap;
				align-items: flex-end;
				justify-content: space-between;
				.-int & {
					margin-top: #{9 / $basePcFz + 0em};
				}
				& > * {
					width: 50%;
					&:first-child {
						// max-width: #{54 / $basePcFz + 0em};
					}
					&:nth-child(2) {
						max-width: 480 / 1080 * 100%;
						transform: translate3d(0, -#{4 / $basePcFz + 0em}, 0);
					}
				}
			}
			.-int & {
				width: 100%;
				max-width: #{78 / $basePcFz + 0em};
				// padding: #{2 / $basePcFz + 0em} #{4 / $basePcFz + 0em} #{3 / $basePcFz + 0em};
				padding: #{3 / $basePcFz + 0em} #{4 / $basePcFz + 0em};
			}
			.-int.-ps & {
				max-width: none;
				font-size: 16 / 1240 * 100vw;
			}
		}
		&_name {
			.-int.-ps & {
				font-size: #{1.1 / $basePcFz + 0em};
			}
		}
		&_fig {
			height: 620 / 1200 * 100vw;
			max-height: #{62 / $basePcFz + 0em};
			.-ps & {
				max-height: #{52 / $basePcFz + 0em};
			}
			& > * {
				object-position: center top;
			}
		}
		&_int-cpy {
			width: 100%;
			max-width: none !important;
			margin-top: 6 / 2.4 + 0em;
			transform: translate3d(0, 0, 0) !important;
		}
	}
	@include mediaQueries-minW('pc-xm') { // desktop size = XM //
		&_prf {
			.-int.-ps & {
				font-size: inherit;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		font-size: 16 / 1050 * 100vw;
	}
}

.mtt-crr-sct {
	&.-mtt {
		padding: #{1.2 / $basePcFz + 0em};
		border-top: #{1.8 / $basePcFz + 0em} solid $siteCol-gry01;
		color: $siteCol-f-wht;
		background-color: rgba($siteCol-d-blu01, 0.95);
	}
	&_ttl {
		display: flex;
		align-items: baseline;
		&-txt {
			display: block;
			&.-en {
				font: {
					size: #{3.5 / $basePcFz + 0em};
					style: italic;
				}
				line-height: 1.1;
				flex-shrink: 0;
				transform: translate3d(0, 0.075em, 0);
				.-crr & {
					color: $siteCol-blu01;
				}
			}
			&.-jp {
				margin-left: 1em;
				font-size: #{2 / $basePcFz + 0em};
				.-mtt & {
					color: rgba($siteCol-f-wht, 0.7);
				}
			}
		}
	}
	&_hrzn {
		height: 2px;
		// margin: #{1 / $basePcFz + 0em} 0 #{1 / $basePcFz + 0em};
		margin: #{1 / $basePcFz + 0em} 0;
		background-color: $siteCol-gry01;
	}
	&_cpy{
		margin-top: 1em;
		padding: #{1.2 / $basePcFz + 0em};
		font-weight: $fwNsnsJp-bold;
		text-align: center;
		border: 1px solid currentColor;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: #{4 / $baseMblFz + 0em};
		& + & {
			margin-top: #{2 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&.-crr {
			width: 100%;
			display: flex;
			justify-content: flex-end;
		}
		&_bdy {
			.-crr & {
				width: 100%;
				max-width: 480 / 1080 * 100%;
			}
		}
	}
}

.crr-stp-stp {
	--ageTrm-wdh: 3.5em;
	line-height: 1.5;
	&_itm {
		display: flex;
		flex-wrap: wrap;
		&:nth-last-child(n+2) {
			--inrVgap-btm: 1em;
			--ageBrd-actv: '';
		}
	}
	&_trm {
		width: var(--ageTrm-wdh);
		padding-bottom: var(--inrVgap-btm);
		color: $siteCol-blu01;
		text-align: center;
		flex-shrink: 0;
		position: relative;
		&::before {
			content: var(--ageBrd-actv);
			width: 2px;
			height: calc(100% - 1em * 1.5);
			background-color: rgba($siteCol-ash-blu01, 0.25);
			display: block;
			position: absolute;
			bottom: 0;
			left: $baseCenterPos;
			transform: translate3d(-#{$baseCenterPos}, -0.125em, 0);
		}
	}
	&_dsc {
		// padding: 0 0 calc(var(--inrVgap-btm, 0) / 2) 1em;
		padding: 0 0 var(--inrVgap-btm, 0) 1em;
	}
}

.emp-art {
	&_bdy {
		background-color: $siteCol-f-wht;
	}
	&_inr {
		@extend %extend--inner-box-1200;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_inr {
			&.-add-pad {
				padding: {
					top: #{4 / $baseMblFz + 0em};
					bottom: #{6 / $baseMblFz + 0em};
				}
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_inr {
			&.-add-pad {
				padding: {
					top: #{8 / $basePcFz + 0em};
					bottom: #{10 / $basePcFz + 0em};
				}
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_inr {
			&.-add-pad {
				padding: {
					top: 80 / 860 * 100vw;
					bottom: 100 / 860 * 100vw;
				}
			}
		}
	}
}

.emp-sct {
	&_bdy {
		& > * {
			position: relative;
		}
	}
	&_inr {
		z-index: 5;
	}
	&_ttl {
		display: flex;
		align-items: center;
		* + & {
			margin-top: #{4 / $basePcFz + 0em};
		}
		&-txt {
			font-size: #{2.4 / $basePcFz + 0em};
			display: block;
			&.-qs {
				margin-right: 2 / 5 + 0em;
				color: $siteCol-blu01;
				font: {
					size: #{5 / $basePcFz + 0em};
					style: italic;
				}
				line-height: 1;
				flex-shrink: 0;
				align-self: flex-start;
			}
		}
	}
	&_cpy {
		* + & {
			margin-top: #{2 / $basePcFz + 0em};
		}
		&.-int {
			display: flex;
		}
		&-txt {
			display: block;
			&.-name {
				width: 4em;
				margin: 0;
				flex-shrink: 0;
			}
			&.-inr {
				& + & {
					margin-top: #{2 / $basePcFz + 0em};
				}
			}
		}
	}
	&_fig {
		z-index: 0;
		.-mid &,
		.-lst & {
			&::after {
				content: '';
				width: 100vw;
				height: 100%;
				background-color: $siteCol-gry01;
				opacity: 0.15;
				display: block;
				position: absolute;
				z-index: 0;
			}
		}
		&-img {
			position: relative;
			z-index: 5;
			& + & {
				margin-top: #{3 / $basePcFz + 0em};
			}
			&::before {
				content: '';
				width: 100%;
				height: 100%;
				background: {
					image: imgUrl('img_bg_noise.png');
					position: center;
					size: #{50 / $basePcFz + 0em} #{50 / $basePcFz + 0em};
				}
				mix-blend-mode: soft-light;
				opacity: 0.2;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 0;
			}
			& > * {
				width: 100%;
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		.-rv &,
		.-int & {
			padding-bottom: #{6 / $baseMblFz + 0em};
		}
		&_bdy {
			& + & {
				margin-top: #{6 / $baseMblFz + 0em};
			}
		}
		&_inr {
			margin-top: #{4 / $baseMblFz + 0em};
		}
		&_fig {
			.-mid &,
			.-lst & {
				&::after {
					bottom: -#{6.5 / $baseMblFz + 0em};
				}
			}
			.-mid & {
				&::after {
					left: #{6 / $baseMblFz + 0em};
				}
			}
			.-lst & {
				&::after {
					right: #{6 / $baseMblFz + 0em};
				}
			}
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		.-rv &,
		.-int & {
			padding-bottom: 60 / 425 * 100vw;
		}
		&_bdy {
			& + & {
				margin-top: 60 / 425 * 100vw;
			}
		}
		&_fig {
			&-img {
				& + & {
					margin-top: 25 / 425 * 100vw;
				}
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		.-rv &,
		.-int & {
			padding: #{12 / $basePcFz + 0em} 0;
		}
		&_bdy {
			display: flex;
			justify-content: flex-end;
			&:nth-child(even) {
				.-int & {
					flex-direction: row-reverse;
					& > *:nth-child(2) {
						padding: 0 #{4 / $basePcFz + 0em} 0 0;
					}
				}
			}
			&:nth-child(n+2) {
				.-rv & {
					display: block;
					& > * {
						width: auto;
						padding: 0;
					}
				}
			}
			& + & {
				margin-top: #{12 / $basePcFz + 0em};
				.-rv & {
					margin-top: #{6 / $basePcFz + 0em};
				}
			}
			&.-frst {
				align-items: flex-end;
				justify-content: space-between;
			}
			&.-mid {
				flex-direction: row-reverse;
			}
			&.-lst {
				align-items: flex-start;
			}
		}
		&_inr {
			width: calc(50% + #{4 / $basePcFz + 0em});
			padding-left: #{4 / $basePcFz + 0em};
			.-mid & {
				padding: 0 #{4 / $basePcFz + 0em} 0 0;
			}
		}
		&_fig {
			margin: 0 auto;
			flex-shrink: 0;
			.-frst & {
				margin: 0;
			}
			.-mid & {
				display: flex;
				align-items: center;
				&::after {
					top: 0;
					left: #{12 / $basePcFz + 0em};
				}
			}
			.-lst & {
				&::after {
					right: #{10 / $basePcFz + 0em};
					bottom: -#{6 / $basePcFz + 0em};
				}
			}
			.-rv & {
				& + * {
					align-self: center;
				}
			}
			.-int & {
				align-self: center;
			}
			&-img {
				.-frst &,
				.-rv &,
				.-int & {
					max-width: #{46 / $basePcFz + 0em};
				}
				.-mid & {
					max-width: #{42 / $basePcFz + 0em};
				}
				.-lst & {
					max-width: #{40 / $basePcFz + 0em};
				}
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		font-size: 16 / 1050 * 100vw;
	}
}

.tt-sct {
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{6 / $baseMblFz + 0em} 0;
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		padding-top: 60 / 425 * 100vw;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{12 / $basePcFz + 0em} 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		padding: 120 / 1050 * 100vw 0;
	}
}

.tt-lst {
	font: {
		size: #{1.8 / $basePcFz + 0em};
		// weight: $fwNsnsJp-bold;
	}
	line-height: 1.7;
	&_itm {
		&:nth-child(n+2) {
			margin-top: 2 / 1.8 + 0em;
		}
	}
	&_trm {
		color: $siteCol-ash-blu01;
		display: flex;
		&-txt {
			display: block;
			&.-time {
				min-width: 5.6 / 1.8 + 0em;
			}
			&.-act {
				padding: 0 1em;
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: 4 / 1.575 + 0em;
		&_itm {
			padding-left: 2 / 1.575 + 0em;
			border-left: 4px solid rgba($siteCol-ash-blu01, 0.2);
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-top: 8 / 1.8 + 0em;
		&_itm {
			display: flex;
		}
		&_trm {
			width: 15 / 1.8 + 0em;
			border-right: 6px solid rgba($siteCol-ash-blu01, 0.2);
			flex-shrink: 0;
			&-txt {
				&.-act {
					margin: 0 auto;
				}
			}
		}
		&_dsc {
			padding-left: 1.5em;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		margin-top: 80 / 1050 * 100vw;
	}
}

.emp-msg-sct {
	position: relative;
	&_bdy {
		@extend %extend--inner-box-1200;
		position: relative;
		z-index: 5;
	}
	&_inr {
		border-top: #{1.8 / $basePcFz + 0em} solid $siteCol-gry01;
		background-color: rgba($siteCol-f-wht, 0.75);
	}
	&_cpy {
		margin-top: #{3 / $basePcFz + 0em};
	}
	&_fig {
		width: 100%;
		max-width: #{24 / $basePcFz + 0em};
		border-radius: 50%;
		overflow: hidden;
		position: relative;
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			background: {
				image: imgUrl('img_bg_noise.png');
				position: center;
				size: #{50 / $basePcFz + 0em} #{50 / $basePcFz + 0em};
			}
			mix-blend-mode: soft-light;
			opacity: 0.2;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
		}
		& > * {
			width: 100%;
		}
	}
	&_bg {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		&::before,
		&::after {
			content: '';
			width: 100%;
			height: 100%;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
		}
		&::before {
			background: {
				image: imgUrl('img_bg_noise.png');
				position: center;
				size: #{50 / $basePcFz + 0em} #{50 / $basePcFz + 0em};
			}
			mix-blend-mode: soft-light;
			opacity: 0.3;
			z-index: 10;
		}
		&::after {
			background-color: rgba($siteCol-f-wht, 0.5);
			z-index: 5;
			backdrop-filter: blur(5px);
			-webkit-backdrop-filter: blur(5px);
		}
		& > * {
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: relative;
			z-index: 0;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{15 / $baseMblFz + 0em} 0 #{6 / $baseMblFz + 0em};
		&_inr {
			padding: #{11 / $baseMblFz + 0em} #{3 / $baseMblFz + 0em} #{3 / $baseMblFz + 0em};
		}
		&_fig {
			position: absolute;
			top: 0;
			left: $baseCenterPos;
			transform: translate3d(-#{$baseCenterPos}, -#{$baseCenterPos}, 0);
		}
		.prm-ttl {
			text-align: center;
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		font-size: 14 / 425 * 100vw;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{8 / $basePcFz + 0em} 0;
		&_inr {
			padding: #{6 / $basePcFz + 0em} 100 / 1200 * 100%;
			display: flex;
			align-items: center;
		}
		&_txt-unt {
			margin-right: 80 / 1000 * 100%;
		}
		&_fig {
			flex-shrink: 0;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		padding-top: #{18 / $basePcFz + 0em};
		&_inr {
			padding: #{12 / $basePcFz + 0em} #{4 / $basePcFz + 0em} #{4 / $basePcFz + 0em};
		}
		&_txt-unt {
			margin-right: 0;
		}
		&_fig {
			position: absolute;
			top: 0;
			left: $baseCenterPos;
			transform: translate3d(-#{$baseCenterPos}, -#{$baseCenterPos}, 0);
		}
		.prm-ttl {
			text-align: center;
		}
	}
}

.emp-tbl-fig {
	background-color: $siteCol-gry03;
	&_bdy {
		@extend %extend--inner-box-1200;
	}
	&_img {
		border: 2px solid $siteCol-gry01;
		background-color: $siteCol-f-wht;
		overflow-x: scroll;
		& > * {
			min-width: #{82 / $basePcFz + 0em};
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{4 / $baseMblFz + 0em} 0;
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		padding: 40 / 415 * 100vw 0;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{10 / $basePcFz + 0em} 0;
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		padding: 80 / 1050 * 100vw 0;
	}
}

.int-art {
	position: relative;
	background: {
		repeat: no-repeat;
		position: center;
		size: cover;
	}
	.-talk & {
		background-image: url('../emp-img/ps-int-02/img_emp_msg_bg.webp');
		.no-webp & {
			background-image: url('../emp-img/ps-int-02/img_emp_msg_bg.jpg');
		}
	}
	.-project-story & {
		background-image: url('../emp-img/tlk-int-01/img_emp_msg_bg.webp');
		.no-webp & {
			background-image: url('../emp-img/tlk-int-01/img_emp_msg_bg.jpg');
		}
	}
	&::before,
	&::after {
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
	&::before {
		background: {
			image: imgUrl('img_bg_noise.png');
			position: center;
			size: #{50 / $basePcFz + 0em} #{50 / $basePcFz + 0em};
		}
		mix-blend-mode: soft-light;
		opacity: 0.3;
		z-index: 10;
	}
	&::after {
		background-color: rgba($siteCol-f-wht, 0.75);
		z-index: 5;
		backdrop-filter: blur(5px);
		-webkit-backdrop-filter: blur(5px);
	}
	&_bdy {
		@extend %extend--inner-box-1200;
		position: relative;
		z-index: 20;
	}
	&_ttl {
		text-align: center;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{4 / $baseMblFz + 0em} 0;
		&_ttl {
			font-size: #{1.8 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		// padding: 40 / 415 * 100vw 0;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{8 / $basePcFz + 0em} 0;
		&_ttl {
			font-size: #{2.4 / $basePcFz + 0em};
		}
	}
}

.int-lst {
	&_itm {
		padding: #{2 / $basePcFz + 0em};
		background-color: $siteCol-f-wht;
	}
	&_bdy {
		height: 100%;
		padding: #{1.5 / $basePcFz + 0em} #{1.5 / $basePcFz + 0em} #{6 / $basePcFz + 0em};
		border: 1px solid $siteCol-blu01;
		overflow: hidden;
		position: relative;
	}
	&_ttl {
		padding: 1.5 / 2 + 0em 0.5em;
		color: $siteCol-f-wht;
		font-size: #{2 / $basePcFz + 0em};
		text-align: center;
		background-color: $siteCol-blu01;
	}
	&_cpy {
		font-weight: $fwNsnsJp-bold;
		line-height: 1.4;
		text-align: center;
		&.-tlk {
			display: flex;
			align-items: center;
			justify-content: center;
			&::after {
				content: '×';
				margin: 0 2 / 3.8 + 0em;
				font: {
					family: 'Noto Sans JP';
					size: #{3.8 / $basePcFz + 0em};
					weight: $fwNsnsJp-light;
				}
				line-height: 1;
				display: block;
				flex-shrink: 0;
			}
		}
		&.-ps {
			font-size: #{2.4 / $basePcFz + 0em};
			margin: auto 0;
		}
		&-unt,
		&-txt {
			display: block;
		}
		&-unt {
			&:nth-child(n+2) {
				order: 5;
			}
		}
		&-txt {
			&.-pst {
				margin-bottom: 0.3em;
				font-size: #{1.2 / $basePcFz + 0em};
			}
			&.-jp {
				font-size: #{3.6 / $basePcFz + 0em};
			}
			&.-en {
				color: $siteCol-ash-blu01;
				font: {
					size: #{2 / $basePcFz + 0em};
					style: italic;
				}
			}
		}
	}
	&_fig {
		margin: #{1.5 / $basePcFz + 0em} 0 #{2 / $basePcFz + 0em};
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: #{4 / $baseMblFz + 0em};
		font-size: 18 / 670 * 100vw;
		&_itm {
			&:nth-child(n+2) {
				margin-top: #{2 / $baseMblFz + 0em};
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		font-size: 16 / 1240 * 100vw;
		margin-top: #{8 / $basePcFz + 0em};
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		&_itm {
			width: 580 / 1200 * 100%;
			&:nth-child(n+3) {
				margin-top: 40 / 1200 * 100%;
			}
		}
		&_bdy {
			.-project-story & {
				display: flex;
				flex-direction: column;
			}
		}
	}
	@include mediaQueries-minW('pc-xm') { // desktop size = XM //
		font-size: inherit;
	}
}

.int-oth-sct {
	position: relative;
	&_bdy {
		@extend %extend--inner-box-1200;
		position: relative;
		z-index: 5;
	}
	&_ttl {
		color: $siteCol-f-wht;
		text-align: center;
	}
	&_bg {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		&::before,
		&::after {
			content: '';
			width: 100%;
			height: 100%;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
		}
		&::before {
			background: {
				image: imgUrl('img_bg_noise.png');
				position: center;
				size: #{50 / $basePcFz + 0em} #{50 / $basePcFz + 0em};
			}
			mix-blend-mode: soft-light;
			opacity: 0.3;
			z-index: 10;
		}
		&::after {
			background-color: rgba($siteCol-d-blu01, 0.8);
			z-index: 5;
			backdrop-filter: blur(5px);
			-webkit-backdrop-filter: blur(5px);
		}
		& > * {
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: relative;
			z-index: 0;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		// padding: #{6 / $baseMblFz + 0em} 0;
		padding: 60 / 500 * 100vw 0;
		&_ttl {
			font-size: 20 / 425 * 100vw;
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		&_ttl {
			font-size: #{2 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{8 / $basePcFz + 0em} 0;
		&_ttl {
			font-size: #{2.4 / $basePcFz + 0em};
		}
	}
}

.int-inr-oth-sct {
	max-width: #{94 / $basePcFz + 0em};
	margin: 0 auto;
	padding: #{3 / $basePcFz + 0em};
	background-color: $siteCol-f-wht;
	overflow: hidden;
	position: relative;
	&_ttl {
		.-tlk & {
			margin: 0 auto;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			&::after {
				content: '×';
				margin: 0 2 / 3.8 + 0em;
				font: {
					family: 'Noto Sans JP';
					size: #{3.8 / $basePcFz + 0em};
					weight: $fwNsnsJp-light;
				}
				line-height: 1;
				display: block;
				flex-shrink: 0;
			}
		}
		.-ps & {
			font-size: #{2.4 / $basePcFz + 0em};
		}
		&-unt,
		&-txt {
			display: block;
		}
		&-unt {
			&:nth-child(n+2) {
				order: 5;
			}
		}
		&-txt {
			&.-pst {
				margin-bottom: 0.3em;
				font-size: #{1.2 / $basePcFz + 0em};
			}
			&.-jp {
				font-size: #{3.6 / $basePcFz + 0em};
			}
			&.-en {
				color: $siteCol-ash-blu01;
				font: {
					size: #{1.8 / $basePcFz + 0em};
					style: italic;
				}
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top:  #{5 / $baseMblFz + 0em};
		padding: #{2 / $baseMblFz + 0em};
		font-size: 14 / 500 * 100vw;
		& + & {
			margin-top:  #{2.5 / $baseMblFz + 0em};
		}
		&_fig {
			margin-top:  #{2 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-top:  #{4 / $basePcFz + 0em};
		padding: #{3 / $basePcFz + 0em};
		display: flex;
		align-items: center;
		justify-content: space-between;
		&_ttl {
			.-ps & {
				padding: 0 1em;
			}
		}
		&_fig {
			max-width: #{40 / $basePcFz + 0em};
			margin-left: #{3 / $basePcFz + 0em};
			flex-shrink: 0;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		font-size: 16 / 1050 * 100vw;
	}
}

/* Data / Recruiter Voice
   ======================================== */
.dt-rv-art {
	&_bdy {
		@extend %extend--inner-box-1200;
	}
	&_inr {
		position: relative;
		&::after {
			content: '';
			width: 100vw;
			height: 100%;
			background-color: $siteCol-l-blu02;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
			.-rvrs & {
				right: 0;
				left: auto;
			}
		}
		& > * {
			position: relative;
			z-index: 10;
		}
	}
	&_cpy {
		margin-top: #{3 / $basePcFz + 0em};
	}
	&_fig {
		position: absolute;
		& > * {
			width: 100%;
			height: 100%;
			object: {
				fit: cover;
				position: center top;
			}
			.-rv & {
				object-position: 75% top;
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: 60 / 670 * 100vw;
		&.-rv {
			padding-bottom: #{4 / $baseMblFz + 0em};
		}
		& + & {
			margin-top: 320 / 670 * 100vw;
			margin-top: #{32 / $baseMblFz + 0em};
		}
		&_inr {
			&::after {
				transform: translate3d(0, 5.8vw, 0);
			}
		}
		&_cnt-unt {
			padding: 0 0 #{3 / $baseMblFz + 0em} #{2 / $baseMblFz + 0em};
			.-rvrs & {
				padding: 0 #{2 / $baseMblFz + 0em} #{3 / $baseMblFz + 0em} 0;
			}
		}
		&_fig {
			width: calc(100% + #{$mbl-pad});
			height: #{26 / $baseMblFz + 0em};
			top: 100%;
			left: -#{$mbl-pad};
			.-rvrs & {
				right: -#{$mbl-pad};
				left: auto;
			}
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		& + & {
			margin-top: 310 / 425 * 100vw;
		}
		&_fig {
			height: 260 / 425 * 100vw;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-top: #{12 / $basePcFz + 0em};
		&.-rv {
			padding-bottom: #{6 / $basePcFz + 0em};
		}
		&_inr {
			&::after {
				transform: translate3d(0, 2.8em, 0);
			}
		}
		&_cnt-unt {
			width: calc(50% + #{6 / $basePcFz + 0em});
			margin-left: auto;
			padding-left: #{6 / $basePcFz + 0em};
			.-rvrs & {
				padding: 0 #{6 / $basePcFz + 0em} 0 0;
				margin-left: 0;
			}
		}
		&_fig {
			width: 640 / 1400 * 100vw;
			height: 390 / 349 * 100%;
			max-width: #{64 / $basePcFz + 0em};
			right: 100%;
			.-rvrs & {
				right: auto;
				left: 100%;
			}
			.-dt & {
				bottom: 0;
			}
			.-rv & {
				top: 5.5em;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_cnt-unt {
			width: calc(50% + #{4 / $basePcFz + 0em});
			padding-left: #{4 / $basePcFz + 0em};
			.-rvrs & {
				padding: 0 #{4 / $basePcFz + 0em} 0 0;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		margin-top: #{8 / $basePcFz + 0em};
		& + & {
			margin-top: #{42 / $basePcFz + 0em};
		}
		&_cnt-unt {
			width: 100%;
			padding-bottom: #{4 / $basePcFz + 0em} !important;
		}
		&_fig {
			width: calc(100% + #{$tblt-pad});
			height: #{34 / $basePcFz + 0em};
			max-width: none;
			right: auto;
			left: -#{$tblt-pad};
			.-rvrs & {
				right: -#{$tblt-pad};
				left: auto;
			}
			.-dt &,
			.-rv & {
				top: 100%;
			}
			.-dt & {
				bottom: auto;
			}
		}
	}
}

.wt-sct {
	& > * {
		position: relative;
	}
	&_inr {
		margin: -70 / 600 * 100% 0 0 auto;
		background-color: $siteCol-f-wht;
		z-index: 5;
	}
	&_cpy {
		margin-top: #{2 / $basePcFz + 0em};
	}
	&_fig {
		z-index: 0;
		& > * {
			width: 100%;
			height: 100%;
			object: {
				fit: cover;
				position: center top;
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		& + & {
			margin-top: #{2 / $baseMblFz + 0em};
		}
		&-otr {
			margin-top: #{32 / $baseMblFz + 0em};
		}
		&_inr,
		&_fig {
			width: calc(100% - #{2 / $baseMblFz + 0em});
		}
		&_inr {
			padding: #{2 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		&-otr {
			margin-top: 320 / 425 * 100vw;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		width: calc(100% / 2);
		&.-tr {
			padding-top: #{4 / $basePcFz + 0em};
		}
		&-otr {
			margin-top: #{16 / $basePcFz + 0em};
			display: flex;
		}
		&_inr,
		&_fig {
			width: 540 / 600 * 100%;
		}
		&_inr {
			width: 540 / 600 * 100%;
			padding: #{2 / $basePcFz + 0em} #{3 / $basePcFz + 0em};
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		width: 100%;
		& + & {
			margin-top: #{4 / $basePcFz + 0em};
		}
		&.-tr {
			padding-top: 0;
		}
		&-otr {
			margin-top: #{42 / $basePcFz + 0em};
			display: block;
		}
		&_inr,
		&_fig {
			width: calc(100% - #{4 / $basePcFz + 0em});
		}
	}
}

.sgk-dt-intr {
	padding: 1em;
	font: {
		size: #{2.4 / $basePcFz + 0em};
		weight: $fwNsrfJp-bold;
	}
	line-height: 1.4;
	text-align: center;
	background-color: $siteCol-gry03;
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		font-size: 21 / 425 * 100vw;
	}
}

.sgk-dt-art {
	background-color: $siteCol-blu03;
	overflow: hidden;
	&_inr {
		@extend %extend--inner-box-1200;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		font-size: 14 / 425 * 100vw;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		font-size: 16 / 1240 * 100vw;
		&_flx {
			display: flex;
			& > * {
				width: 50%;
			}
		}
	}
	@include mediaQueries-minW('pc-xm') { // desktop size = XM //
		font-size: inherit;
	}
	@include mediaQueries-minW('pc-m') { // desktop size = M //
		font-size: 16 / 1370 * 100vw;
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		font-size: inherit;
		&_flx {
			display: block;
			& > * {
				width: 100%;
			}
		}
	}
}

.sgk-dt-sct {
	background-color: $siteCol-d-blu01;
	&.-typ-01 {
		background-color: $siteCol-gry06;
	}
	&.-typ-03 {
		background-color: $siteCol-blu03;
	}
	&.-typ-05 {
		background-color: $siteCol-l-blu03;
	}
	&.-typ-06 {
		background-color: $siteCol-ash-blu03;
	}
	&.-typ-07 {
		background-color: $siteCol-d-grn02;
	}
	&.-typ-08 {
		background-color: $siteCol-f-wht;
	}
	&-otr {
		background-color: $siteCol-d-blu01;
	}
	&_bdy {
		@extend %extend--inner-box-1200;
	}
	&_ttl {
		color: $siteCol-d-blu01;
		text-align: center;
		&.-wht {
			color: $siteCol-f-wht;
		}
		.-typ-04 & {
			text-align: left;
		}
		&-txt {
			padding: 0.25em 0.75em;
			border: 0.6 / 3.6 + 0em solid currentColor;
			border-radius: 100em;
			display: inline-block;
		}
	}
	&_cpy {
		margin: 0 auto;
		text-align: center;
		.-typ-01 &,
		.-typ-04 &,
		.-typ-07 & {
			position: relative;
		}
		.-typ-01 & {
			& > * {
				&:nth-child(2) {
					width: 260 / 106 * 100%;
					max-width: none;
					position: absolute;
					top: 30%;
					left: $baseCenterPos;
					transform: translate3d(-#{$baseCenterPos}, -#{$baseCenterPos}, 0);
				}
			}
		}
		.-typ-03 & {
			max-width: #{46 / $basePcFz + 0em};
		}
		.-typ-04 & {
			& > * {
				&:first-child {
					position: relative;
					z-index: 5;
				}
				&:nth-child(n+2) {
					position: absolute;
				}
				&:nth-child(2) {
					width: 142 / 504 * 100%;
					left: 35%;
				}
				&:nth-child(3) {
					width: 200 / 504 * 100%;
					right: -18%;
					bottom: -15%;
				}
			}
		}
		.-typ-05 & {
			max-width: #{32 / $basePcFz + 0em};
		}
		.-typ-06 & {
			max-width: #{25.6 / $basePcFz + 0em};
		}
		.-typ-07 & {
			max-width: #{34 / $basePcFz + 0em};
			transform: translate3d(-15%, 0, 0);
			& > * {
				&:nth-child(2) {
					position: absolute;
					width: 136 / 340 * 100%;
					top: $baseCenterPos;
					transform: translate3d(0, -#{$baseCenterPos}, 0);
				}
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{4 / $baseMblFz + 0em} 0;
		&.-typ-03 {
			padding-bottom: 40 / 425 * 100%;
		}
		&_ttl {
			margin-bottom: #{4 / $baseMblFz + 0em};
			&-txt {
				font-size: #{2.4 / $baseMblFz + 0em};
			}
			.-typ-01 & {
				margin-bottom: #{2 / $baseMblFz + 0em};
			}
		}
		&_cpy {
			.-typ-04 & {
				& > * {
					&:nth-child(2) {
						top: -26%;
					}
				}
			}
			.-typ-07 & {
				& > * {
					&:nth-child(2) {
						left: 92%;
						z-index: -1;
					}
				}
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{6 / $basePcFz + 0em} 0;
		&_bdy {
			.-typ-04 & {
				padding-top: 15%;
				padding-top: #{10 / $basePcFz + 0em};
			}
		}
		&_ttl {
			margin-bottom: #{5 / $basePcFz + 0em};
			.-typ-01 & {
				text-align: left;
			}
			.-typ-01 & {
				margin-bottom: -#{8 / $basePcFz + 0em};
			}
			.-typ-02 & {
				margin-bottom: #{2 / $basePcFz + 0em};
			}
			.-typ-04 & {
				margin-bottom: -#{10 / $basePcFz + 0em};
			}
			&-txt {
				font-size: #{3.6 / $basePcFz + 0em};
			}
		}
		&_cpy {
			.-typ-01 & {
				max-width: #{106 / $basePcFz + 0em};
			}
			.-typ-04 & {
				max-width: #{50.4 / $basePcFz + 0em};
				& > * {
					&:nth-child(2) {
						top: -30%;
					}
				}
			}
			.-typ-07 & {
				& > * {
					&:nth-child(2) {
						left: 105%;
					}
				}
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_ttl {
			.-typ-01 & {
				margin-bottom: #{3 / $basePcFz + 0em};
				text-align: center;
			}
		}
	}
}

.dt-gndr-cpy {
	color: $siteCol-f-wht;
	text-align: center;
	&_txt {
		font: {
			size: #{4 / $basePcFz + 0em};
			weight: $fwNsnsJp-bold;
		}
		line-height: 1;
		display: inline-block;
		transform: translate3d(0, 0.1em, 0);
	}
}

.dt-gndr-lst {
	margin-top: 2em;
	text-align: center;
	display: flex;
	align-items: flex-end;
	&_itm {
		width: 50%;
		position: relative;
		& > .dt-gndr-lst_txt {
			width: 88%;
			position: relative;
			z-index: 5;
		}
		& > .dt-gndr-lst_obj {
			width: 100%;
		}
		&:first-child {
			& > .dt-gndr-lst_txt {
				max-width: #{41.4 / $basePcFz + 0em};
			}
			& > .dt-gndr-lst_obj {
				max-width: #{14.8 / $basePcFz + 0em};
			}
		}
		&:nth-child(2) {
			& > .dt-gndr-lst_txt {
				max-width: #{36 / $basePcFz + 0em};
			}
			& > .dt-gndr-lst_obj {
				max-width: #{11.8 / $basePcFz + 0em};
			}
		}
	}
	&_obj {
		position: absolute;
		bottom: -#{$baseCenterPos};
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_itm {
			font-size: 14 / 670 * 100vw;
			&:first-child {
				& > .dt-gndr-lst_obj {
					left: -3%;
				}
			}
			&:nth-child(2) {
				& > .dt-gndr-lst_obj {
					right: 3%;
				}
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_itm {
			&:first-child {
				& > .dt-gndr-lst_obj {
					left: 15%;
				}
			}
			&:nth-child(2) {
				& > .dt-gndr-lst_obj {
					left: 35%;
				}
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_itm {
			font-size: 16 / 860 * 100vw;
			&:first-child {
				& > .dt-gndr-lst_obj {
					left: 10%;
				}
			}
			&:nth-child(2) {
				& > .dt-gndr-lst_obj {
					left: 55%;
				}
			}
		}
	}
}

.dt-rct-lst {
	margin-bottom: 4 / 2.4 + 0em;
	color: $siteCol-f-wht;
	font-size: #{2.4 / $basePcFz + 0em};
	line-height: 1.5;
	border: 2px solid currentColor;
	&_trm,
	&_dsc {
		padding: 0.5em 0.75em;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		text-align: center;
		&_dsc {
			border-top: 2px solid currentColor;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		display: flex;
		align-items: center;
		&_trm,
		&_dsc {
			width: 50%;
		}
		&_dsc {
			border-left: 2px solid currentColor;
		}
	}
}

/* Office
   ======================================== */
.ofc-atr {
	&_bdy {
		@extend %extend--inner-box-1200;
	}
	&_fig {
		max-width: #{117 / $basePcFz + 0em};
		margin: #{3 / $basePcFz + 0em} auto 0;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: 0 0 #{4 / $baseMblFz + 0em};
		&_ttl {
			font-size: #{1.8 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{4 / $basePcFz + 0em} 0 #{8 / $basePcFz + 0em};
		&_ttl {
			font-size: #{2.4 / $basePcFz + 0em};
		}
	}
}

.ofc-sct {
	&_intr {
		background-color: $siteCol-wht01;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		& + & {
			margin-top: #{4 / $baseMblFz + 0em};
		}
		&_intr {
			padding: #{2 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		& + & {
			margin-top: #{8 / $basePcFz + 0em};
		}
		&_intr {
			padding: #{3 / $basePcFz + 0em};
		}
	}
}

.ofc-thmb-lst {
	display: flex;
	flex-wrap: wrap;
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: #{2 / $baseMblFz + 0em};
		justify-content: space-between;
		&_itm {
			width: 187.5 / 385 * 100%;
			&:nth-child(n+3) {
				margin-top: 10 / 385 * 100%;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-top: #{4 / $basePcFz + 0em};
		&_itm {
			width: 285 / 1200 * 100%;
			&:nth-child(n+2) {
				margin-left: 20 / 1200 * 100%;
			}
			&:nth-child(4n+1) {
				margin-left: 0;
			}
			&:nth-child(n+5) {
				margin-top: 20 / 1200 * 100%;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_itm {
			width: 248 / 780 * 100%;
			&:nth-child(n+2) {
				margin-left: 18 / 780 * 100%;
			}
			&:nth-child(3n+1) {
				margin-left: 0;
			}
			&:nth-child(n+4) {
				margin-top: 20 / 780 * 100%;
			}
		}
	}
}

.ofc-thmb {
	line-height: 1.5;
	&_cpt {
		margin-top: 0.75em;
		text-align: center;
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		font-size: 14 / 425 * 100vw;
	}
}

/* Training
   ======================================== */
.tr-tbl {
	width: #{119.6 / $basePcFz + 0em};
	line-height: 1.5;
	background-color: $siteCol-wht01;
	display: block;
	& > * {
		display: block;
	}
	&-otr {
		max-width: #{120 / $basePcFz + 0em};
		margin: #{3 / $basePcFz + 0em} auto #{6 / $basePcFz + 0em};
		border: #{0.2 / $basePcFz + 0em} solid $siteCol-gry02;
		overflow-x: scroll;
	}
	&_tr, &_th, &_td {
		display: block;
	}
	&_tr {
		display: flex;
		&.-typ-01 {
			&::before {
				content: '';
				width: #{8 / $basePcFz + 0em};
				height: #{8 / $basePcFz + 0em};
				background-color: $siteCol-d-blu01;
				display: block;
				flex-shrink: 0;
			}
		}
		&:nth-child(n+2) {
			border-top: #{0.2 / $basePcFz + 0em} solid $siteCol-gry02;
		}
	}
	&_th {
		padding: 0.5em 0.25em;
		color: $siteCol-f-wht;
		font-size: #{2.6 / $basePcFz + 0em};
		text-align: center;
		background-color: $siteCol-d-blu01;
		display: flex;
		align-items: center;
		justify-content: center;
		.-typ-01 & {
			border-left: 0.2001 / 2.6 + 0em solid $siteCol-gry02;
			.-tbl-mce & {
				width: calc(100% / 3);
			}
		}
		&:first-child {
			.-typ-01 & {
				.-tbl-ne & {
					width: 12 / 2.6 + 0em;
					flex-shrink: 0;
				}
			}
			.-typ-02 & {
				width: 8 / 2.6 + 0em;
				writing-mode: vertical-rl;
				flex-shrink: 0;
			}
		}
		&:nth-child(n+2) {
			.-typ-01 & {
				.-tbl-ne & {
					width: calc((100% - #{12 / 2.6 + 0em}) / 3);
				}
			}
		}
	}
	&_td {
		width: 100%;
		padding: #{2 / $basePcFz + 0em} 0 #{2 / $basePcFz + 0em} #{2 / $basePcFz + 0em};
		border-left: #{0.2 / $basePcFz + 0em} solid $siteCol-gry02;
	}
	&_flx {
		display: flex;
		align-items: flex-start;
		&.-typ-ne-01 {
			& > * {
				&:nth-child(2) {
					margin-left: #{4.2 / $basePcFz + 0em};
				}
				&:nth-child(n+3) {
					margin-left: #{1 / $basePcFz + 0em};
				}
			}
		}
		&.-typ-ne-02 {
			width: 100%;
			flex-wrap: wrap;
			& > * {
				&:nth-child(3) {
					margin-top: #{1 / $basePcFz + 0em};
				}
			}
		}
		&.-typ-mce-01 {
			& > * {
				&:nth-child(n+2) {
					margin-left: #{1 / $basePcFz + 0em};
				}
			}
		}
		&.-typ-mce-02 {
			width: 100%;
			flex-wrap: wrap;
			& > * {
				&:nth-child(3) {
					margin-top: #{1.4 / $basePcFz + 0em};
				}
			}
		}
		&-itm {
			.-typ-ne-02 & {
				&:first-child {
					width: #{49.2 / $basePcFz + 0em};
					& > * {
						&:first-child {
							width: 34%;
						}
						&:nth-child(2) {
							width: 68%;
						}
						&:nth-child(n+2) {
							margin-top: #{1 / $basePcFz + 0em};
						}
					}
				}
				&:nth-child(2) {
					width: #{33 / $basePcFz + 0em};
					margin-left: #{1 / $basePcFz + 0em};
					& > * {
						&:nth-child(n+2) {
							margin-top: #{1.5 / $basePcFz + 0em};
						}
					}
				}
			}
			.-typ-mce-02 & {
				&:first-child {
					width: #{64.4 / $basePcFz + 0em};
					& > * {
						&:nth-last-child(n+2) {
							width: 72.5%;
						}
					}
				}
				&:nth-child(2) {
					width: #{37 / $basePcFz + 0em};
					margin-left: #{1 / $basePcFz + 0em};
				}
				& > * {
					&:nth-child(n+2) {
						margin-top: #{1.3 / $basePcFz + 0em};
					}
				}
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&-otr {
			font-size: #{0.8 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&-otr {
			font-size: 16 / 1370 * 100vw;
		}
	}
	@include mediaQueries-minW('pc-m') { // desktop size = M //
		&-otr {
			font-size: inherit;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&-otr {
			font-size: #{1 / $basePcFz + 0em};
		}
	}
}

/* Business
   ======================================== */
.sgk-biz-art {
	&_bdy {
		@extend %extend--inner-box-1200;
	}
	&_hdr {
		background: {
			image: imgUrl('img_sgk_biz_hr.webp');
			repeat: no-repeat;
			position: center;
			size: cover;
		}
		&-bdy {
			@extend %extend--inner-box-1200;
			height: 100%;
			position: relative;
			display: flex;
			flex-direction: column;
		}
		.no-webp & {
			background-image: imgUrl('img_sgk_biz_hr.jpg');
		}
	}
	&_ttl {
		color: $siteCol-f-wht;
		line-height: 1.8;
		transform: translate3d(0, 25%, 0);
		&-txt {
			padding: 0 0.25em;
			background-color: $siteCol-d-blu01;
		}
	}
	&_intr {
		margin: auto 0 -#{5 / $basePcFz + 0em};
		padding: #{2 / $basePcFz + 0em} 0;
		color: $siteCol-f-wht;
		position: relative;
		z-index: 0;
		&::after {
			content: '';
			width: 150vw;
			height: 100%;
			background-color: $siteCol-d-blu01;
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			z-index: -1;
		}
	}
	&_cnt-anch {
		width: #{25 / $basePcFz + 0em};
		height: #{25 / $basePcFz + 0em};
		line-height: 1;
		text-align: center;
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 5;
		transform: translate3d(0, #{$baseCenterPos}, 0);
		&,
		&-lnk {
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&-lnk {
			width: #{18 / $basePcFz + 0em};
			height: #{12.2 / $basePcFz + 0em};
		}
		&-icn {
			display: block;
			&::before {
				content: '\e901';
				color: $siteCol-d-blu01;
				font-size: #{34 / $basePcFz + 0em};
				@include useIcomoon;
				display: block;
				transform: rotate(90deg);
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_bdy {
			padding: {
				top: #{8.4 / $baseMblFz + 0em};
				bottom: #{4 / $baseMblFz + 0em};
			}
		}
		&_hdr {
			height: 400 / 425 * 100vw;
		}
		&_ttl {
			font-size: 25 / 425 * 100vw;
		}
		&_intr {
			padding-right: #{2 / $baseMblFz + 0em};
		}
		&_cnt-anch {
			display: none;
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		&_bdy {
			padding-top: 84 / 425 * 100vw;
		}
		&_hdr {
			font-size: 14 / 425 * 100vw;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_bdy {
			padding: {
				top: #{12 / $basePcFz + 0em};
				bottom: #{8 / $basePcFz + 0em};
			}
		}
		&_hdr {
			height: #{62 / $basePcFz + 0em};
		}
		&_ttl {
			font-size: #{5.4 / $basePcFz + 0em};
		}
		&_intr {
			width: calc(100% - #{25 / $basePcFz + 0em});
			padding-right: #{4 / $basePcFz + 0em};
		}
		&_cnt-anch {
			right: $pc-pad;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_hdr {
			height: 620 / 1050 * 100vw;
		}
		&_ttl {
			font-size: 54 / 1050 * 100vw;
		}
		&_cnt-anch {
			right: $tblt-pad;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_intr {
			width: 100%;
		}
		&_cnt-anch {
			display: none;
		}
	}
}

.sgk-biz-sct {
	&_cpy {
		margin-top: #{2.5 / $basePcFz + 0em};
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		& + & {
			margin-top: #{4 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		& + & {
			margin-top: #{8 / $basePcFz + 0em};
		}
	}
}

.rntl-flw {
	margin-top: #{5.5 / $basePcFz + 0em};
	color: $siteCol-f-wht;
	background-color: $siteCol-l-blu03;
	position: relative;
	&_ttl {
		width: 50 / 3 + 0em;
		height: 6 / 3 + 0em;
		color: $siteCol-l-blu03;
		text-align: center;
		background-color: $siteCol-gry03;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		z-index: 5;
	}
	&_fig {
		&-img {
			max-width: #{110 / $basePcFz + 0em};
			margin: 0 auto;
		}
	}
	&_cpt {
		margin-top: 1.5 / 2.4 + 0em;
		font-weight: $fwNsnsJp-bold;
		text-align: center;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{4 / $baseMblFz + 0em} #{2 / $baseMblFz + 0em} #{1.5 / $baseMblFz + 0em};
		&_ttl {
			font-size: #{2 / $baseMblFz + 0em};
			left: $baseCenterPos;
			transform: translate3d(-#{$baseCenterPos}, -#{$baseCenterPos}, 0);
		}
		&_cpt {
			font-size: #{1.8 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		font-size: 14 / 425 * 100vw;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{6 / $basePcFz + 0em} #{2.5 / $basePcFz + 0em} #{2 / $basePcFz + 0em};
		&_ttl {
			font-size: #{3 / $basePcFz + 0em};
			left: 0;
			transform: translate3d(-#{2 / 3 + 0em}, -#{$baseCenterPos}, 0);
		}
		&_cpt {
			font-size: #{2.4 / $basePcFz + 0em};
		}
	}
}

.sgk-biz-fig {
	padding: #{2 / $basePcFz + 0em} #{3 / $basePcFz + 0em} #{3 / $basePcFz + 0em};
	background-color: $siteCol-gry03;
	&.-cnsl {
		margin-top: #{2.5 / $basePcFz + 0em};
	}
	&_img {
		margin-top: 0.5em;
	}
	&_cpt {
		font-weight: $fwNsnsJp-bold;
		line-height: 1.4;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{1.5 / $baseMblFz + 0em} #{2 / $baseMblFz + 0em} #{2 / $baseMblFz + 0em};
		&_itm {
			&:nth-child(n+2) {
				margin-top: #{1.5 / $baseMblFz + 0em};
			}
		}
		&_img {
			& > * {
				&:nth-child(n+2) {
					.-rntl & {
						margin-top: #{1.5 / $baseMblFz + 0em};
					}
				}
			}
		}
		&_cpt {
			font-size: #{1.8 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		
		padding: #{2 / $basePcFz + 0em} #{3 / $basePcFz + 0em} #{3 / $basePcFz + 0em};
		&.-cnsl {
			display: flex;
			justify-content: space-between;
		}
		&_itm {
			.-cnsl & {
				// width: calc((100% - #{3 / $basePcFz + 0em}) / 2);
				width: 555 / 1140 * 100%;
			}
		}
		&_img {
			.-rntl & {
				display: flex;
				justify-content: space-between;
			}
			& > * {
				.-rntl & {
					// width: calc((100% - #{3 / $basePcFz + 0em}) / 2);
					width: 555 / 1140 * 100%;
				}
			}
		}
		&_cpt {
			font-size: #{2.4 / $basePcFz + 0em};
		}
	}
}

/* History
   ======================================== */
.hst-art {
	background: {
		color: $siteCol-gry05;
		image: imgUrl('img_bg_lttc.svg');
		position: center;
		size: #{2 / $basePcFz + 0em} #{2 / $basePcFz + 0em};
	}
	&_bdy {
		@extend %extend--inner-box-1200;
		position: relative;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{4 / $baseMblFz + 0em} 0 0;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{6 / $basePcFz + 0em} 0 0;
	}
}

.hst-bg {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	&_img {
		width: 100%;
		max-width: #{100 / $basePcFz + 0em};
		opacity: 0.2;
		position: absolute;
		&:first-child {
			top: 5.5%;
			left: -7%;
		}
		&:nth-child(2) {
			top: 21%;
			left: -8%;
		}
		&:nth-child(3) {
			top: 34.2%;
			left: 24%;
		}
		&:nth-child(4) {
			top: 55%;
			left: 35%;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		display: none;
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		display: none;
	}
}

.hst-sct {
	position: relative;
	z-index: 10;
	&_ttl {
		color: $siteCol-f-wht;
		text-align: center;
		padding: 0.5em;
		background-color: $siteCol-d-blu01;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_ttl {
			font-size: #{2 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_ttl {
			font-size: #{3 / $basePcFz + 0em};
		}
	}
}

.hst-lst {
	position: relative;
	z-index: 0;
	&::after {
		content: '';
		height: 100%;
		background-color: $siteCol-d-blu01;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}
	&_itm {
		position: relative;
		&::before {
			content: '';
			border: solid rgba($siteCol-f-wht, 0.95);
			border-radius: 50%;
			background-color: $siteCol-d-blu01;
			display: block;
			flex-shrink: 0;
		}
	}
	&_ttl {
		height: 5.4 / 3.8 + 0em;
		color: $siteCol-f-wht;
		text-align: center;
		background-color: $siteCol-d-blu01;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		&::before {
			content: '';
			width: 4.8 / 3.8 + 0em;
			height: 100%;
			background-color: $siteCol-d-blu01;
			display: block;
			position: absolute;
			top: $baseCenterPos;
			right: calc(100% + #{1 / 3.8 + 0em});
			transform: translate3d(0, -#{$baseCenterPos}, 0);
			clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
		}
	}
	&_inr {
		margin-top: #{1 / $basePcFz + 0em};
		background-color: $siteCol-f-wht;
	}
	&_cpy {
		& + & {
			margin-top: 1em;
		}
	}
	&_fig {
		line-height: 1.5;
		&.-flx {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
		&-itm {
			.-flx & {
				width: 235 / 490 * 100%;
				margin-top: 20 / 490 * 100%;
				&:nth-child(even) {
					margin-left: auto;
				}
			}
		}
	}
	&_cpt {
		background-color: $siteCol-f-wht;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{4 / $baseMblFz + 0em} 0;
		.-typ-04 & {
			padding-bottom: 140 / 670 * 100vw !important;
		}
		&::after {
			width: #{0.8 / $baseMblFz + 0em};
			left: #{1.3 / $baseMblFz + 0em};
		}
		&_itm {
			display: flex;
			justify-content: space-between;
			&::before {
				width: #{3.4 / $baseMblFz + 0em};
				height: #{3.4 / $baseMblFz + 0em};
				border-width: #{0.7 / $baseMblFz + 0em};
			}
			&:nth-child(n+2) {
				margin-top: #{3 / $baseMblFz + 0em};
			}
		}
		&_bdy {
			width: calc(100% - #{8 / $baseMblFz + 0em});
		}
		&_ttl {
			font-size: #{2.4 / $baseMblFz + 0em};
		}
		&_inr {
			padding: #{2 / $baseMblFz + 0em};
		}
		&_cpt {
			padding: 0.5em 0.75em;
		}
	}
	@include mediaQueries-maxW('mbl-s') { // mobile size = S //
		font-size: 14 / 375 * 100vw;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{6 / $basePcFz + 0em} 0;
		.-typ-01 & {
			padding-bottom: #{16 / $basePcFz + 0em};
		}
		.-typ-02 & {
			padding-bottom: #{37 / $basePcFz + 0em};
		}
		.-typ-03 & {
			padding-bottom: #{55 / $basePcFz + 0em};
		}
		.-typ-04 & {
			padding-bottom: #{60 / $basePcFz + 0em};
		}
		&::after {
			width: #{1 / $basePcFz + 0em};
			left: $baseCenterPos;
			transform: translate3d(-#{$baseCenterPos}, 0, 0);
		}
		&_itm {
			&.-yr-1955 {
				margin-top: #{15 / $basePcFz + 0em};
			}
			&.-yr-1971 {
				margin-top: #{78 / $basePcFz + 0em};
			}
			&.-yr-1978 {
				margin-top: #{52 / $basePcFz + 0em};
			}
			&.-yr-1979 {
				margin-top: #{10 / $basePcFz + 0em};
			}
			&.-yr-2002 {
				margin-top: #{12 / $basePcFz + 0em};
			}
			&.-yr-2004 {
				margin-top: #{12 / $basePcFz + 0em};
			}
			&.-yr-2007 {
				margin-top: #{18 / $basePcFz + 0em};
			}
			&.-yr-2008 {
				margin-top: #{15 / $basePcFz + 0em};
			}
			&.-yr-2010 {
				margin-top: #{25 / $basePcFz + 0em};
			}
			&.-yr-2011 {
				margin-top: #{9 / $basePcFz + 0em};
			}
			&.-yr-2012-2 {
				margin-top: #{10 / $basePcFz + 0em};
			}
			&.-yr-2014 {
				margin-top: #{46 / $basePcFz + 0em};
			}
			&.-yr-2016 {
				margin-top: #{12 / $basePcFz + 0em};
			}
			&.-yr-2017 {
				margin-top: #{15 / $basePcFz + 0em};
			}
			&.-yr-2019 {
				margin-top: #{36 / $basePcFz + 0em};
			}
			&.-yr-2020 {
				margin-top: #{21 / $basePcFz + 0em};
			}
			&::before {
				width: #{5.4 / $basePcFz + 0em};
				height: #{5.4 / $basePcFz + 0em};
				margin: 0 auto;
				border-width: #{1.3 / $basePcFz + 0em};
			}
		}
		&_bdy {
			width: calc(50% - #{11 / $basePcFz + 0em});
			position: absolute;
			top: 0;
			.-lft & {
				left: 0;
			}
			.-rgt & {
				right: 0;
			}
		}
		&_ttl {
			font-size: #{3.8 / $basePcFz + 0em};
			&::before {
				.-lft & {
					right: auto;
					left: calc(100% + #{1 / 3.8 + 0em});
					clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
				}
			}
		}
		&_inr {
			padding: #{2 / $basePcFz + 0em};
		}
		&_cpt {
			padding: 0.5em 0.75em;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		padding-bottom: #{6 / $basePcFz + 0em} !important;
		.-typ-04 & {
			padding-bottom: 180 / 1050 * 100vw !important;
		}
		&::after {
			left: #{2.2 / $basePcFz + 0em};
			transform: translate3d(0, 0, 0);
		}
		&_itm {
			display: flex;
			justify-content: space-between;
			&::before {
				margin: 0;
			}
			&:nth-child(n+2) {
				margin-top: #{3 / $basePcFz + 0em};
			}
		}
		&_bdy {
			width: calc(100% - #{12.5 / $basePcFz + 0em});
			position: static;
		}
		&_ttl {
			font-size: #{3.8 / $basePcFz + 0em};
			&::before {
				.-lft & {
					right: calc(100% + #{1 / 3.8 + 0em});
					left: auto;
					clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
				}
			}
		}
	}
}

.hst-min-lst {
	position: relative;
	z-index: 0;
	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
	}
	&::before {
		width: #{4.2 / $basePcFz + 0em};
		height: #{14 / $basePcFz + 0em};
		background: {
			image: imgUrl('img_min_hst_arw.svg');
			repeat: no-repeat;
			position: center;
			size: 100% 100%;
		}
		top: 100%;
		z-index: 0;
	}
	&::after {
		height: 100%;
		background-color: $siteCol-d-blu01;
		top: 2px;
		left: 0;
		z-index: -1;
	}
	&_itm {
		position: relative;
		&::before {
			content: '';
			border: solid rgba($siteCol-f-wht, 0.95);
			border-radius: 50%;
			background-color: $siteCol-d-blu01;
			display: block;
			order: 5;
			flex-shrink: 0;
		}
	}
	&_ttl {
		height: 5.4 / 3.8 + 0em;
		color: $siteCol-f-wht;
		text-align: center;
		background-color: $siteCol-d-blu01;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&_inr {
		background-color: $siteCol-f-wht;
	}
	&_cpy {
		& + & {
			margin-top: 1em;
		}
	}
	&_fig {
		line-height: 1.5;
		&.-flx {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
		&-itm {
			.-flx & {
				width: 235 / 490 * 100%;
				&:nth-child(even) {
					margin-left: auto;
				}
				&:nth-child(n+3) {
					margin-top: 20 / 490 * 100%;
				}
			}
		}
	}
	&_cpt {
		background-color: $siteCol-f-wht;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: 0 0 #{4 / $baseMblFz + 0em};
		&::before {
			left: -#{0.15 / $baseMblFz + 0em};
			transform: translate3d(0, -75%, 0);
		}
		&::after {
			width: #{0.8 / $baseMblFz + 0em};
			left: #{1.3 / $baseMblFz + 0em};
		}
		&_itm {
			&::before {
				width: #{3.4 / $baseMblFz + 0em};
				height: #{3.4 / $baseMblFz + 0em};
				border-width: #{0.7 / $baseMblFz + 0em};
				position: absolute;
				top: 0;
				left: 0;
			}
			&:nth-child(n+2) {
				margin-top: #{3 / $baseMblFz + 0em};
			}
			& > * {
				width: calc(100% - #{5 / $baseMblFz + 0em});
				margin-left: auto;
			}
		}
		&_ttl {
			font-size: #{2.4 / $baseMblFz + 0em};
		}
		&_inr {
			padding: #{2 / $baseMblFz + 0em};
		}
		&_cpt {
			padding: 0.5em 0.75em;
		}
	}
	@include mediaQueries-maxW('mbl-s') { // mobile size = S //
		font-size: 14 / 375 * 100vw;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: 0 0 #{6 / $basePcFz + 0em};
		&::before {
			left: $baseCenterPos;
			transform: translate3d(-#{$baseCenterPos}, -75%, 0);
		}
		&::after {
			width: #{1 / $basePcFz + 0em};
			left: $baseCenterPos;
			transform: translate3d(-#{$baseCenterPos}, 0, 0);
		}
		&_itm {
			display: flex;
			&::before {
				width: #{5.4 / $basePcFz + 0em};
				height: #{5.4 / $basePcFz + 0em};
				margin: 0 auto;
				border-width: #{1.3 / $basePcFz + 0em};
			}
			&:nth-child(n+2) {
				margin-top: #{3 / $basePcFz + 0em};
			}
			& > * {
				width: calc(50% - #{6 / $basePcFz + 0em});
				&:nth-child(2) {
					order: 10;
				}
			}
		}
		&_bdy {
			// width: calc(50% - #{11 / $basePcFz + 0em});
		}
		&_ttl {
			font-size: #{3.8 / $basePcFz + 0em};
		}
		&_inr {
			padding: #{2 / $basePcFz + 0em};
		}
		&_cpt {
			padding: 0.5em 0.75em;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&::before {
			left: #{0.6 / $basePcFz + 0em};
			transform: translate3d(0, -75%, 0);
		}
		&::after {
			left: #{2.2 / $basePcFz + 0em};
			transform: translate3d(0, 0, 0);
		}
		&_itm {
			display: block;
			&::before {
				margin: 0;
				position: absolute;
				top: 0;
				left: 0;
			}
			& > * {
				// width: calc(50% - #{6 / $basePcFz + 0em});
				width: calc(100% - #{8.4 / $basePcFz + 0em});
				margin-left: auto;
			}
		}
		&_ttl {
			font-size: #{3.8 / $basePcFz + 0em};
		}
	}
}

.hst-bynd {
	color: $siteCol-f-wht;
	text-align: center;
	background-color: $siteCol-d-blu01;
	position: relative;
	&::before {
		content: '';
		width: 100vw;
		min-width: #{137 / $basePcFz + 0em};
		height: 100%;
		background-color: inherit;
		display: block;
		position: absolute;
		bottom: calc(100% - 1px);
		left: $baseCenterPos;
		transform: translate3d(-#{$baseCenterPos}, 0, 0);
		clip-path: polygon(0% 0%, 50% calc(100% - 1px), 100% 0%, 100% 100%, 0% 100%);
	}
	&_cpy {
		margin-top: 6 / 3.8 + 0em;
		font: {
			size: #{3.8 / $basePcFz + 0em};
			weight: $fwNsnsJp-bold;
		}
		line-height: 1.4;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{4 / $baseMblFz + 0em} #{2 / $baseMblFz + 0em};
		&::before {
			height: #{15 / $basePcFz + 0em};
		}
		&_cpy {
			margin-top: 4 / 3.325 + 0em;
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		font-size: 14 / 425 * 100vw;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{8 / $basePcFz + 0em} #{4 / $basePcFz + 0em};
		&::before {
			height: #{30 / $basePcFz + 0em};
		}
		&_cpy {
			margin-top: 6 / 3.8 + 0em;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&::before {
			height: #{15 / $basePcFz + 0em};
		}
	}
}

/* SDGs
   ======================================== */
.sdgs-art {
	&_bdy {
		@extend %extend--inner-box-1200;
	}
	&_cpy {
		margin-top: #{3 / $basePcFz + 0em};
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: 20 / 425 * 100vw 0 0;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{4 / $basePcFz + 0em} 0 0;
	}
}

.apr-sdgs-sct {
	margin-top: #{3 / $basePcFz + 0em};
	background-color: $siteCol-wht01;
	.sgk-abt-art & {
		padding: 0;
		background-color: transparent;
	}
	&_ttl {
		color: $siteCol-d-blu01;
		font-size: #{3 / $basePcFz + 0em};
	}
	&_cpy {
		margin-top: 1em;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{2 / $baseMblFz + 0em};
		&_fig {
			margin-bottom: #{4 / $basePcFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{4 / $basePcFz + 0em};
		&_fig {
			margin-bottom: #{6 / $basePcFz + 0em};
		}
	}
}

.sdgs-sct {
	& + & {
		margin-top: 0;
	}
	&_ttl {
		border-bottom: 2px solid $siteCol-d-blu01;
		.-sgk-m-grn & {
			border-color: $siteCol-grn01;
		}
		&-txt {
			padding: 0 0.75em;
			color: $siteCol-f-wht;
			background-color: $siteCol-d-blu01;
			.-sgk-m-grn & {
				background-color: $siteCol-grn01;
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: #{4 / $baseMblFz + 0em};
		&_ttl {
			font-size: #{2.4 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		&_ttl {
			font-size: 24 / 425 * 100vw;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-top: #{6 / $basePcFz + 0em};
		&_ttl {
			font-size: #{3.4 / $basePcFz + 0em};
		}
	}
}

.sdgs-dtl-sct {
	& + & {
		border-top: 2px solid $siteCol-d-blu01;
	}
	&_inr {
		background-color: $siteCol-wht01;
	}
	* + &_inr {
		margin-top: #{3 / $basePcFz + 0em};
	}
	&_ttl {
		color: $siteCol-d-blu01;
		font-size: #{3 / $basePcFz + 0em};
		.-sgk-m-grn & {
			color: $siteCol-grn01;
		}
		&.-min {
			font-size: #{2 / $basePcFz + 0em};
			text-align: justify;
		}
	}
	&_cpy {
		margin-top: #{2 / $basePcFz + 0em};
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{4 / $baseMblFz + 0em} 0;
		&_inr {
			padding: #{2 / $baseMblFz + 0em};
		}
		&_ttl {
			text-align: center;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{6 / $basePcFz + 0em} #{4 / $basePcFz + 0em};
		&_inr {
			padding: #{2.5 / $basePcFz + 0em} #{4 / $basePcFz + 0em};
		}
	}
}

.gls-lst {
	margin-top: #{3 / $basePcFz + 0em};
	font-weight: $fwNsnsJp-bold;
	line-height: 1.4;
	&_trm,
	&_dsc {
		font-size: #{3 / $basePcFz + 0em};
	}
	&_trm {
		width: 16 / 3 + 0em;
		height: 5.5 / 3 + 0em;
		color: $siteCol-f-wht;
		text-align: center;
		background-color: $siteCol-gry01;
		display: flex;
		align-items: center;
		justify-content: center;
		.-no7 & {
			background-color: $siteCol-sdgs-no7;
		}
		.-no11 & {
			background-color: $siteCol-sdgs-no11;
		}
		.-no12 & {
			background-color: $siteCol-sdgs-no12;
		}
		.-no13 & {
			background-color: $siteCol-sdgs-no13;
		}
	}
	&_dsc {
		margin-top: 2 / 3 + 0em;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		text-align: center;
		&_itm {
			&:nth-child(n+2) {
				margin-top: #{4 / $baseMblFz + 0em};
			}
		}
		&_trm {
			margin: 0 auto;
		}
		&_fig {
			width: 24 / 2.625 + 0em;
			margin: 2 / 2.625 + 0em auto 0;
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //s
		&_dsc {
			font-size: 26.25 / 425 * 100vw;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_itm {
			padding-left: #{19.5 / $basePcFz + 0em};
			position: relative;
			&:nth-child(n+2) {
				margin-top: #{3 / $basePcFz + 0em};
			}
		}
		&_fig {
			width: 100%;
			max-width: 16.5 / 3 + 0em;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

.ysdgs-sct {
	background-color: $siteCol-l-blu02;
	&_bdy {
		@extend %extend--inner-box-1200;
	}
	&_ttl {
		color: $siteCol-d-blu01;
		font-size: #{3 / $basePcFz + 0em};
		text-align: center;
		&.-min {
			width: 22 / 2.4 + 0em;
			margin-top: 3 / 2.4 + 0em;
			color: $siteCol-f-wht;
			font-size: #{2.4 / $basePcFz + 0em};
			background-color: $siteCol-d-blu01;
		}
	}
	&_fig {
		text-align: center;
		background-color: $siteCol-f-wht;
		& > * {
			max-width: #{56 / $basePcFz + 0em};
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{4 / $baseMblFz + 0em} 0;
		&_fig {
			margin-top: #{2 / $baseMblFz + 0em};
			padding: #{3 / $baseMblFz + 0em} #{4 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		&_ttl {
			font-size: 26.25 / 425 * 100vw;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{6 / $basePcFz + 0em} 0;
		&_fig {
			margin-top: #{4 / $basePcFz + 0em};
			padding: #{6 / $basePcFz + 0em} #{4 / $basePcFz + 0em};
		}
	}
}

/* Network
   ======================================== */
.nw-art {
	&_bdy {
		@extend %extend--inner-box-1200;
		position: relative;
		z-index: 50;
	}
	&_flx {
		margin-top: 1em;
	}
	&_unt {
		padding: #{2 / $basePcFz + 0em};
		background-color: rgba($siteCol-blu01, 0.75);
		.-nw-02 & {
			background-color: rgba($siteCol-d-blu01, 0.75);
		}
		.-nw-03 & {
			background-color: rgba($siteCol-d-grn01, 0.75);
		}
		.-nw-04 & {
			background-color: rgba($siteCol-d-blu02, 0.75);
		}
	}
	&_ttl {
		color: $siteCol-blu01;
		line-height: 1.2;
		&-txt {
			min-width: 15 / 3.4 + 0em;
			padding: 0 0.5em;
			font-size:  #{3.4 / $basePcFz + 0em};
			text-align: center;
			background-color: $siteCol-f-wht;
			display: inline-block;
		}
		&.-min {
			color: $siteCol-l-blu09;
			font-size:  #{2.4 / $basePcFz + 0em};
			* + & {
				margin-top: 2 / 2.4 + 0em;
			}
		}
	}
	&_fig {
		width: 100%;
		position: absolute;
		top: $baseCenterPos;
		left: $baseCenterPos;
		transform: translate3d(-#{$baseCenterPos}, -#{$baseCenterPos}, 0);
		&.-jp {
			width: #{88 / $basePcFz + 0em};
			left: 56%;
			z-index: 40;
		}
		&.-vn {
			width: #{280 / $basePcFz + 0em};
			z-index: 0;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{4 / $baseMblFz + 0em} 0;
		&_inr {
			&:nth-child(n+2) {
				margin-top: #{1 / $basePcFz + 0em};
			}
		}
		&_unt {
			& + & {
				margin-top: #{1 / $baseMblFz + 0em};
			}
		}
		&_fig {
			display: none;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{4 / $basePcFz + 0em} 0;
		&_flx {
			display: flex;
		}
		&_inr {
			width: 100%;
			max-width: #{26 / $basePcFz + 0em};
			&:nth-child(n+2) {
				margin-left: #{1 / $basePcFz + 0em};
			}
		}
		&_unt {
			& + & {
				margin-top: #{1 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_flx {
			display: block;
		}
		&_inr {
			max-width: none;
			&:nth-child(n+2) {
				margin: #{1 / $basePcFz + 0em} 0 0;
			}
		}
		&_fig {
			display: none;
			&.-jp {
				// font-size: 16 / 860 * 100vw;
			}
		}
	}
}

.nw-lst {
	color: $siteCol-f-wht;
	* + & {
		margin-top: #{1 / $basePcFz + 0em};
	}
}

/* Contents
   ======================================== */
.rct-cnt-art {
	&_bdy {
		@extend %extend--inner-box-1200;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: 60 / 670 * 100vw 0 #{4 / $baseMblFz + 0em};
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{8 / $basePcFz + 0em} 0;
	}
}

.rct-cnt-tab-lst {
	color: $siteCol-f-wht;
	display: flex;
	flex-wrap: wrap;
	&_trm {
		max-width: #{22 / $basePcFz + 0em};
		order: 0;
		& > * {
			height: 100%;
		}
	}
	&_dsc {
		width: 100%;
		border: 1px solid currentColor;
		order: 5;
		display: none;
		&.is-shw {
			display: block;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top:  60 / 670 * 100vw;
		justify-content: space-between;
		&_trm {
			width: calc((100% - #{0.4 / $baseMblFz + 0em} * 3) / 4);
		}
		&_dsc {
			margin-top: #{4 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		font-size: 14 / 425 * 100vw;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		justify-content: center;
		&_trm {
			width: calc((100% - #{1 / $basePcFz + 0em} * 3) / 4);
			&:nth-child(n+2) {
				margin-left: #{1 / $basePcFz + 0em};
			}
		}
		margin-top: #{4 / $basePcFz + 0em};
		&_dsc {
			margin-top: #{6 / $basePcFz + 0em};
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_dsc {
			margin-top: 60 / 1050 * 100vw;
		}
	}
}

.rct-cnt-tab-inr-lst {
	color: $siteCol-f-wht;
	&_itm {
		min-height: #{27 / $basePcFz + 0em};
		padding: #{2 / $basePcFz + 0em};
		border: 1px solid currentColor;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		&::after {
			content: '';
			width: 100%;
			height: 100%;
			background: {
				repeat: no-repeat;
				position: center top;
				size: cover;
			}
			opacity: 0.2;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
			.is-load & {
				transition: 
					opacity $ovrDur $easeOutQuint,
					transform $ovrDur $easeOutQuint;
			}
		}
		&:first-child {
			&::after {
				.-sgk & {
					background-image: imgUrl('img_cnt_tab_thmb_01_1.jpg');
				}
				.-emp & {
					background-image: imgUrl('img_cnt_tab_thmb_02_1.jpg');
				}
				.-sys & {
					background-image: imgUrl('img_cnt_tab_thmb_03_1.jpg');
				}
				.-cmp & {
					background-image: imgUrl('img_cnt_tab_thmb_04_1.jpg');
				}
			}
		}
		&:nth-child(2) {
			&::after {
				.-sgk & {
					background-image: imgUrl('img_cnt_tab_thmb_01_2.jpg');
				}
				.-emp & {
					background-image: imgUrl('img_cnt_tab_thmb_02_2.jpg');
				}
				.-sys & {
					background-image: imgUrl('img_cnt_tab_thmb_03_2.jpg');
				}
				.-cmp & {
					background-image: imgUrl('img_cnt_tab_thmb_04_2.jpg');
				}
			}
		}
		&:nth-child(3) {
			&::after {
				.-sgk & {
					background-image: imgUrl('img_cnt_tab_thmb_01_3.jpg');
				}
				.-emp & {
					background-image: imgUrl('img_cnt_tab_thmb_02_3.jpg');
				}
				.-cmp & {
					background-image: imgUrl('img_cnt_tab_thmb_04_3.jpg');
				}
			}
		}
		&:nth-child(4) {
			&::after {
				.-sgk & {
					background-image: imgUrl('img_cnt_tab_thmb_01_4.jpg');
				}
				.-cmp & {
					background-image: imgUrl('img_cnt_tab_thmb_04_4.jpg');
				}
			}
		}
		&:nth-child(5) {
			&::after {
				.-sgk & {
					background-image: imgUrl('img_cnt_tab_thmb_01_5.jpg');
				}
				.-cmp & {
					background-image: imgUrl('img_cnt_tab_thmb_04_5.jpg');
				}
			}
		}
		&:nth-child(6) {
			&::after {
				.-sgk & {
					background-image: imgUrl('img_cnt_tab_thmb_01_6.jpg');
				}
			}
		}
		.is-load & {
			transition: filter $ovrDur $easeOutQuint;
		}
		.ua-mbl & {
			&:active {
				filter: brightness(125%);
				&::after {
					opacity: 0.4;
					transform: scale(1.075);
				}
			}
		}
		body:not(.ua-mbl) & {
			&:hover {
				filter: brightness(125%);
				&::after {
					opacity: 0.4;
					transform: scale(1.075);
				}
			}
		}
		& > * {
			position: relative;
			z-index: 5;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		display: flex;
		flex-wrap: wrap;
		&_itm {
			width: calc(100% / 3);
			.-col-2 & {
				width: 50%;
			}
			&:nth-child(n+4) {
				.-col-3-2 & {
					width: 50%;
				}
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		font-size: 16 / 1050 * 100vw;
	}
}

/* Entry
   ======================================== */
.ent-atr {
	&.-gry {
		background-color: $siteCol-wht01;
	}
	&_bdy {
		@extend %extend--inner-box-1200;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{4 / $baseMblFz + 0em} 0;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{8 / $basePcFz + 0em} 0;
	}
}

.not-rct {
	// max-width: 46 / 2.4 + 0em;
	margin: 0 auto;
	font: {
		size: #{2.4 / $basePcFz + 0em};
		weight: $fwNsnsJp-bold;
	}
	line-height: 1.5;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	&_txt {
		padding: 0 1em;
		color: $siteCol-f-wht;
		background-color: $siteCol-d-blu01;
		display: block;
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		font-size: 21 / 425 * 100vw;
	}
}

.ent-lnk-unt {
	background-color: $siteCol-l-blu08;
	&_bdy {
		@extend %extend--inner-box-1200;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: #{2 / $baseMblFz + 0em} 0;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{3 / $basePcFz + 0em} 0;
	}
}

.rct-typ-lst {
	line-height: 1.5;
	&_itm {
		&:nth-child(n+2) {
			margin-top: #{2 / $basePcFz + 0em};
		}
	}
	&_trm {
		width: 100%;
		max-width: #{20 / $basePcFz + 0em};
		padding-left: #{1 / $basePcFz + 0em};
		color: $siteCol-f-wht;
		text-align: center;
		background-color: $siteCol-blu03;
		flex-shrink: 0;
		.-cntr & {
			background-color: $siteCol-l-blu03;
		}
		&-txt {
			padding: 0.5em 1em;
			border-left: #{0.4 / $basePcFz + 0em} solid $siteCol-f-wht;
			display: block;
		}
	}
	&_dsc {
		display: flex;
		& > * {
			margin: 0;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_dsc {
			margin-top: #{1.2 / $baseMblFz + 0em};
			flex-wrap: wrap;
			justify-content: space-between;
			& > * {
				max-width: none !important;
				&:nth-child(n+2) {
					// margin-left: #{1 / $baseMblFz + 0em};
				}
			}
			&.-odd {
				& > * {
					width: calc((100% - #{0.6 / $baseMblFz * 2 + 0em}) / 3);
					&:nth-child(n+4) {
						margin-top: #{0.6 / $baseMblFz + 0em};
					}
				}
			}
			&.-even {
				& > * {
					width: calc((100% - #{0.6 / $baseMblFz + 0em}) / 2);
					&:nth-child(n+3) {
						margin-top: #{0.6 / $baseMblFz + 0em};
					}
				}
			}
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		&_dsc {
			& > * {
				font-size: 14 / 425 * 100vw;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_itm {
			display: flex;
			align-items: center;
		}
		&_dsc {
			width: 100%;
			padding-left: #{1.5 / $basePcFz + 0em};
			& > * {
				&:nth-child(n+2) {
					margin-left: #{1.5 / $basePcFz + 0em};
				}
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_itm {
			display: block;
		}
		&_dsc {
			margin-top: #{1.5 / $basePcFz + 0em};
			padding: 0;
		}
	}
}

.new-grad-kv {
	margin: #{3 / $basePcFz + 0em} 0;
	& > * {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		height: #{20 / $baseMblFz + 0em};
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		height: 200 / 425 * 100vw;
		margin: 26 / 425 * 100vw 0;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		height: #{34 / $basePcFz + 0em};
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		height: 340 / 860 * 100vw;
	}
}

.ent-new-grad-lst {
	// margin-top: #{3 / $basePcFz + 0em};
	&_itm {
		padding: 2em;
		border: 1px solid $siteCol-blk01;
		border-radius: #{3 / $basePcFz + 0em};
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&_lnk {
		display: block;
		&.-line {
			width: 100%;
			max-width: #{58 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_itm {
			&:nth-child(n+2) {
				margin-top: #{2 / $baseMblFz + 0em};
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		&_itm {
			width: calc((100% - #{2 / $basePcFz + 0em}) / 2);
			&:nth-child(n+3) {
				margin-top: #{2 / $basePcFz + 0em};
			}
			&.-line {
				width: 100%;
			}
			.-has-line & {
				&:nth-child(n+2) {
					margin-top: #{2 / $basePcFz + 0em};
				}
			}
		}
		&_lnk {
			&.-line {
				width: 90%;
			}
		}
	}
}

.ent-inf-sct {
	&_ttl {
		color: $siteCol-f-wht;
		font-size: #{2.4 / $basePcFz + 0em};
		display: flex;
		background-color: $siteCol-blu03;
		&.-sngl {
			background-color: $siteCol-d-blu01;
		}
		&-txt {
			width: 100%;
			padding: 0.25em 0.5em;
			display: block;
			&.-job {
				// border-right: 1 / 2.4 + 0em solid $siteCol-f-wht;
				background-color: $siteCol-d-blu01;
			}
			&.-cat {
				width: 60%;
				max-width: 18 / 2.4 + 0em;
				margin-left: 1 / 2.4 + 0em;
				text-align: center;
				border-left: 0.4 / 2.4 + 0em solid $siteCol-f-wht;
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: #{2 / $baseMblFz + 0em};
		& + & {
			margin-top: #{4 / $baseMblFz + 0em};
		}
		&_ttl {
			font-size: #{1.8 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-top: #{6 / $basePcFz + 0em};
		& + & {
			margin-top: #{8 / $basePcFz + 0em};
		}
		&_ttl {
			font-size: #{2.4 / $basePcFz + 0em};
		}
	}
}

.ent-inf-inr-sct {
	& + & {
		margin-top: #{3 / $basePcFz + 0em};
	}
	&.-gry {
		background-color: $siteCol-wht01;
	}
	&_ttl {
		color: $siteCol-d-blu01;
		font-size: #{2 / $basePcFz + 0em};
	}
	&_fig {
		margin: 0 auto;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&.-gry {
			padding: #{2 / $baseMblFz + 0em};
		}
		&_inr {
			.-flx & {
				margin-top: #{2 / $baseMblFz + 0em};
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&.-flx {
			display: flex;
			align-items: center;
		}
		&.-gry {
			padding: #{2.5 / $basePcFz + 0em} #{4 / $basePcFz + 0em};
		}
		&_inr {
			.-flx & {
				padding-left: #{3 / $basePcFz + 0em};
			}
		}
		&_fig {
			.-flx & {
				width: 450 / 1000 * 100vw;
				max-width: #{45 / $basePcFz + 0em};
				flex-shrink: 0;
				align-self: flex-start;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&.-flx {
			display: block;
		}
		&_inr {
			.-flx & {
				margin-top: #{2 / $basePcFz + 0em};
				padding-left: 0;
			}
		}
		&_fig {
			.-flx & {
				width: 75%;
				max-width: none;
			}
		}
	}
}

.wl-lst {
	color: $siteCol-d-blu01;
	font-weight: $fwNsnsJp-bold;
	&_itm {
		display: flex;
	}
	&_trm {
		color: $siteCol-gry04;
		flex-shrink: 0;
		&::after {
			content: '：';
			padding: 0em 0.25em;
		}
	}
}

.emp-rd-lst {
	margin: 0 auto;
	display: flex;
	justify-content: center;
	&_itm {
		width: #{15 / $basePcFz + 0em};
		border-radius: 50%;
		overflow: hidden;
	}
	&_lnk {
		width: 100%;
		padding-top: 100%;
		border-radius: inherit;
		overflow: hidden;
		display: block;
		position: relative;
		& > * {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		max-width: #{50 / $baseMblFz + 0em};
		flex-wrap: wrap;
		align-items: flex-end;
		&_itm {
			width: 220 / 500 * 100%;
			margin: 0 10 / 500 * 100%;
			&:nth-child(2n+4) {
				margin-top: 20 / 500 * 100%;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_itm {
			width: #{15 / $basePcFz + 0em};
			&:nth-child(n+2) {
				margin-left: #{2 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_itm {
			&:nth-child(n+2) {
				margin-left: #{1 / $basePcFz + 0em};
			}
		}
	}
}

.ent-flw-lst {
	color: $siteCol-f-wht;
	font-weight: $fwNsnsJp-bold;
	line-height: 1.4;
	text-align: center;
	&_itm {
		padding: #{4 / $basePcFz + 0em} 0;
		background-color: $siteCol-blu03;
		&.-cntr {
			background-color: $siteCol-l-blu03;
		}
	}
	&_trm {
		color: $siteCol-blu03;
		font-size: #{3.4 / $basePcFz + 0em};
		background-color: $siteCol-f-wht;
		.-cntr & {
			color: $siteCol-l-blu03;
		}
	}
	&_dsc {
		margin-top: #{4 / $basePcFz + 0em};
		padding: 0 1em;
		& + & {
			margin-top: #{1.5 / $basePcFz + 0em};
		}
		&:nth-last-child(n+2) {
			&::after {
				content: '\e901';
				height: 0.6em;
				margin: 0 auto;
				font-size: #{8 / $basePcFz + 0em};
				@include useIcomoon;
				display: flex;
				align-items: center;
				justify-content: center;
				transform: rotate(90deg);
			}
		}
	}
	&_fig {
		&-img {
			width: 80%;
			max-width: #{25 / $basePcFz + 0em};
			margin: 0 auto;
			border-radius: 50%;
			overflow: hidden;
		}
	}
	&_cpt {
		margin-top: 0.5em;
		font-size: #{2.4 / $basePcFz + 0em};
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_itm {
			&:nth-child(n+2) {
				margin-top: #{2 / $baseMblFz + 0em};
			}
		}
		&_trm {
			font-size: #{2.4 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		&_itm {
			width: calc((100% - #{3 / $basePcFz + 0em}) / 2);
		}
	}
}

.ent-frm-atr {
	&_bdy {
		@extend %extend--inner-box-1200;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: 0 0 #{4 / $baseMblFz + 0em};
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: #{2 / $basePcFz + 0em} 0 #{8 / $basePcFz + 0em};
	}
}

.app-req {
	&_ttl {
		padding: 0.25em 0.5em;
		color: $siteCol-f-wht;
		background-color: $siteCol-d-blu01;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_ttl {
			font-size: #{1.8 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_ttl {
			font-size: #{2.4 / $basePcFz + 0em};
		}
	}
}

.app-req-lst {
	line-height: 1.5;
	border: 1px solid $siteCol-gry01;
	&_itm {
		&:nth-child(n+2) {
			border-top: 1px solid $siteCol-gry01;
		}
	}
	&_trm {
		padding: 1.5 / 1.8 + 0em 2 / 1.8 + 0em;
		font-size: #{1.8 / $basePcFz + 0em};
		line-height: 1.3;
		background-color: $siteCol-wht01;
	}
	&_dsc {
		padding: #{1.5 / $basePcFz + 0em} #{2 / $basePcFz + 0em};
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_itm {
			display: flex;
		}
		&_trm {
			width: 10em;
			flex-shrink: 0;
		}
		&_dsc {
			width: 100%;
		}
	}
}

.rls-sct {
	&_ttl {
		color: $siteCol-d-blu01;
		font-size: #{3 / $basePcFz + 0em};
	}
	&_inr {
		height: #{42 / $basePcFz + 0em};
		padding: #{2 / $basePcFz + 0em};
		border: 1px solid $siteCol-gry01;
		overflow: scroll;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		* + & {
			margin-top: #{4 / $baseMblFz + 0em};
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		* + & {
			margin-top: #{6 / $basePcFz + 0em};
		}
	}
}

.rls-ent-sct {
	&_ttl {
		font-size: #{1.8 / $basePcFz + 0em};
		* + & {
			margin-top: 2 / 1.8 + 0em;
		}
	}
	&_cpy {
		* + & {
			margin-top: #{1 / $basePcFz + 0em};
		}
	}
}

.prv-sct {
	&_ttl {
		padding-bottom: 1 / 2.6 + 0em;
		color: $siteCol-d-blu01;
		font-size: #{2.6 / $basePcFz + 0em};
		border-bottom: 2px solid currentColor;
		* + & {
			margin-top: 1em;
		}
		&.-min {
			padding-bottom: 0;
			color: $siteCol-blk01;
			font-size: #{2 / $basePcFz + 0em};
			border-bottom: 0;
		}
	}
	&_cpy {
		* + & {
			margin-top: #{1 / $basePcFz + 0em};
		}
	}
}

.prv-addr-lst {
	&_itm {
		padding: 1em;
		background-color: $siteCol-wht01;
	}
	&_dsc {
		margin-top: #{1 / $basePcFz + 0em};
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_itm {
			&:nth-child(n+2) {
				margin-top: #{1 / $baseMblFz + 0em};
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		&_itm {
			width: calc((100% - #{2 / $basePcFz + 0em}) / 2);
			&:nth-child(n+3) {
				margin-top: #{2 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		display: block;
		&_itm {
			width: 100%;
			&:nth-child(n+2) {
				margin-top: #{2 / $basePcFz + 0em};
			}
		}
	}
}

/* FAQ
   ======================================== */
.faq-unt {
	max-width: #{112 / $basePcFz + 0em};
	margin: 0 auto;
	&_ttl {
		font-weight: $fwNsnsJp-bold;
		line-height: 1.4;
		display: flex;
		align-items: baseline;
		&-txt {
			display: block;
			&.-en {
				font: {
					size: #{4 / $basePcFz + 0em};
					style: italic;
				}
				line-height: 1.1;
				transform: translate3d(0, 0.075em, 0);
			}
			&.-jp {
				margin-left: #{1.5 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		font-size: 16 / 1050 * 100vw;
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		font-size: inherit;
		display: block;
	}
}

.faq-cat-lst {
	line-height: 1.5;
	&_lnk {
		display: flex;
		align-items: center;
		&::after {
			content: '';
			width: 100%;
			height: 1px;
			min-width: #{4 / $basePcFz + 0em};
			margin-left: #{1 / $basePcFz + 0em};
			background-color: currentColor;
		}
	}
	&_txt {
		flex-shrink: 0;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: #{2 / $baseMblFz + 0em};
		&_itm {
			&:nth-child(n+2) {
				margin-top: #{1.6 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		display: flex;
		&_itm {
			&:nth-child(n+2) {
				margin-left: #{3.5 / $basePcFz + 0em};
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		margin-top: #{3 / $basePcFz + 0em};
		display: block;
		&_itm {
			&:nth-child(n+2) {
				margin: #{1.6 / $basePcFz + 0em} 0 0;
			}
		}
	}
}