@charset "UTF-8";
/* ==============================================================================================
   Base styles: opinionated defaults
   ============================================================================================== */
html {
	color: #222;
}

body {
	line-height: 1.9;
	background-color: #031f6f;
	background-image: linear-gradient(90deg, rgba(16, 80, 226, 0.9) 0%, rgba(3, 31, 111, 0.9) 100%);
}

@media screen and (max-width: 670px) {
	body {
		min-width: 320px;
		font-size: 1.4rem;
	}
}

@media screen and (min-width: 671px) {
	body {
		font-size: 1.6rem;
	}
}

a:not(.logo_lnk):not(.btn_lnk):not(.sns-acct_lnk):not(.rct-f-add-lst_lnk):not(.ent-new-grad-lst_lnk):not(.nw-lst_lnk)[target="_blank"]::after {
	content: '\e900';
	padding-left: 0.4em;
	font-family: 'icomoon';
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	speak: none;
	text-transform: none;
	display: inline-block;
	transform: translate3d(0, 0.05em, 0);
}

img, picture, source {
	pointer-events: none;
}

::-moz-selection {
	text-shadow: none;
	background-color: #b3d4fc;
}

::selection {
	text-shadow: none;
	background-color: #b3d4fc;
}

/* ==============================================================================================
   Plugins styles
   ============================================================================================== */
/* scroll hint styles
   ========================================================================== */
@keyframes scroll-hint-appear {
	0% {
		transform: translateX(40px);
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	50%,
	100% {
		transform: translateX(-40px);
		opacity: 0;
	}
}

.scroll-hint.is-right-scrollable {
	background: linear-gradient(270deg, rgba(0, 0, 0, 0.15) 0, rgba(0, 0, 0, 0) 16px, rgba(0, 0, 0, 0));
}

.scroll-hint.is-right-scrollable.is-left-scrollable {
	background: linear-gradient(90deg, rgba(0, 0, 0, 0.15) 0, rgba(0, 0, 0, 0) 16px, rgba(0, 0, 0, 0)), linear-gradient(270deg, rgba(0, 0, 0, 0.15) 0, rgba(0, 0, 0, 0) 16px, rgba(0, 0, 0, 0));
}

.scroll-hint.is-left-scrollable {
	background: linear-gradient(90deg, rgba(0, 0, 0, 0.15) 0, rgba(0, 0, 0, 0) 16px, rgba(0, 0, 0, 0));
}

.scroll-hint-icon {
	position: absolute;
	top: calc(50% - 25px);
	left: calc(50% - 60px);
	box-sizing: border-box;
	width: 120px;
	height: 90px;
	border-radius: 5px;
	transition: opacity .3s;
	opacity: 0;
	background: black;
	text-align: center;
	padding: 20px 10px 10px 10px;
}

.scroll-hint-icon-wrap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-width: 0 !important;
	max-height: 100%;
	pointer-events: none;
}

.scroll-hint-text {
	font-size: 10px;
	color: #FFF;
	margin-top: 0.6em;
}

.scroll-hint-icon-wrap.is-active .scroll-hint-icon {
	opacity: .8;
}

.scroll-hint-icon:before {
	display: inline-block;
	width: 40px;
	height: 40px;
	color: #FFF;
	vertical-align: middle;
	text-align: center;
	content: "";
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS43NyIgaGVpZ2h0PSIzMC41MiIgdmlld0JveD0iMCAwIDI1Ljc3IDMwLjUyIj48dGl0bGU+44Ki44K744OD44OIIDM8L3RpdGxlPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxwYXRoIGQ9Ik0yMS4zMywzMC41Mkg3Ljg1QTEuNTUsMS41NSwwLDAsMSw2LjMsMjlhMTIuNDYsMTIuNDYsMCwwLDAtLjYzLTQuNDIsMjUuMTYsMjUuMTYsMCwwLDAtNC4yNS01bC0uMDYtLjA2QTUsNSwwLDAsMSwwLDE1Ljg2YTMuNjQsMy42NCwwLDAsMSwxLjE3LTIuNjIsMy42MywzLjYzLDAsMCwxLDUuMTQuMDdWMy43N2EzLjc3LDMuNzcsMCwxLDEsNy41NCwwVjguMzNhMy4zNSwzLjM1LDAsMCwxLDEuMjYsMCwzLDMsMCwwLDEsMiwxLjIyLDMuNSwzLjUsMCwwLDEsMi0uMDYsMy4yMSwzLjIxLDAsMCwxLDIsMS41NCwzLjc0LDMuNzQsMCwwLDEsMywuNDdBNC4yMSw0LjIxLDAsMCwxLDI1Ljc0LDE1YzAsLjExLDAsLjI3LDAsLjQ2YTE5LjI2LDE5LjI2LDAsMCwxLS44NCw3Yy0uMTQuMzgtLjM2LjgxLS41NiwxLjIybC0uMTEuMjJjMCwuMDctLjA5LjE0LS4xNC4yMWE3LjEzLDcuMTMsMCwwLDAtMS4xNywyLjE3Yy0uMDYuNTYtLjA2LDIuMTUtLjA1LDIuNzFBMS41NSwxLjU1LDAsMCwxLDIxLjMzLDMwLjUyWk04LjYxLDI4LjIxaDEyYzAtLjcxLDAtMS43MS4wNy0yLjIzYTguNzQsOC43NCwwLDAsMSwxLjU5LTMuMjVsLjA2LS4xMmExMCwxMCwwLDAsMCwuNDYtMSwxNi44LDE2LjgsMCwwLDAsLjctNi4xMmMwLS4yMywwLS40MSwwLS41NGgwYTIsMiwwLDAsMC0uNjQtMS41MiwxLjMzLDEuMzMsMCwwLDAtMS41NS4wOCwxLjEzLDEuMTMsMCwwLDEtMS4xOC4yOCwxLjE1LDEuMTUsMCwwLDEtLjc4LS45NCwxLjI2LDEuMjYsMCwwLDAtLjc1LTEuMTEsMSwxLDAsMCwwLTEuMTEuMjhsLS4xLjFhMS4xNSwxLjE1LDAsMCwxLTEuMTkuMjksMS4xNiwxLjE2LDAsMCwxLS43OC0uOTVjLS4wOS0uNjgtLjIxLS43Ny0uNy0uODdhLjgyLjgyLDAsMCwwLTEsLjQ4LDEuMTYsMS4xNiwwLDAsMS0yLjE2LS41OFYzLjc3YTEuNDYsMS40NiwwLDEsMC0yLjkyLDB2Ny44NWwwLDQuMzNhMS4xNywxLjE3LDAsMCwxLS44MywxLjExLDEuMTUsMS4xNSwwLDAsMS0xLjItLjM1bC0xLS45MWMtLjQ3LS40Mi0uNzMtLjY2LS44NC0uNzdhMS4zNSwxLjM1LDAsMCwwLTItLjEyTDIuNywxNWExLjMyLDEuMzIsMCwwLDAtLjM5LDFBMi41NywyLjU3LDAsMCwwLDMsMTcuODVsMCwwYTI3LjI0LDI3LjI0LDAsMCwxLDQuNyw1LjYyQTEyLjYzLDEyLjYzLDAsMCwxLDguNjEsMjguMjFaTTIzLjIsMjMuMzVaTTYuNTEsMTYuNTlaIiBmaWxsPSIjZmZmIi8+PC9nPjwvZz48L3N2Zz4=);
}

.scroll-hint-icon:after {
	content: "";
	width: 34px;
	height: 14px;
	display: block;
	position: absolute;
	top: 10px;
	left: 50%;
	margin-left: -20px;
	background-repeat: no-repeat;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMy4yOSIgaGVpZ2h0PSIxMi4wMiIgdmlld0JveD0iMCAwIDMzLjI5IDEyLjAyIj48dGl0bGU+44Ki44K744OD44OIIDE8L3RpdGxlPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxsaW5lIHgxPSIxLjg1IiB5MT0iNi4wMSIgeDI9IjEwLjQiIHkyPSI2LjAxIiBmaWxsPSIjZmZmIi8+PHBhdGggZD0iTTEwLjQsNy4xN0gxLjg1YTEuMTYsMS4xNiwwLDEsMSwwLTIuMzFIMTAuNGExLjE2LDEuMTYsMCwxLDEsMCwyLjMxWiIgZmlsbD0iI2ZmZiIvPjxwYXRoIGQ9Ik03LjQsMTJhMS4xNSwxLjE1LDAsMCwxLS43Mi0uMjVsLTYuMjUtNUExLjIsMS4yLDAsMCwxLDAsNS44NywxLjE0LDEuMTQsMCwwLDEsLjQ2LDVMNi43LjIzQTEuMTYsMS4xNiwwLDAsMSw4LjEsMi4wOEwzLDUuOTEsOC4xMiwxMEExLjE2LDEuMTYsMCwwLDEsNy40LDEyWiIgZmlsbD0iI2ZmZiIvPjxsaW5lIHgxPSIzMS40NSIgeTE9IjYuMDEiIHgyPSIyMi44OSIgeTI9IjYuMDEiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNMzEuNDUsNy4xN0gyMi44OWExLjE2LDEuMTYsMCwxLDEsMC0yLjMxaDguNTZhMS4xNiwxLjE2LDAsMCwxLDAsMi4zMVoiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNMjUuOSwxMmExLjE4LDEuMTgsMCwwLDEtLjkxLS40M0ExLjE3LDEuMTcsMCwwLDEsMjUuMTcsMTBsNS4wOS00LjA1TDI1LjIsMi4wOEExLjE2LDEuMTYsMCwwLDEsMjYuNTkuMjNMMzIuODQsNWExLjE2LDEuMTYsMCwwLDEsLjQ1LjkxLDEuMTQsMS4xNCwwLDAsMS0uNDMuOTJsLTYuMjQsNUExLjE3LDEuMTcsMCwwLDEsMjUuOSwxMloiIGZpbGw9IiNmZmYiLz48L2c+PC9nPjwvc3ZnPg==);
	opacity: 0;
	transition-delay: 2.4s;
}

.scroll-hint-icon-wrap.is-active .scroll-hint-icon:after {
	opacity: 1;
}

.scroll-hint-icon-wrap.is-active .scroll-hint-icon:before {
	animation: scroll-hint-appear 1.2s linear;
	animation-iteration-count: 2;
}

.scroll-hint-icon-white {
	background-color: #FFF;
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.4);
}

.scroll-hint-icon-white:before {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS43NyIgaGVpZ2h0PSIzMC41MiIgdmlld0JveD0iMCAwIDI1Ljc3IDMwLjUyIj48dGl0bGU+44Ki44K744OD44OIIDQ8L3RpdGxlPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxwYXRoIGQ9Ik0yMS4zMywzMC41Mkg3Ljg1QTEuNTUsMS41NSwwLDAsMSw2LjMsMjlhMTIuNDYsMTIuNDYsMCwwLDAtLjYzLTQuNDIsMjUuMTYsMjUuMTYsMCwwLDAtNC4yNS01bC0uMDYtLjA2QTUsNSwwLDAsMSwwLDE1Ljg2YTMuNjQsMy42NCwwLDAsMSwxLjE3LTIuNjIsMy42MywzLjYzLDAsMCwxLDUuMTQuMDdWMy43N2EzLjc3LDMuNzcsMCwxLDEsNy41NCwwVjguMzNhMy4zNSwzLjM1LDAsMCwxLDEuMjYsMCwzLDMsMCwwLDEsMiwxLjIyLDMuNSwzLjUsMCwwLDEsMi0uMDYsMy4yMSwzLjIxLDAsMCwxLDIsMS41NCwzLjc0LDMuNzQsMCwwLDEsMywuNDdBNC4yMSw0LjIxLDAsMCwxLDI1Ljc0LDE1YzAsLjExLDAsLjI3LDAsLjQ2YTE5LjI2LDE5LjI2LDAsMCwxLS44NCw3Yy0uMTQuMzgtLjM2LjgxLS41NiwxLjIybC0uMTEuMjJjMCwuMDctLjA5LjE0LS4xNC4yMWE3LjEzLDcuMTMsMCwwLDAtMS4xNywyLjE3Yy0uMDYuNTYtLjA2LDIuMTUtLjA1LDIuNzFBMS41NSwxLjU1LDAsMCwxLDIxLjMzLDMwLjUyWk04LjYxLDI4LjIxaDEyYzAtLjcxLDAtMS43MS4wNy0yLjIzYTguNzQsOC43NCwwLDAsMSwxLjU5LTMuMjVsLjA2LS4xMmExMCwxMCwwLDAsMCwuNDYtMSwxNi44LDE2LjgsMCwwLDAsLjctNi4xMmMwLS4yMywwLS40MSwwLS41NGgwYTIsMiwwLDAsMC0uNjQtMS41MiwxLjMzLDEuMzMsMCwwLDAtMS41NS4wOCwxLjEzLDEuMTMsMCwwLDEtMS4xOC4yOCwxLjE1LDEuMTUsMCwwLDEtLjc4LS45NCwxLjI2LDEuMjYsMCwwLDAtLjc1LTEuMTEsMSwxLDAsMCwwLTEuMTEuMjhsLS4xLjFhMS4xNSwxLjE1LDAsMCwxLTEuMTkuMjksMS4xNiwxLjE2LDAsMCwxLS43OC0uOTVjLS4wOS0uNjgtLjIxLS43Ny0uNy0uODdhLjgyLjgyLDAsMCwwLTEsLjQ4LDEuMTYsMS4xNiwwLDAsMS0yLjE2LS41OFYzLjc3YTEuNDYsMS40NiwwLDEsMC0yLjkyLDB2Ny44NWwwLDQuMzNhMS4xNywxLjE3LDAsMCwxLS44MywxLjExLDEuMTUsMS4xNSwwLDAsMS0xLjItLjM1bC0xLS45MWMtLjQ3LS40Mi0uNzMtLjY2LS44NC0uNzdhMS4zNSwxLjM1LDAsMCwwLTItLjEyTDIuNywxNWExLjMyLDEuMzIsMCwwLDAtLjM5LDFBMi41NywyLjU3LDAsMCwwLDMsMTcuODVsMCwwYTI3LjI0LDI3LjI0LDAsMCwxLDQuNyw1LjYyQTEyLjYzLDEyLjYzLDAsMCwxLDguNjEsMjguMjFaTTIzLjIsMjMuMzVaTTYuNTEsMTYuNTlaIi8+PC9nPjwvZz48L3N2Zz4=);
}

.scroll-hint-icon-white:after {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMy4yOSIgaGVpZ2h0PSIxMi4wMiIgdmlld0JveD0iMCAwIDMzLjI5IDEyLjAyIj48dGl0bGU+44Ki44K744OD44OIIDI8L3RpdGxlPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxsaW5lIHgxPSIxLjg1IiB5MT0iNi4wMSIgeDI9IjEwLjQiIHkyPSI2LjAxIi8+PHBhdGggZD0iTTEwLjQsNy4xN0gxLjg1YTEuMTYsMS4xNiwwLDEsMSwwLTIuMzFIMTAuNGExLjE2LDEuMTYsMCwxLDEsMCwyLjMxWiIvPjxwYXRoIGQ9Ik03LjQsMTJhMS4xNSwxLjE1LDAsMCwxLS43Mi0uMjVsLTYuMjUtNUExLjIsMS4yLDAsMCwxLDAsNS44NywxLjE0LDEuMTQsMCwwLDEsLjQ2LDVMNi43LjIzQTEuMTYsMS4xNiwwLDAsMSw4LjEsMi4wOEwzLDUuOTEsOC4xMiwxMEExLjE2LDEuMTYsMCwwLDEsNy40LDEyWiIvPjxsaW5lIHgxPSIzMS40NSIgeTE9IjYuMDEiIHgyPSIyMi44OSIgeTI9IjYuMDEiLz48cGF0aCBkPSJNMzEuNDUsNy4xN0gyMi44OWExLjE2LDEuMTYsMCwxLDEsMC0yLjMxaDguNTZhMS4xNiwxLjE2LDAsMCwxLDAsMi4zMVoiLz48cGF0aCBkPSJNMjUuOSwxMmExLjE4LDEuMTgsMCwwLDEtLjkxLS40M0ExLjE3LDEuMTcsMCwwLDEsMjUuMTcsMTBsNS4wOS00LjA1TDI1LjIsMi4wOEExLjE2LDEuMTYsMCwwLDEsMjYuNTkuMjNMMzIuODQsNWExLjE2LDEuMTYsMCwwLDEsLjQ1LjkxLDEuMTQsMS4xNCwwLDAsMS0uNDMuOTJsLTYuMjQsNUExLjE3LDEuMTcsMCwwLDEsMjUuOSwxMloiLz48L2c+PC9nPjwvc3ZnPg==);
}

.scroll-hint-icon-white .scroll-hint-text {
	color: #000;
}

/* ==============================================================================================
   Author's custom styles
   ============================================================================================== */
/* Extend styles
   ========================================================================== */
.top-msg-mov-sct_bdy {
	max-width: 1120px;
	margin: 0 auto;
}

.mn-cnt_bdy, .sgk-abt-art_bdy, .msg-t-art_bdy, .abt-art_bdy, .top-msg-hdr_bdy, .top-msg-sct_bdy, .mbr-art_bdy, .emp-hdr_bdy, .emp-art_inr, .emp-msg-sct_bdy, .emp-tbl-fig_bdy, .int-art_bdy, .int-oth-sct_bdy, .dt-rv-art_bdy, .sgk-dt-art_inr, .sgk-dt-sct_bdy, .ofc-atr_bdy, .sgk-biz-art_bdy, .sgk-biz-art_hdr-bdy, .hst-art_bdy, .sdgs-art_bdy, .ysdgs-sct_bdy, .nw-art_bdy, .rct-cnt-art_bdy, .ent-atr_bdy, .ent-lnk-unt_bdy, .ent-frm-atr_bdy, .mn-hdr_bdy, .mn-ftr_inr {
	max-width: 1320px;
	margin: 0 auto;
}

@media screen and (max-width: 670px) {
	.top-msg-mov-sct_bdy, .mn-cnt_bdy, .sgk-abt-art_bdy, .msg-t-art_bdy, .abt-art_bdy, .top-msg-hdr_bdy, .top-msg-sct_bdy, .mbr-art_bdy, .emp-hdr_bdy, .emp-art_inr, .emp-msg-sct_bdy, .emp-tbl-fig_bdy, .int-art_bdy, .int-oth-sct_bdy, .dt-rv-art_bdy, .sgk-dt-art_inr, .sgk-dt-sct_bdy, .ofc-atr_bdy, .sgk-biz-art_bdy, .sgk-biz-art_hdr-bdy, .hst-art_bdy, .sdgs-art_bdy, .ysdgs-sct_bdy, .nw-art_bdy, .rct-cnt-art_bdy, .ent-atr_bdy, .ent-lnk-unt_bdy, .ent-frm-atr_bdy, .mn-hdr_bdy, .mn-ftr_inr {
		padding-right: 20px;
		padding-left: 20px;
	}
}

@media screen and (min-width: 671px) {
	.top-msg-mov-sct_bdy, .mn-cnt_bdy, .sgk-abt-art_bdy, .msg-t-art_bdy, .abt-art_bdy, .top-msg-hdr_bdy, .top-msg-sct_bdy, .mbr-art_bdy, .emp-hdr_bdy, .emp-art_inr, .emp-msg-sct_bdy, .emp-tbl-fig_bdy, .int-art_bdy, .int-oth-sct_bdy, .dt-rv-art_bdy, .sgk-dt-art_inr, .sgk-dt-sct_bdy, .ofc-atr_bdy, .sgk-biz-art_bdy, .sgk-biz-art_hdr-bdy, .hst-art_bdy, .sdgs-art_bdy, .ysdgs-sct_bdy, .nw-art_bdy, .rct-cnt-art_bdy, .ent-atr_bdy, .ent-lnk-unt_bdy, .ent-frm-atr_bdy, .mn-hdr_bdy, .mn-ftr_inr {
		padding-right: 60px;
		padding-left: 60px;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.top-msg-mov-sct_bdy, .mn-cnt_bdy, .sgk-abt-art_bdy, .msg-t-art_bdy, .abt-art_bdy, .top-msg-hdr_bdy, .top-msg-sct_bdy, .mbr-art_bdy, .emp-hdr_bdy, .emp-art_inr, .emp-msg-sct_bdy, .emp-tbl-fig_bdy, .int-art_bdy, .int-oth-sct_bdy, .dt-rv-art_bdy, .sgk-dt-art_inr, .sgk-dt-sct_bdy, .ofc-atr_bdy, .sgk-biz-art_bdy, .sgk-biz-art_hdr-bdy, .hst-art_bdy, .sdgs-art_bdy, .ysdgs-sct_bdy, .nw-art_bdy, .rct-cnt-art_bdy, .ent-atr_bdy, .ent-lnk-unt_bdy, .ent-frm-atr_bdy, .mn-hdr_bdy, .mn-ftr_inr {
		padding-right: 40px;
		padding-left: 40px;
	}
}

/* Window entrance styles
   ========================================================================== */
.ldr-win {
	width: 100vw;
	height: 100vh;
	color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9000;
	transform: translate3d(0, 0, 0);
}

.ldr-win:after {
	content: '';
	width: 100%;
	height: 100%;
	background-color: #fff;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	transform: scale(0, 1);
	transform-origin: 0 0;
}

.is-load .ldr-win:after {
	animation: xBndApprFx 0.6s 0.4s cubic-bezier(0.83, 0, 0.17, 1) both;
}

.is-load .ldr-win {
	opacity: 0;
	visibility: hidden;
	transition: opacity 250ms 1.1s cubic-bezier(0.22, 1, 0.36, 1), visibility 250ms 1.1s cubic-bezier(0.22, 1, 0.36, 1);
}

.ldr-win_bdy {
	width: 100%;
	height: 100%;
	background-color: #031f6f;
	background-image: linear-gradient(90deg, rgba(16, 80, 226, 0.9) 0%, rgba(3, 31, 111, 0.9) 100%);
	position: relative;
}

.ldr-win_bdy:before {
	content: '';
	width: 100%;
	height: 100%;
	background-image: url(../img/img_bg_noise.png);
	background-position: center;
	background-size: 31.25em 31.25em;
	mix-blend-mode: soft-light;
	opacity: 0.3;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
}

.is-load .ldr-win_bdy {
	animation: xBndItmApprFx 0.6s 0.4s cubic-bezier(0.83, 0, 0.17, 1) both reverse;
}

.loader {
	line-height: 1;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	backface-visibility: hidden;
	transform: translate3d(-50%, -50%, 0);
}

.is-load .loader {
	opacity: 0;
	transition: opacity 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.loader_txt {
	width: 4.4em;
	margin: 1.2em auto 0;
	font-size: 0.75em;
	position: relative;
}

.loader_txt-rdr {
	display: block;
	overflow: hidden;
	position: absolute;
	bottom: 0;
	left: 100%;
	animation: reader-stp 1s 1s steps(2) infinite;
}

@keyframes reader-stp {
	to {
		height: 0;
	}
}

.orbit-spinner {
	width: 3.4375em;
	height: 3.4375em;
	border-radius: 50%;
	perspective: 50em;
}

.orbit-spinner_line {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	position: absolute;
}

.orbit-spinner_line:first-child {
	border-bottom: 0.1875em solid currentColor;
	top: 0;
	left: 0;
	animation: orbit-spinner-fx-1 1.25s linear infinite;
}

.orbit-spinner_line:nth-child(2) {
	border-right: 0.1875em solid currentColor;
	top: 0;
	right: 0;
	animation: orbit-spinner-fx-2 1.25s linear infinite;
}

.orbit-spinner_line:nth-child(3) {
	border-top: 0.1875em solid currentColor;
	right: 0;
	bottom: 0;
	animation: orbit-spinner-fx-3 1.25s linear infinite;
}

@keyframes orbit-spinner-fx-1 {
	0% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}

@keyframes orbit-spinner-fx-2 {
	0% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}

@keyframes orbit-spinner-fx-3 {
	0% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}

/* General styles
   ========================================================================== */
.cnt-bdy {
	overflow: hidden;
	position: relative;
}

.nts {
	color: #777;
	font-size: 0.8125em;
	font-weight: 700;
	line-height: 1.5;
}

.nts[data-nts-styl] {
	display: flex;
	align-items: baseline;
}

.nts[data-nts-styl]::before {
	content: attr(data-nts-styl);
	flex-shrink: 0;
}

.nts.-rght, .nts.-rght[data-nts-styl], .nts.utl-tar, .nts.utl-tar[data-nts-styl] {
	text-align: right;
	display: block;
}

.nts.-ctr, .nts.-ctr[data-nts-styl], .nts.utl-tac, .nts.utl-tac[data-nts-styl] {
	text-align: center;
	display: block;
}

.nts.-inl, .nts.-inl[data-nts-styl], .nts.utl-di, .nts.utl-di[data-nts-styl] {
	display: inline;
}

.nts-lst {
	color: #777;
	font-size: 0.8125em;
	font-weight: 700;
	line-height: 1.5;
}

.nts-lst_itm[data-nts-styl] {
	display: flex;
	align-items: baseline;
}

.nts-lst_itm[data-nts-styl]::before {
	content: attr(data-nts-styl);
	flex-shrink: 0;
}

.nts-lst_itm.-rght, .nts-lst_itm.-rght[data-nts-styl], .nts-lst_itm.utl-tar, .nts-lst_itm.utl-tar[data-nts-styl] {
	text-align: right;
	display: block;
}

.nts-lst_itm.-ctr, .nts-lst_itm.-ctr[data-nts-styl], .nts-lst_itm.utl-tac, .nts-lst_itm.utl-tac[data-nts-styl] {
	text-align: center;
	display: block;
}

.blt-lst.-crr {
	text-align: left;
}

.blt-lst_itm[data-blt-styl] {
	display: flex;
	align-items: baseline;
}

.-crr .blt-lst_itm[data-blt-styl] {
	padding-right: 0.25em;
	display: inline-flex;
}

.blt-lst_itm[data-blt-styl]::before {
	content: attr(data-blt-styl);
	padding-right: 0.25em;
	flex-shrink: 0;
}

.-crr .blt-lst_itm[data-blt-styl]::before {
	color: #7a87a3;
}

.blt-lst_itm.-rght, .blt-lst_itm.-rght[data-nts-styl], .blt-lst_itm.utl-tar, .blt-lst_itm.utl-tar[data-nts-styl] {
	text-align: right;
	display: block;
}

.blt-lst_itm.-ctr, .blt-lst_itm.-ctr[data-nts-styl], .blt-lst_itm.utl-tac, .blt-lst_itm.utl-tac[data-nts-styl] {
	text-align: center;
	display: block;
}

.ord-lst {
	counter-reset: lst-idx;
}

.ord-lst_itm {
	display: flex;
	align-items: baseline;
	counter-increment: lst-idx;
}

.ord-lst_itm::before {
	content: counter(lst-idx) ".";
	padding-right: 0.25em;
	flex-shrink: 0;
}

.ord-lst_itm.-rght, .ord-lst_itm.utl-tar {
	text-align: right;
	display: block;
}

.ord-lst_itm.-ctr, .ord-lst_itm.utl-tac {
	text-align: center;
	display: block;
}

.sns-lst {
	line-height: 0;
	display: flex;
	align-items: center;
}

.sns-lst_itm:nth-child(n+2) {
	margin-left: 0.9375em;
}

.sns-lst_lnk {
	width: 1.25em;
	display: block;
}

.sns-acct {
	margin-top: 15px;
	font-style: italic;
	font-size: 1.4rem;
	font-weight: 700;
	line-height: 1.1;
}

.sns-acct + .sns-acct {
	margin-top: 0.5em;
}

.sns-acct_lnk {
	display: flex;
	align-items: center;
}

.sns-acct_icn {
	width: 30px;
	height: 30px;
	margin-right: 15px;
	line-height: 0;
	border-radius: 100em;
	overflow: hidden;
	display: block;
	flex-shrink: 0;
	position: relative;
}

.sns-acct_icn > * {
	position: relative;
	z-index: 10;
}

.-ig .sns-acct_icn {
	background-image: linear-gradient(135deg, #427eff 0%, #f13f79 70%);
}

.-ig .sns-acct_icn::after {
	content: '';
	width: 160%;
	height: 160%;
	background-image: radial-gradient(rgba(255, 219, 44, 0.8) 10%, rgba(255, 105, 34, 0.65) 55%, rgba(255, 88, 96, 0.1) 70%);
	display: block;
	position: absolute;
	top: 30%;
	left: -44%;
	z-index: 0;
}

.-ig-sld .sns-acct_icn {
	border-radius: 0;
}

.flx-box {
	display: flex;
}

@media screen and (max-width: 670px) {
	.flx-box.-mbl-db {
		display: block;
	}
	.flx-box.-after-mbr-lst > *:nth-child(2) {
		margin-top: 1.875em;
	}
}

@media screen and (min-width: 671px) {
	.flx-box.-after-mbr-lst {
		align-items: center;
	}
}

/* Icon styles
   ========================================================================== */
@font-face {
	src: url("../fonts/icomoon.ttf?fmumff") format("truetype"), url("../fonts/icomoon.woff?fmumff") format("woff"), url("../fonts/icomoon.svg?fmumff#icomoon") format("svg");
	font-family: 'icomoon';
	font-style: normal;
	font-weight: normal;
	font-display: block;
}

.icn {
	font-family: 'icomoon';
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	speak: none;
	text-transform: none;
}

.icn.-blnk::before {
	content: '\e900';
}

.icn.-arw::before {
	content: '\e901';
}

.icn.-crs::before {
	content: '\e902';
}

.icn.-lst::before {
	content: '\e903';
}

/* Logo styles
   ========================================================================== */
.logo {
	width: 100%;
	line-height: 1;
	display: block;
}

.logo.-sgk-rct.-wht, .logo.-sgk-rct-vrt.-wht {
	filter: brightness(0%) invert(100%);
}

.logo.-sgk-rct.-blk, .logo.-sgk-rct-vrt.-blk {
	filter: brightness(0%) invert(0%);
}

.logo.-sgk-rct {
	max-width: 16.875em;
	color: #000;
	font-weight: 700;
}

.logo.-sgk-rct-vrt {
	max-width: 14.5em;
}

.hst-bynd .logo.-sgk-rct-vrt {
	max-width: 11.875em;
}

.-group .logo.-sgk-rct-vrt {
	margin-bottom: 3.125em;
}

.logo.-ytb {
	max-width: 10em;
}

.logo_lnk, .logo_txt, .logo_inr, .logo_wrp {
	width: 100%;
	height: 100%;
	display: block;
}

.-sgk-rct .logo_lnk {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.-sgk-rct .logo_txt.-logo {
	max-width: calc(100% - 8.125em);
}

.-sgk-rct .logo_txt.-txt {
	width: auto;
	height: auto;
	font-size: 0.875em;
	font-style: italic;
	transform: translate3d(0, 0.05em, 0);
}

@media screen and (max-width: 374px) {
	.logo.-sgk-rct.-nav {
		font-size: 3.73333vw;
	}
	.logo.-sgk-rct.-nav-mdl {
		font-size: 0.92857em;
	}
}

/* Button styles
   ========================================================================== */
.btn {
	width: 100%;
	margin: 0 auto;
	color: #fff;
	font-weight: 700;
	line-height: 1.5;
	text-align: center;
	background-color: #000;
	display: block;
}

.btn.-nws-unt {
	width: 3.25em;
	height: 3.25em;
	color: #1050e2;
	line-height: 1;
	border-radius: 50%;
	background-color: rgba(16, 80, 226, 0);
	overflow: hidden;
	position: absolute;
	top: 50%;
	right: 0.5em;
	transform: translate3d(0, -50%, 0);
}

.is-load .btn.-nws-unt {
	transition: color 450ms cubic-bezier(0.22, 1, 0.36, 1), background-color 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.ua-mbl .btn.-nws-unt:active {
	color: #fff;
	background-color: #1050e2;
}

body:not(.ua-mbl) .btn.-nws-unt:hover {
	color: #fff;
	background-color: #1050e2;
}

.btn.-ent {
	line-height: 1.3;
}

.btn.-ent.-blu {
	background-color: #1caef7;
}

.btn.-ent.-grn {
	background-color: #1fd3ac;
}

.btn.-ent.-yel {
	background-color: #f2b724;
}

.btn.-more {
	max-width: 8.75em;
	margin: 0;
	color: #1050e2;
	font-style: italic;
	background-color: transparent;
}

.btn.-more.-wht {
	color: #fff;
}

.btn.-more.-absl {
	width: 100%;
	height: 100%;
	max-width: none;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 50;
}

.btn.-sqr {
	color: #031f6f;
	border: 2px solid #031f6f;
	background-color: rgba(3, 31, 111, 0);
}

.is-load .btn.-sqr {
	transition: color 450ms cubic-bezier(0.22, 1, 0.36, 1), background-color 450ms cubic-bezier(0.22, 1, 0.36, 1), opacity 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.btn.-sqr.-blu {
	color: #fff;
	background-color: #031f6f;
}

.btn.-sqr.-wht {
	color: #fff;
	border-color: #fff;
	background-color: rgba(255, 255, 255, 0);
}

.btn.-sqr.-wht.is-actv {
	color: #1050e2;
	background-color: white;
}

.ua-mbl .btn.-sqr.-wht:active {
	color: #1050e2;
	background-color: white;
}

body:not(.ua-mbl) .btn.-sqr.-wht:hover {
	color: #1050e2;
	background-color: white;
}

.btn.-sqr.is-actv {
	color: #fff;
	background-color: #031f6f;
}

.ua-mbl .btn.-sqr:active {
	color: #fff;
	background-color: #031f6f;
}

body:not(.ua-mbl) .btn.-sqr:hover {
	color: #fff;
	background-color: #031f6f;
}

.btn.-rnd-sqr {
	max-width: 12.5em;
	color: #fff;
	border-radius: 0.625em;
	background-color: #031f6f;
	overflow: hidden;
}

.btn.-rnd-sqr.-sld {
	color: #031f6f;
	border: 1px solid currentColor;
	background-color: transparent;
}

.-ft .btn.-rnd-sqr.-sld {
	color: #0f75be;
}

.is-load .btn.-rnd-sqr.-sld {
	transition: background-color 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.is-load .btn.-rnd-sqr.-sld .btn_lnk {
	transition: color 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.ua-mbl .btn.-rnd-sqr.-sld:active {
	background-color: currentColor;
}

.ua-mbl .btn.-rnd-sqr.-sld:active .btn_txt {
	color: #fff;
}

body:not(.ua-mbl) .btn.-rnd-sqr.-sld:hover {
	background-color: currentColor;
}

body:not(.ua-mbl) .btn.-rnd-sqr.-sld:hover .btn_lnk {
	color: #fff;
}

.btn.-d-sqr {
	max-width: 37.5em;
	background-color: #1050e2;
}

.btn.-tr-tbl {
	height: 3.75em;
	margin: 0;
	text-align: justify;
	background-color: #657bb5;
}

.btn.-tr-tbl.-lrg {
	height: 5em;
}

.btn.-tr-tbl.-vrl {
	width: 3.75em;
	height: 21.25em;
	writing-mode: vertical-rl;
	flex-shrink: 0;
}

.btn.-tr-tbl.-vrl.-lrg {
	width: 5em;
}

.btn.-tr-tbl.-col-blu02 {
	background-color: #5c7faf;
}

.btn.-tr-tbl.-col-blu03 {
	background-color: #234e89;
}

.btn_lnk, .btn_inr, .btn_wrp {
	width: 100%;
	height: 100%;
	padding: 1em;
	color: currentColor;
	display: flex;
	align-items: center;
	justify-content: center;
}

.-nws-unt .btn_lnk {
	padding: 0;
}

.-nws-unt .btn_lnk::before {
	content: '\e903';
	font-size: 1.625em;
	font-family: 'icomoon';
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	speak: none;
	text-transform: none;
	transform: translate3d(-0.02em, 0, 0);
}

.-ent .btn_lnk {
	flex-direction: column;
	position: relative;
}

.-ent.-min .btn_lnk {
	min-height: 2.375em;
	padding: 0.25em 0.5em;
}

.-ent.-min .btn_lnk::after {
	content: '';
	width: calc(50% - 2.75em);
	height: 1px;
	background-color: currentColor;
	display: block;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate3d(0, -50%, 0);
}

.fxd-ftr .-ent.-min .btn_lnk::after {
	display: none;
}

.-ent.-lrg .btn_lnk {
	padding: 0.625em;
}

.-more .btn_lnk {
	padding: 0;
}

.emp-lst_itm .-more .btn_lnk::after {
	content: '';
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.-sqr .btn_lnk {
	padding: 1em 0.5em;
}

.ssgl-lst .-sqr .btn_lnk {
	padding: 1em 1.5em;
}

.-rnd-sqr .btn_lnk {
	padding: 0.5em 0.5em 0.5em 1em;
	justify-content: space-between;
}

.-rnd-sqr .btn_lnk::after {
	content: '▶';
	display: block;
	transform: translate3d(0, 0.05em, 0);
}

.-d-sqr .btn_lnk {
	min-height: 9.375em;
	padding: 1.625em;
	flex-direction: column;
	position: relative;
}

.-d-sqr .btn_lnk::after {
	content: '';
	width: calc(100% - 0.625em * 2);
	height: calc(100% - 0.625em * 2);
	border: 1px solid currentColor;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
}

.-tr-tbl .btn_lnk {
	padding: 0.5em 1em;
	justify-content: space-between;
}

.-tr-tbl .btn_lnk::after {
	content: '\e901';
	font-size: 2.5em;
	font-family: 'icomoon';
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	speak: none;
	text-transform: none;
	display: block;
	transform: rotate(90deg);
}

.-more .btn_inr {
	padding: 0;
	justify-content: flex-start;
}

.-more .btn_inr::after {
	content: '';
	width: 100%;
	height: 1px;
	margin-left: 0.625em;
	background-color: currentColor;
	display: block;
}

.is-load .-more .btn_inr {
	transition: filter 450ms cubic-bezier(0.22, 1, 0.36, 1), transform 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.-more.-lrg .btn_inr::after {
	height: 2px;
}

.-more.-absl .btn_inr {
	height: auto;
	max-width: 8.75em;
	position: absolute;
	right: 0;
	bottom: 1.25em;
	z-index: 10;
}

.int-inr-oth-sct .-more.-absl .btn_inr::before {
	content: '';
	width: calc(100% + 3em);
	height: 110%;
	background-color: #fff;
	display: block;
	position: absolute;
	top: 50%;
	right: -2em;
	z-index: -1;
	transform: translate3d(0, -50%, 0) skew(-16deg);
}

.ua-mbl .-more:active .btn_inr {
	filter: brightness(125%);
	transform: translate3d(0.25em, 0, 0);
}

.ua-mbl .-more:active .btn_inr::after {
	animation: more-btn-act 540ms cubic-bezier(0.83, 0, 0.17, 1);
}

body:not(.ua-mbl) .-more:hover .btn_inr {
	filter: brightness(125%);
	transform: translate3d(0.25em, 0, 0);
}

body:not(.ua-mbl) .-more:hover .btn_inr::after {
	animation: more-btn-act 540ms cubic-bezier(0.83, 0, 0.17, 1);
}

.btn_txt {
	display: block;
}

.-ent .btn_txt.-en {
	font-style: italic;
}

.-ent.-min .btn_txt {
	font-size: 0.6875em;
}

.-ent.-min .btn_txt.-en {
	transform: translate3d(-0.12em, 0, 0);
}

.fxd-ftr .-ent.-min .btn_txt {
	font-size: inherit;
}

.-ent.-lrg .btn_txt {
	display: flex;
	align-items: center;
}

.-ent.-lrg .btn_txt.-jp {
	width: 100%;
	height: 100%;
	padding: 0.5em;
	font-size: 1.875em;
	border: 1px solid currentColor;
	justify-content: center;
}

.-nav-mdl .-ent.-lrg .btn_txt.-jp {
	font-size: 1.5em;
}

.-ent.-lrg .btn_txt.-en {
	font-size: 0.875em;
	position: absolute;
	right: 0.78571em;
	bottom: calc(4% + 0.78571em);
}

.-ent.-lrg .btn_txt.-en::after {
	content: '';
	width: 2.85714em;
	height: 1px;
	margin-left: 0.42857em;
	background-color: currentColor;
	display: block;
}

.-more .btn_txt {
	flex-shrink: 0;
}

.-more.-lrg .btn_txt {
	font-size: 1.25em;
}

.-d-sqr .btn_txt {
	display: flex;
	align-items: center;
}

.-d-sqr .btn_txt.-lrg {
	font-size: 1.875em;
	font-style: italic;
	line-height: 1.1;
}

.-d-sqr .btn_txt.-min {
	margin-top: 0.5em;
	font-size: 1.25em;
}

.-d-sqr .btn_txt.-en {
	font-size: 0.875em;
	font-style: italic;
	position: absolute;
	right: 0.78571em;
	bottom: calc(4% + 0.78571em);
}

.-d-sqr .btn_txt.-en::after {
	content: '';
	width: 2.85714em;
	height: 1px;
	margin-left: 0.42857em;
	background-color: currentColor;
	display: block;
}

.-tr-tbl .btn_txt {
	font-size: 1.25em;
}

.-tr-tbl.-vrl .btn_txt {
	margin: auto 0;
}

@media screen and (max-width: 670px) {
	.btn.-d-sqr {
		margin-top: 2.85714em;
	}
	.-ent.-lrg .btn_txt.-jp {
		min-height: 5.03704em;
	}
	.-nav-mdl .-ent.-lrg .btn_txt.-jp {
		min-height: 5.55556em;
	}
}

@media screen and (max-width: 425px) {
	.btn.-d-sqr {
		font-size: 3.29412vw;
	}
}

@media screen and (min-width: 671px) {
	.btn.-d-sqr {
		margin-top: 5em;
	}
	.-ent.-lrg .btn_txt.-jp {
		min-height: 6.66667em;
	}
	.-nav-mdl .-ent.-lrg .btn_txt.-jp {
		min-height: 5.41667em;
	}
	.-sqr .btn_txt {
		font-size: 1.125em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.btn.-d-sqr {
		margin-top: 9.30233vw;
	}
}

@keyframes more-btn-act {
	0% {
		transform: scale(1, 1);
		transform-origin: right;
	}
	50% {
		transform: scale(0, 1);
		transform-origin: right;
	}
	50.1% {
		transform: scale(0, 1);
		transform-origin: left;
	}
	100% {
		transform: scale(1, 1);
		transform-origin: left;
	}
}

.menu-btn {
	width: 2.5em;
	height: 2.5em;
	margin-left: auto;
	color: #fff;
}

.menu-btn_lnk {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.menu-btn_bar {
	height: 3px;
	background-color: currentColor;
	display: block;
}

.menu-btn_bar:nth-child(2) {
	margin: 8px 0;
}

.menu-btn_bar:nth-child(odd) {
	transform: translate3d(-11.53846%, 0, 0);
}

.menu-btn_bar:nth-child(even) {
	transform: translate3d(11.53846%, 0, 0);
}

.menu-btn_bar-unt {
	width: 65%;
	display: block;
}

@media screen and (max-width: 670px) {
	.menu-btn {
		margin-left: auto;
	}
}

@media screen and (min-width: 671px) {
	.menu-btn {
		margin-left: 1.875em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.menu-btn {
		margin-left: auto;
	}
}

.btn-otr {
	width: 100%;
}

.btn-otr.-ent {
	display: flex;
	justify-content: space-between;
}

.btn-otr.-ent > * {
	width: calc((100% - 0.625em * 2) / 3);
	margin: 0;
}

.btn-otr.-ent.-nav {
	max-width: 23.75em;
	margin-left: auto;
}

.btn-otr.-ent.-ftr {
	max-width: 76.25em;
	margin: 0 auto;
	padding: 0 0.625em;
	position: relative;
	z-index: 0;
}

.btn-otr.-ent.-ftr::after {
	content: '';
	width: 100vw;
	height: 50%;
	background-color: #fff;
	display: block;
	position: absolute;
	bottom: -1px;
	left: 50%;
	z-index: -1;
	transform: translate3d(-50%, 0, 0);
}

@media screen and (max-width: 670px) {
	.btn-otr.-ent.-nav {
		display: none;
	}
	.btn-otr.-ent.-nav-mdl, .btn-otr.-ent.-ftr {
		font-size: 2.11765vw;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.btn-otr.-ent.-nav {
		display: none;
	}
	.btn-otr.-ent.-nav-mdl, .btn-otr.-ent.-ftr {
		font-size: 1.86047vw;
	}
}

.bnr.-abt {
	overflow: hidden;
	position: relative;
	z-index: 5;
}

.bnr.-abt::after {
	content: '';
	width: 100%;
	height: 100%;
	background-color: #060d1f;
	opacity: 0.4;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.is-load .bnr.-abt::after {
	transition: opacity 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.ua-mbl .bnr.-abt:active::after {
	opacity: 0.6;
}

body:not(.ua-mbl) .bnr.-abt:hover::after {
	opacity: 0.6;
}

.bnr_lnk {
	display: block;
}

.frm-btn-otr {
	max-width: 40em;
	margin: 1.875em auto 0;
}

.frm-btn-otr > *:nth-child(n+2) {
	margin-top: 0.9375em;
}

/* Modal styles
   ========================================================================== */
.mdl {
	width: 100vw;
	height: 100vh;
	opacity: 0;
	visibility: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9000;
	transform: translate3d(0, 0, 0);
}

.mdl.is-opn {
	opacity: 1;
	visibility: visible;
}

.is-load .mdl.is-opn {
	transition: opacity 250ms cubic-bezier(0.22, 1, 0.36, 1), visibility 250ms cubic-bezier(0.22, 1, 0.36, 1);
}

.is-load .mdl {
	transition: opacity 500ms 83.33333ms cubic-bezier(0.22, 1, 0.36, 1), visibility 500ms 83.33333ms cubic-bezier(0.22, 1, 0.36, 1);
}

.mdl_bdy {
	width: 90vw;
	height: 100%;
	max-width: 1000px;
	opacity: 0;
	overflow: hidden;
	position: relative;
	z-index: 10;
	transform: scale(0.75);
}

.mdl_bdy.-rnd {
	border-radius: 0.5em;
}

.-ytb .mdl_bdy {
	height: auto;
	overflow: visible;
	display: flex;
	align-items: center;
}

.is-opn .mdl_bdy {
	opacity: 1;
	transform: scale(1);
}

.is-load .is-opn .mdl_bdy {
	transition: opacity 250ms 208.33333ms cubic-bezier(0.34, 1.56, 0.64, 1), transform 250ms 208.33333ms cubic-bezier(0.34, 1.56, 0.64, 1);
}

.is-load .mdl_bdy {
	transition: opacity 250ms cubic-bezier(0.34, 1.56, 0.64, 1), transform 250ms cubic-bezier(0.34, 1.56, 0.64, 1);
}

.mdl_inr {
	height: 100%;
	background-color: #fff;
	overflow: scroll;
}

.mdl_cls {
	overflow: hidden;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 15;
	cursor: pointer;
	transform: translate3d(-50%, 50%, 0) rotate(45deg);
}

.mdl_cls::before, .mdl_cls::after {
	content: '';
	background-color: #fff;
	opacity: 0;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
}

.is-opn .mdl_cls::before, .is-opn .mdl_cls::after {
	opacity: 1;
}

.is-load .is-opn .mdl_cls::before, .is-load .is-opn .mdl_cls::after {
	transition: opacity 250ms 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.is-load .mdl_cls::before, .is-load .mdl_cls::after {
	transition: opacity 250ms cubic-bezier(0.22, 1, 0.36, 1);
}

.mdl_cls::before {
	width: 100%;
	height: 2px;
}

.mdl_cls::after {
	width: 2px;
	height: 100%;
}

.mdl_ovrly {
	width: 100%;
	height: 100%;
	background-color: #060d1f;
	opacity: 0.9;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
	transform: translate3d(0, 0, 0);
}

@media screen and (max-width: 670px) {
	.mdl_bdy {
		max-height: calc(100% - 144px);
	}
	.mdl_cls {
		width: 36px;
		height: 36px;
	}
}

@media screen and (min-width: 671px) {
	.mdl_bdy {
		max-height: calc(100% - 184px);
	}
	.mdl_cls {
		width: 46px;
		height: 46px;
	}
}

/* YpuTube styles
   ========================================================================== */
.ytb_bdy, .ytb-bg_bdy {
	padding-top: 56.25%;
	position: relative;
}

.ytb_mov, .ytb-bg_mov {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.ytb {
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	border-radius: 0.3125em;
	overflow: hidden;
	position: relative;
	z-index: 0;
}

.ytb-thmb {
	max-width: 53.75em;
	margin: 0 auto;
	color: rgba(33, 33, 33, 0.8);
	border-radius: 0.3125em;
	overflow: hidden;
	position: relative;
	cursor: pointer;
}

.is-load .ytb-thmb {
	transition: color 225ms cubic-bezier(0.22, 1, 0.36, 1);
}

.ua-mbl .ytb-thmb:active {
	color: #ff0000;
}

body:not(.ua-mbl) .ytb-thmb:hover {
	color: #ff0000;
}

.sgk-rct-mov .ytb-thmb {
	width: 100%;
	height: 100%;
	max-width: none;
	position: absolute;
	top: 0;
	left: 0;
}

.ytb-thmb_lnk {
	display: block;
}

.sgk-rct-mov .ytb-thmb_lnk {
	width: 100%;
	height: 100%;
}

.is-load .sgk-rct-mov .ytb-thmb_lnk {
	transition: background-color 225ms cubic-bezier(0.22, 1, 0.36, 1);
}

.ua-mbl .sgk-rct-mov .ytb-thmb_lnk:active {
	background-color: rgba(6, 13, 31, 0.45);
}

body:not(.ua-mbl) .sgk-rct-mov .ytb-thmb_lnk:hover {
	background-color: rgba(6, 13, 31, 0.45);
}

.ytb-thmb_icn {
	width: 4.25em;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 5;
	transform: translate3d(-50%, -50%, 0);
}

.ytb-thmb_inf {
	display: block;
}

.sgk-rct-mov .ytb-thmb_inf {
	position: absolute;
}

.ytb-thmb_name {
	color: #fff;
	display: block;
	font-weight: 700;
}

.sgk-rct-mov .ytb-thmb_name {
	margin-top: 0.35714em;
	font-size: 1.75em;
}

@media screen and (max-width: 670px) {
	.sgk-rct-mov .ytb-thmb_icn {
		font-size: 3.29412vw;
	}
	.sgk-rct-mov .ytb-thmb_inf {
		font-size: 2.35294vw;
		bottom: 8%;
		left: 6%;
	}
}

@media screen and (max-width: 425px) {
	.sgk-rct-mov .ytb-thmb_icn {
		font-size: inherit;
	}
}

@media screen and (min-width: 671px) {
	.sgk-rct-mov .ytb-thmb_icn {
		font-size: 1.25em;
	}
	.sgk-rct-mov .ytb-thmb_inf {
		bottom: 8%;
		left: 6%;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.sgk-rct-mov .ytb-thmb_icn {
		font-size: inherit;
	}
	.sgk-rct-mov .ytb-thmb_inf {
		font-size: 1.86047vw;
	}
}

.ytb-bg-smpl {
	margin-top: 5em;
	padding: 2em 1.5em 4em;
	color: #fff;
	text-align: center;
	overflow: hidden;
	position: relative;
}

.ytb-bg {
	width: 100%;
	height: 56.25vw;
	min-width: 100%;
	min-height: 100%;
	background-color: #060d1f;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: -1;
	transform: translate3d(-50%, -50%, 0);
}

.ytb-bg-otr {
	overflow: hidden;
}

.ytb-bg-otr.-fxd {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 0;
	transform: translate3d(0, 0, 0);
}

.ytb-bg_mov {
	opacity: 0.5;
}

@media screen and (max-width: 670px) {
	.ytb-bg.-mbl-fxd {
		position: fixed;
	}
}

@media screen and (min-width: 671px) {
	.ytb-bg.-pc-fxd {
		position: fixed;
	}
}

/* Main general content styles
   ========================================================================== */
.mn-cnt_add-bg.-bg-wht {
	background-color: #fff;
}

.mn-cnt_add-bg.-bg-l-blu01 {
	background-color: #b1c0dd;
}

.mn-cnt_add-bg.-bg-l-blu02 {
	background-color: #b1c9dd;
}

.mn-cnt_add-bg.-bg-l-blu03 {
	background-color: #cedbf4;
}

.-group .mn-cnt_add-bg {
	overflow: hidden;
	position: relative;
}

.-group .mn-cnt_add-bg::before, .-group .mn-cnt_add-bg::after {
	content: '';
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.-group .mn-cnt_add-bg::before {
	background-image: url(../img/img_bg_noise.png);
	background-position: center;
	background-size: 31.25em 31.25em;
	mix-blend-mode: soft-light;
	opacity: 0.2;
	z-index: 5;
}

.-group .mn-cnt_add-bg::after {
	background-image: url(../img/img_bg_grp.webp);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	opacity: 0.4;
	z-index: 0;
}

.no-webp .-group .mn-cnt_add-bg::after {
	background-image: url(../img/img_bg_grp.jpg);
}

.-group .mn-cnt_add-bg > * {
	position: relative;
	z-index: 20;
}

.-network .mn-cnt_add-bg {
	overflow: hidden;
	position: relative;
	z-index: 0;
}

.-network .mn-cnt_add-bg::after {
	content: '';
	width: 100%;
	height: 100%;
	background-image: url(../img/img_bg_nw.webp);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	opacity: 0.65;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.no-webp .-network .mn-cnt_add-bg::after {
	background-image: url(../img/img_bg_nw.jpg);
}

.-philosophy .mn-cnt_bdy {
	padding-top: 0;
}

@media screen and (max-width: 670px) {
	.mn-cnt_bdy {
		padding-top: 2.85714em;
		padding-bottom: 2.85714em;
	}
	.-training .mn-cnt_bdy {
		padding-top: 0;
	}
}

@media screen and (min-width: 671px) {
	.-network .mn-cnt_add-bg {
		min-height: 65.625em;
	}
	.mn-cnt_bdy {
		padding-top: 5em;
		padding-bottom: 5em;
	}
	.-training .mn-cnt_bdy {
		padding-top: 2.5em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.-network .mn-cnt_add-bg {
		min-height: 0;
	}
}

.prm-ttl.-wht {
	color: #fff;
}

.msg-t-art .prm-ttl {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
}

.msg-t-art .prm-ttl::after {
	content: '';
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.9);
	box-shadow: 1.2em 1.2em 0.8em -1em rgba(6, 13, 31, 0.2);
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 0;
	transform: translate3d(-50%, -50%, 0) rotate(45deg);
}

.abt-art .prm-ttl {
	position: absolute;
	top: 0;
	right: 3.33333%;
	z-index: 50;
	transform: translate3d(0, -36%, 0);
}

.prm-ttl_txt {
	display: block;
}

.prm-ttl_txt.-en {
	color: #1050e2;
	font-style: italic;
	line-height: 1.1;
}

.-wht .prm-ttl_txt.-en {
	color: inherit;
}

.-ash-blu .prm-ttl_txt.-en {
	color: #7a87a3;
}

.prm-ttl_txt.-jp {
	margin-top: 0.4em;
	font-size: 1.25em;
}

.msg-t-art .prm-ttl_txt {
	position: relative;
	z-index: 5;
}

@media screen and (max-width: 670px) {
	.msg-t-art .prm-ttl {
		width: 47.05882vw;
		height: 47.05882vw;
		margin: 0 auto;
	}
	.prm-ttl_txt.-en {
		font-size: 9.41176vw;
	}
}

@media screen and (max-width: 425px) {
	.msg-t-art .prm-ttl {
		width: 14.28571em;
		height: 14.28571em;
	}
	.prm-ttl_txt.-en {
		font-size: 2.85714em;
	}
}

@media screen and (max-width: 374px) {
	.prm-ttl {
		font-size: 3.73333vw;
	}
}

@media screen and (min-width: 671px) {
	.prm-ttl.-flx {
		display: flex;
		align-items: baseline;
	}
	.msg-t-art .prm-ttl {
		width: 21.125em;
		height: 21.125em;
	}
	.prm-ttl_txt.-en {
		font-size: 4.0625em;
	}
	.-flx .prm-ttl_txt.-en {
		transform: translate3d(0, 0.075em, 0);
	}
	.-flx .prm-ttl_txt.-jp {
		margin: 0 0 0 1em;
	}
}

.scnd-ttl {
	color: #1050e2;
}

.scnd-ttl.-wht {
	color: #fff;
}

.scnd-ttl.-flx {
	display: flex;
	align-items: baseline;
}

.scnd-ttl_txt {
	display: block;
}

.scnd-ttl_txt.-en {
	font-size: 2.5em;
	font-style: italic;
	line-height: 1.1;
}

.-flx .scnd-ttl_txt.-en {
	transform: translate3d(0, 0.075em, 0);
}

.scnd-ttl_txt.-jp {
	margin-top: 0.5em;
}

.-flx .scnd-ttl_txt.-jp {
	margin: 0 0 0 0.9375em;
}

@media screen and (max-width: 374px) {
	.scnd-ttl {
		font-size: 3.73333vw;
	}
}

.tab-lst {
	margin-bottom: 1em;
	color: #031f6f;
	display: flex;
	justify-content: center;
	position: relative;
}

.tab-lst::after {
	content: '';
	width: 100%;
	height: 2px;
	background-color: currentColor;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 5;
}

.tab-lst_itm {
	width: 100%;
	height: 3.75em;
	max-width: 13.75em;
}

.tab-lst_lnk {
	height: 100%;
	font-size: 1.125em;
	font-weight: 700;
	text-align: center;
	border: 2px solid #fff;
	border-top-width: 0.5em;
	border-bottom: 0;
	background-color: #e6eeff;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 0;
}

.is-actv .tab-lst_lnk {
	border-color: currentColor;
	background-color: #fff;
	opacity: 1 !important;
	z-index: 10;
}

@media screen and (max-width: 670px) {
	.tab-lst {
		padding: 0 0.42857em;
	}
	.tab-lst_itm:nth-child(n+2) {
		margin-left: 0.42857em;
	}
}

@media screen and (min-width: 671px) {
	.tab-lst_itm:nth-child(n+2) {
		margin-left: 1.25em;
	}
}

.sgk-rct-mov {
	overflow: hidden;
	position: relative;
}

.sgk-rct-mov .ytb-bg {
	min-height: 135%;
}

@media screen and (max-width: 670px) {
	.sgk-rct-mov {
		height: 68.65672vw;
	}
}

@media screen and (min-width: 671px) {
	.sgk-rct-mov {
		height: 45.25547vw;
	}
}

@media screen and (min-width: 1370px) {
	.sgk-rct-mov {
		height: 38.75em;
	}
}

.tyr-frm {
	border: 0;
}

@keyframes xBndApprFx {
	0% {
		transform: scale(0, 1);
		transform-origin: 0 0;
	}
	50% {
		transform: scale(1, 1);
		transform-origin: 0 0;
	}
	50.1% {
		transform: scale(1, 1);
		transform-origin: 100% 0;
	}
	100% {
		transform: scale(0, 1);
		transform-origin: 100% 0;
	}
}

@keyframes xBndItmApprFx {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	50.1% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

/* About
   ======================================== */
.sgk-abt-art_ttl {
	width: 100%;
	max-width: 17.64706em;
	padding: 0.41176em 0.73529em 0.41176em 0;
	color: #fff;
	position: relative;
	z-index: 0;
}

.sgk-abt-art_ttl::after {
	content: '';
	width: 150vw;
	height: 100%;
	background-color: #031f6f;
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	z-index: -1;
}

* + .sgk-abt-art_cpy {
	margin-top: 1em;
}

.sgk-abt-art_cpy.-biz {
	font-size: 1.25em;
}

.sgk-abt-art_lnk-cpy {
	font-size: 1.5em;
}

@media screen and (max-width: 670px) {
	.sgk-abt-art {
		padding: 0 0 2.85714em;
	}
	.sgk-abt-art_inr + .sgk-abt-art_inr {
		margin-top: 2.85714em;
	}
	.sgk-abt-art_ttl {
		margin-bottom: 1.66667em;
		font-size: 1.71429em;
	}
	.sgk-abt-art_fig.-ph {
		margin-top: 1em;
	}
}

@media screen and (min-width: 671px) {
	.sgk-abt-art {
		padding: 2.5em 0 5em;
	}
	.sgk-abt-art_inr + .sgk-abt-art_inr {
		margin-top: 5em;
	}
	.sgk-abt-art_flx {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.sgk-abt-art_flx > * {
		width: calc((100% - 2.5em) / 2);
	}
	.sgk-abt-art_ttl {
		margin-bottom: 1.76471em;
		font-size: 2.125em;
	}
	.-grp .sgk-abt-art_ttl {
		margin-bottom: 0;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.-grp .sgk-abt-art_ttl {
		margin-bottom: 1.76471em;
	}
}

.abt-biz-sct_ttl {
	color: #031f6f;
	font-size: 1.875em;
}

.abt-biz-sct_ttl.-min {
	color: inherit;
	font-size: 1.5em;
}

* + .abt-biz-sct_ttl.-min {
	margin-top: 1em;
}

* + .abt-biz-sct_cpy {
	margin-top: 1em;
}

.abt-biz-sct_cpt {
	font-weight: 700;
}

.abt-biz-sct_cpt.-top {
	margin-bottom: 0.625em;
}

.abt-biz-sct_cpt.-btm {
	margin-top: 0.625em;
}

@media screen and (max-width: 670px) {
	.abt-biz-sct_inr + .abt-biz-sct_inr {
		margin-top: 2.85714em;
	}
	.-cnsl .abt-biz-sct_fig {
		margin-top: 1.5em;
	}
	.abt-biz-sct_cpt {
		font-size: 1.28571em;
	}
}

@media screen and (min-width: 671px) {
	.abt-biz-sct_inr + .abt-biz-sct_inr {
		margin-top: 5em;
	}
	.abt-biz-sct_inr.-cnsl {
		display: flex;
		justify-content: space-between;
	}
	.abt-biz-sct_inr.-cnsl > * {
		width: calc((100% - 2.5em) / 2);
	}
	.-cnsl .abt-biz-sct_fig-img {
		height: 12.5em;
	}
	.-cnsl .abt-biz-sct_fig-img > * {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.abt-biz-sct_cpt {
		font-size: 1.5em;
	}
}

/* Message
   ======================================== */
.msg-t-art {
	background-color: #f1f1f1;
	background-image: url(../img/img_bg_msg.png);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
}

.msg-t-art_ttl {
	font-size: 2em;
	margin-bottom: 0.625em;
}

.msg-t-art_cpy {
	line-height: 2.5;
}

.msg-t-art_cpy + .msg-t-art_cpy {
	margin-top: 1em;
}

@media screen and (max-width: 670px) {
	.msg-t-art {
		padding-top: 17.91045vw;
		padding-bottom: 2.85714em;
		text-align: center;
	}
	.msg-t-art_cpy-unt {
		margin-top: 14.92537vw;
	}
}

@media screen and (min-width: 671px) {
	.msg-t-art {
		padding-top: 6.875em;
		padding-bottom: 6.875em;
		text-align: left;
	}
	.msg-t-art_bdy {
		display: flex;
		align-items: center;
	}
	.msg-t-art_bdy > *:first-child {
		flex-shrink: 0;
	}
	.msg-t-art_cpy-unt {
		padding-left: 6.25em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.msg-t-art {
		padding-top: 3.75em;
		padding-bottom: 3.75em;
		font-size: 1.86047vw;
	}
	.msg-t-art_bdy {
		display: flex;
		align-items: center;
	}
	.msg-t-art_bdy > *:first-child {
		font-size: 0.875em;
	}
}

/* About SUGIKO
   ======================================== */
.abt-art_inr {
	position: relative;
}

.abt-art_inr::after {
	content: '';
	width: 100vw;
	height: 19.76744%;
	background-color: #fff;
	display: block;
	position: absolute;
	bottom: -1px;
	left: 50%;
	transform: translate3d(-50%, 0, 0);
}

@media screen and (max-width: 670px) {
	.abt-art {
		margin-top: 14.92537vw;
	}
}

@media screen and (min-width: 671px) {
	.abt-art {
		margin-top: 7.5em;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.abt-art {
		margin-top: 11.42857vw;
	}
}

/* Message
   ======================================== */
.top-msg-art {
	background-color: #f1f1f1;
}

.top-msg-hdr {
	position: relative;
}

.top-msg-hdr::after {
	content: '';
	width: 100%;
	height: 100%;
	background-image: url(../img/img_bg_top_msg.webp);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.no-webp .top-msg-hdr::after {
	background-image: url(../img/img_bg_top_msg.jpg);
}

.top-msg-hdr_bdy {
	position: relative;
	z-index: 5;
}

.top-msg-hdr_inr {
	background-color: #fff;
}

.top-msg-hdr_ttl {
	color: #031f6f;
	font-size: 1.75em;
}

.top-msg-hdr_cpy {
	margin-top: 1em;
	font-size: 1.25em;
}

.top-msg-hdr_ceo {
	margin: 0 auto;
}

.top-msg-hdr_name-ceo {
	max-width: 7.8em;
	margin-left: 0.5em;
	transform: translate3d(0, -0.25em, 0);
}

@media screen and (max-width: 670px) {
	.top-msg-hdr {
		padding-top: 2.85714em;
	}
	.top-msg-hdr::after {
		height: 58.57143em;
	}
	.top-msg-hdr_inr {
		margin-top: -2.85714em;
		padding: 2.14286em 2.14286em 1.42857em;
		position: relative;
		z-index: 5;
	}
	.top-msg-hdr_ceo {
		max-width: 34.28571em;
		margin: 0 auto;
	}
}

@media screen and (max-width: 425px) {
	.top-msg-hdr {
		font-size: 3.29412vw;
	}
}

@media screen and (min-width: 671px) {
	.top-msg-hdr {
		padding-top: 1.25em;
		font-size: 1.29032vw;
	}
	.top-msg-hdr::after {
		height: 38.75em;
	}
	.top-msg-hdr_bdy {
		display: flex;
		align-items: flex-start;
	}
	.top-msg-hdr_inr {
		max-width: 33.125em;
		padding: 3.75em;
		align-self: flex-end;
		transform: translate3d(0, 2.5em, 0);
	}
	.top-msg-hdr_ceo {
		height: 37.5em;
		max-width: 35em;
		overflow: hidden;
	}
}

@media screen and (min-width: 1240px) {
	.top-msg-hdr {
		font-size: inherit;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.top-msg-hdr {
		font-size: inherit;
	}
	.top-msg-hdr::after {
		height: 58.75em;
	}
	.top-msg-hdr_bdy {
		display: block;
	}
	.top-msg-hdr_inr {
		max-width: none;
		margin-top: -1.25em;
		transform: translate3d(0, 0, 0);
	}
}

.top-msg-sct_bdy {
	display: flex;
}

.top-msg-sct_inr {
	color: #fff;
	position: relative;
	z-index: 0;
}

.top-msg-sct_inr::after {
	content: '';
	width: 150vw;
	height: 100%;
	background-color: #031f6f;
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	z-index: -1;
}

.top-msg-sct_ttl {
	font-size: 1.75em;
}

.top-msg-sct_cpy {
	margin-top: 1.6em;
	font-size: 1.25em;
}

.top-msg-sct_ceo {
	margin: 0 auto;
	overflow: hidden;
}

@media screen and (max-width: 670px) {
	.top-msg-sct {
		margin-top: 2.85714em;
	}
	.top-msg-sct_bdy {
		flex-direction: column;
	}
	.top-msg-sct_inr {
		padding: 2.85714em 1.42857em 2.85714em 0;
		order: 5;
	}
	.top-msg-sct_ceo {
		max-width: 32.5em;
		aspect-ratio: 26 / 29;
		order: 0;
	}
}

@media screen and (max-width: 425px) {
	.top-msg-sct {
		font-size: 3.29412vw;
	}
}

@media screen and (min-width: 671px) {
	.top-msg-sct {
		margin-top: 5em;
		font-size: 1.29032vw;
	}
	.top-msg-sct_bdy {
		align-items: center;
	}
	.top-msg-sct_inr {
		max-width: 42.5em;
		padding: 6.25em 3.75em 6.25em 0;
	}
	.top-msg-sct_ceo {
		max-width: 20em;
		padding: 0 1.25em;
		flex-shrink: 0;
	}
}

@media screen and (min-width: 1240px) {
	.top-msg-sct {
		font-size: inherit;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.top-msg-sct_inr {
		padding: 3.75em 2.5em 3.75em 0;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.top-msg-sct {
		margin-top: 3.75em;
		font-size: inherit;
	}
	.top-msg-sct_bdy {
		flex-direction: column;
	}
	.top-msg-sct_inr {
		order: 5;
	}
	.top-msg-sct_ceo {
		max-width: 32.5em;
		aspect-ratio: 26 / 29;
		padding: 0;
		order: 0;
	}
}

.top-msg-mov-sct {
	background-color: #fff;
}

.top-msg-mov-sct_ttl {
	color: #1050e2;
	font-size: 1.5em;
}

@media screen and (max-width: 670px) {
	.top-msg-mov-sct {
		margin-top: 2.85714em;
		padding: 2.85714em 0;
	}
	.top-msg-mov-sct_ttl {
		text-align: center;
	}
}

@media screen and (min-width: 671px) {
	.top-msg-mov-sct {
		margin-top: 3.75em;
		padding: 3.75em 0;
	}
}

.top-msg-mov-lst {
	margin: 1.5625em auto 0;
}

.top-msg-mov-lst_ttl {
	margin-top: 0.6em;
	font-size: 1.25em;
}

@media screen and (max-width: 670px) {
	.top-msg-mov-lst {
		max-width: 37.14286em;
	}
	.top-msg-mov-lst_itm:nth-child(n+2) {
		margin-top: 1.42857em;
	}
	.top-msg-mov-lst_ttl {
		text-align: center;
	}
}

@media screen and (min-width: 671px) {
	.top-msg-mov-lst {
		display: flex;
		justify-content: space-between;
	}
	.top-msg-mov-lst_itm {
		width: 48%;
		max-width: 28.125em;
	}
}

/* Member
   ======================================== */
@media screen and (max-width: 670px) {
	.mbr-art {
		padding-top: 8.95522vw;
	}
	.mbr-art.-sub {
		padding: 0 0 2.85714em;
	}
}

@media screen and (min-width: 671px) {
	.mbr-art {
		padding-top: 5em;
	}
	.mbr-art.-sub {
		padding: 0 0 5em;
	}
	.mbr-art_inr {
		position: relative;
	}
	.mbr-art_inr .btn.-va {
		position: absolute;
		right: 0;
		bottom: 0;
	}
}

.emp-lst {
	color: #fff;
}

.emp-lst.-sub {
	margin-top: 0;
}

.emp-lst_itm {
	height: 15em;
	display: flex;
	align-items: center;
	position: relative;
}

.emp-lst_itm::after {
	content: '';
	width: 150vw;
	height: 100%;
	background-color: #777;
	display: block;
	position: absolute;
	top: 0;
	z-index: 0;
	transform: skew(-12deg);
}

.emp-lst_itm:nth-child(odd)::after {
	left: 0;
	transform-origin: left center;
}

.emp-lst_itm:nth-child(even)::after {
	right: 0;
	transform-origin: right center;
}

.emp-lst_itm:nth-child(even) > *:first-child {
	order: 5;
}

.is-load .emp-lst_itm {
	transition: filter 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.ua-mbl .emp-lst_itm:active {
	filter: brightness(125%);
}

.ua-mbl .emp-lst_itm:active .emp-lst_fig {
	filter: brightness(80%);
}

body:not(.ua-mbl) .emp-lst_itm:hover {
	filter: brightness(125%);
}

body:not(.ua-mbl) .emp-lst_itm:hover .emp-lst_fig {
	filter: brightness(80%);
}

.emp-lst_inr {
	width: 100%;
	padding: 0 1.875em;
	z-index: 10;
}

.emp-lst_name {
	transform: translate3d(0.9em, 0, 0) skew(-12deg);
}

.emp-lst_name-txt {
	display: block;
}

.emp-lst_name-txt.-cat {
	margin-bottom: 0.8em;
	padding: 0.4em 0.8em;
	font-size: 1.28571em;
	line-height: 1;
	border: 1px solid currentColor;
	display: inline-block;
}

.emp-lst_name-txt.-min {
	font-size: 1.125em;
}

.emp-lst_name-txt.-lrg {
	margin-top: 0.25em;
	font-size: 2em;
}

.emp-lst_fig {
	flex-shrink: 0;
	align-self: flex-end;
	z-index: 5;
}

.is-load .emp-lst_fig {
	transition: filter 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

@media screen and (max-width: 670px) {
	.emp-lst {
		margin-top: 2.14286em;
		font-size: calc(14 / 385 * (100vw - 40px));
	}
	.emp-lst_itm:nth-child(n+2) {
		margin-top: 2.14286em;
	}
	.emp-lst_itm:nth-child(odd) {
		transform: translate3d(1.5em, 0, 0);
	}
	.emp-lst_itm:nth-child(odd) .emp-lst_fig {
		transform: translate3d(-1em, 0, 0);
	}
	.emp-lst_itm:nth-child(even) {
		transform: translate3d(-1.5em, 0, 0);
	}
	.emp-lst_itm:nth-child(even) .emp-lst_fig {
		transform: translate3d(1em, 0, 0);
	}
	.emp-lst_name {
		font-size: 0.71429em;
	}
	.emp-lst_fig {
		width: 12.85714em;
	}
}

@media screen and (min-width: 671px) {
	.emp-lst {
		margin-top: 3.75em;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row-reverse;
		justify-content: space-between;
	}
	.emp-lst_itm {
		width: 46.66667%;
	}
	.-sub .emp-lst_itm:nth-child(n+2) {
		margin-top: 2.8125em;
	}
	.emp-lst_itm:nth-child(n+3) {
		margin-top: 2.8125em;
	}
	.-top .emp-lst_itm:nth-child(odd) {
		transform: translate3d(0, -50%, 0);
	}
	.-sub .emp-lst_itm:nth-child(odd) {
		margin-top: -15em;
	}
	.emp-lst_name {
		font-size: 0.75em;
	}
	.emp-lst_fig {
		width: 13.875em;
	}
}

@media screen and (min-width: 1240px) {
	.emp-lst_name {
		font-size: 0.875em;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.emp-lst {
		font-size: calc(16 / 970 * (100vw - 80px));
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.emp-lst {
		margin-top: 6.97674vw;
	}
}

.emp-min-lst {
	color: #fff;
	line-height: 1.7;
	display: flex;
	flex-wrap: wrap;
}

.emp-min-lst_lnk {
	height: 100%;
	padding: 1em 1.25em;
	background-color: #777;
	display: flex;
}

.emp-min-lst_txt {
	font-weight: 700;
	display: block;
}

.emp-min-lst_txt.-cat {
	padding: 0.4em 0.8em;
	line-height: 1;
	border: 1px solid currentColor;
	display: inline-block;
	transform: skew(-12deg);
}

.emp-min-lst_txt.-min {
	font-size: 0.8125em;
}

.emp-min-lst_txt.-min > * {
	margin: 0.25em 0;
}

.emp-min-lst_txt.-lrg {
	font-size: 1.5em;
	flex-shrink: 0;
}

@media screen and (max-width: 670px) {
	.emp-min-lst {
		margin: -0.14286em;
	}
	.emp-min-lst_itm {
		width: calc(100% / 2);
		padding: 0.14286em;
	}
	.emp-min-lst_lnk {
		flex-direction: column;
		justify-content: center;
	}
	.emp-min-lst_txt.-min > *:nth-child(2) {
		display: block !important;
	}
	.emp-min-lst_txt.-lrg {
		margin-top: auto;
	}
}

@media screen and (max-width: 425px) {
	.emp-min-lst {
		font-size: 3.29412vw;
	}
}

@media screen and (min-width: 671px) {
	.emp-min-lst {
		margin: -0.625em;
	}
	.emp-min-lst_itm {
		width: calc(100% / 3);
		padding: 0.625em;
	}
	.emp-min-lst_lnk {
		align-items: center;
	}
	.emp-min-lst_txt.-min {
		width: 57.80347%;
		max-width: 15.38462em;
		margin-right: 1.53846em;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.emp-min-lst_itm {
		width: calc(100% / 2);
	}
}

.mbr-sct {
	min-height: 20.625em;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.mbr-sct > * {
	position: relative;
	z-index: 10;
}

.mbr-sct::before, .mbr-sct::after {
	content: '';
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.mbr-sct::before {
	background-color: #031f6f;
	opacity: 0.8;
	z-index: 5;
}

.mbr-sct::after {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	z-index: 0;
}

.is-load .mbr-sct::after {
	transition: transform 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.mbr-sct.-tlk::after {
	background-image: url(../img/img_tlk_thmb.jpg);
}

.mbr-sct.-ps::after {
	background-image: url(../img/img_ps_thmb.jpg);
}

.is-load .mbr-sct {
	transition: filter 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.ua-mbl .mbr-sct:active {
	filter: brightness(125%);
}

.ua-mbl .mbr-sct:active::after {
	transform: scale(1.075);
}

body:not(.ua-mbl) .mbr-sct:hover {
	filter: brightness(125%);
}

body:not(.ua-mbl) .mbr-sct:hover::after {
	transform: scale(1.075);
}

@media screen and (max-width: 670px) {
	.mbr-sct + .mbr-sct {
		margin-top: 2px;
	}
	.mbr-sct-otr {
		margin-top: 2.85714em;
	}
}

@media screen and (max-width: 425px) {
	.mbr-sct {
		min-height: 67.76471vw;
	}
	.mbr-sct-otr {
		margin-top: 9.41176vw;
	}
}

@media screen and (min-width: 671px) {
	.mbr-sct {
		width: calc((100% - 2px) / 2);
	}
	.mbr-sct-otr {
		margin-top: 6.25em;
		display: flex;
		justify-content: space-between;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.mbr-sct {
		width: 100%;
	}
	.mbr-sct + .mbr-sct {
		margin-top: 2px;
	}
	.mbr-sct-otr {
		margin-top: 11.62791vw;
		display: block;
	}
}

.emp-hdr {
	line-height: 1.7;
	background-color: #fff;
	position: relative;
}

.emp-hdr_inr {
	position: relative;
}

.emp-hdr_inr::after {
	content: '';
	width: 150vw;
	height: 100%;
	background-color: #777;
	opacity: 0.15;
	display: block;
	position: absolute;
	top: 0;
	z-index: 0;
}

.-int .emp-hdr_inr::after {
	background-color: #e6eeff;
	opacity: 1;
}

.emp-hdr_inr > * {
	position: relative;
	z-index: 10;
}

.emp-hdr_intr {
	min-height: 13.75em;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
}

.emp-hdr_intr::after {
	content: '';
	width: 150vw;
	height: 100%;
	background-color: #1050e2;
	display: block;
	position: absolute;
	top: 0;
	z-index: 0;
	transform: skew(-12deg);
	transform-origin: right bottom;
}

.emp-hdr_intr-txt {
	width: 100%;
	font-size: 2em;
	font-weight: 700;
	position: relative;
	z-index: 5;
}

.emp-hdr_prf {
	font-weight: 700;
	line-height: 1.5;
	background-color: #fff;
	position: relative;
}

.-int .emp-hdr_prf {
	background-color: rgba(255, 255, 255, 0.8);
}

.emp-hdr_job-typ {
	min-width: 8.33333em;
	padding: 0.5em 1em;
	color: #fff;
	font-size: 1.125em;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	border-bottom-right-radius: 0.83333em;
	background-color: #7a87a3;
	position: absolute;
	top: 0;
	left: 0;
}

.emp-hdr_name-unt, .emp-hdr_name-txt {
	display: block;
}

.emp-hdr_name-unt:nth-child(n+2) {
	order: 5;
}

.emp-hdr_name-txt.-jp {
	font-size: 3.75em;
}

.emp-hdr_name-txt.-en {
	color: #7a87a3;
	font-size: 1.875em;
	font-style: italic;
}

.emp-hdr_name-txt.-pst {
	margin-bottom: 0.3em;
	color: #7a87a3;
	font-size: 1.2em;
}

.emp-hdr_name-txt.-sj {
	color: #fff;
	width: 4em;
	height: 4em;
	font-size: 1.25em;
	text-align: center;
	border-radius: 50%;
	background-color: #1050e2;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	transform: translate3d(0, -50%, 0);
}

.emp-hdr_name-txt.-sj.-lft {
	right: 100%;
}

.emp-hdr_name-txt.-sj.-rgt {
	left: 100%;
}

.-int .emp-hdr_name {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.-int .emp-hdr_name::after {
	content: '×';
	margin: 0 0.35714em;
	font-family: 'Noto Sans JP';
	font-size: 3.5em;
	font-weight: 300;
	line-height: 1;
	display: block;
	flex-shrink: 0;
}

.-int.-ps .emp-hdr_name {
	align-items: flex-end;
	justify-content: space-around;
}

.-int.-ps .emp-hdr_name::after {
	display: none;
}

.emp-hdr_cpy {
	margin-top: 0.83333em;
	font-size: 1.5em;
}

.-rv .emp-hdr_cpy,
.-int .emp-hdr_cpy {
	margin: 0 0 0.83333em;
}

.emp-hdr_fig {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
}

.emp-hdr_fig::before {
	content: '';
	width: 100%;
	height: 100%;
	background-image: url(../img/img_bg_noise.png);
	background-position: center;
	background-size: 31.25em 31.25em;
	mix-blend-mode: soft-light;
	opacity: 0.2;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.emp-hdr_fig > * {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.emp-hdr_int-cpy {
	font-size: 1.5em;
	font-weight: 700;
	text-align: center;
}

@media screen and (max-width: 670px) {
	.emp-hdr {
		margin-bottom: calc(-300 / 630 * (100vw - 40px));
	}
	.-rv .emp-hdr {
		margin-bottom: calc(-280 / 630 * (100vw - 40px));
	}
	.emp-hdr_inr {
		padding: 67.16418vw 0 calc(340 / 630 * (100vw - 40px));
	}
	.emp-hdr_inr::after {
		left: 50%;
		transform: translate3d(-50%, 0, 0);
	}
	.emp-hdr_intr {
		padding: 0 1.42857em;
		font-size: calc(14 / 500 * (100vw - 40px));
	}
	.emp-hdr_intr::after {
		right: 4.28571em;
	}
	.emp-hdr_prf {
		padding: 1.42857em 1.78571em;
	}
	.emp-hdr_prf-unt {
		width: calc(100% - 1.42857em * 2);
		margin: 2.85714em auto 0;
	}
	.-member .emp-hdr_prf {
		padding-top: 3.21429em;
	}
	.-int .emp-hdr_prf {
		max-width: 46.66667em;
		margin: 0 auto;
		font-size: calc(12 / 630 * (100vw - 40px));
	}
	.-int.-ps .emp-hdr_name {
		font-size: 0.91667em;
		flex-wrap: wrap;
	}
	.-int.-ps .emp-hdr_name-unt {
		width: 50%;
	}
	.-int.-ps .emp-hdr_name-unt:nth-child(n+3) {
		margin-top: 2em;
	}
	.emp-hdr_fig {
		height: 44.28571em;
		font-size: 2.08955vw;
	}
	.emp-hdr_fig > * {
		object-position: calc(50% - 21.42857em) top;
	}
	.-int.-ps.-interview-02 .emp-hdr_fig > * {
		object-position: calc(50% - 23.42857em) top;
	}
	.emp-hdr_int-cpy {
		margin-top: 1.19048em;
	}
}

@media screen and (max-width: 425px) {
	.emp-hdr {
		font-size: calc(14 / 385 * (100vw - 40px));
	}
	.emp-hdr_name-txt.-jp {
		font-size: 3.28571em;
	}
}

@media screen and (min-width: 671px) {
	.emp-hdr {
		margin-bottom: -2.5em;
	}
	.-rv .emp-hdr,
	.-int .emp-hdr {
		margin-bottom: 0;
	}
	.emp-hdr_inr {
		padding: 6.25em 0 3.75em;
	}
	.emp-hdr_inr::after {
		right: 0;
	}
	.-int .emp-hdr_inr::after {
		right: auto;
		left: 50%;
		transform: translate3d(-50%, 0, 0);
	}
	.emp-hdr_intr {
		width: 50%;
	}
	.emp-hdr_intr::after {
		right: 0;
	}
	.emp-hdr_intr-txt {
		max-width: 16.875em;
	}
	.emp-hdr_prf {
		padding: 2.5em;
	}
	.emp-hdr_prf-unt {
		width: calc(100% - 2.5em * 2);
		max-width: 67.5em;
		margin: 3.75em auto 0;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		justify-content: space-between;
	}
	.-int .emp-hdr_prf-unt {
		margin-top: 5.625em;
	}
	.emp-hdr_prf-unt > * {
		width: 50%;
	}
	.emp-hdr_prf-unt > *:nth-child(2) {
		max-width: 44.44444%;
		transform: translate3d(0, -2.5em, 0);
	}
	.-int .emp-hdr_prf {
		width: 100%;
		max-width: 48.75em;
		padding: 1.875em 2.5em;
	}
	.-int.-ps .emp-hdr_prf {
		max-width: none;
		font-size: 1.29032vw;
	}
	.-int.-ps .emp-hdr_name {
		font-size: 0.6875em;
	}
	.emp-hdr_fig {
		height: 51.66667vw;
		max-height: 38.75em;
	}
	.-ps .emp-hdr_fig {
		max-height: 32.5em;
	}
	.emp-hdr_fig > * {
		object-position: center top;
	}
	.emp-hdr_int-cpy {
		width: 100%;
		max-width: none !important;
		margin-top: 2.5em;
		transform: translate3d(0, 0, 0) !important;
	}
}

@media screen and (min-width: 1240px) {
	.-int.-ps .emp-hdr_prf {
		font-size: inherit;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.emp-hdr {
		font-size: 1.52381vw;
	}
}

.mtt-crr-sct.-mtt {
	padding: 0.75em;
	border-top: 1.125em solid #777;
	color: #fff;
	background-color: rgba(3, 31, 111, 0.95);
}

.mtt-crr-sct_ttl {
	display: flex;
	align-items: baseline;
}

.mtt-crr-sct_ttl-txt {
	display: block;
}

.mtt-crr-sct_ttl-txt.-en {
	font-size: 2.1875em;
	font-style: italic;
	line-height: 1.1;
	flex-shrink: 0;
	transform: translate3d(0, 0.075em, 0);
}

.-crr .mtt-crr-sct_ttl-txt.-en {
	color: #1050e2;
}

.mtt-crr-sct_ttl-txt.-jp {
	margin-left: 1em;
	font-size: 1.25em;
}

.-mtt .mtt-crr-sct_ttl-txt.-jp {
	color: rgba(255, 255, 255, 0.7);
}

.mtt-crr-sct_hrzn {
	height: 2px;
	margin: 0.625em 0;
	background-color: #777;
}

.mtt-crr-sct_cpy {
	margin-top: 1em;
	padding: 0.75em;
	font-weight: 700;
	text-align: center;
	border: 1px solid currentColor;
}

@media screen and (max-width: 670px) {
	.mtt-crr-sct {
		margin-top: 2.85714em;
	}
	.mtt-crr-sct + .mtt-crr-sct {
		margin-top: 1.42857em;
	}
}

@media screen and (min-width: 671px) {
	.mtt-crr-sct.-crr {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
	.-crr .mtt-crr-sct_bdy {
		width: 100%;
		max-width: 44.44444%;
	}
}

.crr-stp-stp {
	--ageTrm-wdh: 3.5em;
	line-height: 1.5;
}

.crr-stp-stp_itm {
	display: flex;
	flex-wrap: wrap;
}

.crr-stp-stp_itm:nth-last-child(n+2) {
	--inrVgap-btm: 1em;
	--ageBrd-actv: '';
}

.crr-stp-stp_trm {
	width: var(--ageTrm-wdh);
	padding-bottom: var(--inrVgap-btm);
	color: #1050e2;
	text-align: center;
	flex-shrink: 0;
	position: relative;
}

.crr-stp-stp_trm::before {
	content: var(--ageBrd-actv);
	width: 2px;
	height: calc(100% - 1em * 1.5);
	background-color: rgba(122, 135, 163, 0.25);
	display: block;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate3d(-50%, -0.125em, 0);
}

.crr-stp-stp_dsc {
	padding: 0 0 var(--inrVgap-btm, 0) 1em;
}

.emp-art_bdy {
	background-color: #fff;
}

@media screen and (max-width: 670px) {
	.emp-art_inr.-add-pad {
		padding-top: 2.85714em;
		padding-bottom: 4.28571em;
	}
}

@media screen and (min-width: 671px) {
	.emp-art_inr.-add-pad {
		padding-top: 5em;
		padding-bottom: 6.25em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.emp-art_inr.-add-pad {
		padding-top: 9.30233vw;
		padding-bottom: 11.62791vw;
	}
}

.emp-sct_bdy > * {
	position: relative;
}

.emp-sct_inr {
	z-index: 5;
}

.emp-sct_ttl {
	display: flex;
	align-items: center;
}

* + .emp-sct_ttl {
	margin-top: 2.5em;
}

.emp-sct_ttl-txt {
	font-size: 1.5em;
	display: block;
}

.emp-sct_ttl-txt.-qs {
	margin-right: 0.4em;
	color: #1050e2;
	font-size: 3.125em;
	font-style: italic;
	line-height: 1;
	flex-shrink: 0;
	align-self: flex-start;
}

* + .emp-sct_cpy {
	margin-top: 1.25em;
}

.emp-sct_cpy.-int {
	display: flex;
}

.emp-sct_cpy-txt {
	display: block;
}

.emp-sct_cpy-txt.-name {
	width: 4em;
	margin: 0;
	flex-shrink: 0;
}

.emp-sct_cpy-txt.-inr + .emp-sct_cpy-txt.-inr {
	margin-top: 1.25em;
}

.emp-sct_fig {
	z-index: 0;
}

.-mid .emp-sct_fig::after,
.-lst .emp-sct_fig::after {
	content: '';
	width: 100vw;
	height: 100%;
	background-color: #777;
	opacity: 0.15;
	display: block;
	position: absolute;
	z-index: 0;
}

.emp-sct_fig-img {
	position: relative;
	z-index: 5;
}

.emp-sct_fig-img + .emp-sct_fig-img {
	margin-top: 1.875em;
}

.emp-sct_fig-img::before {
	content: '';
	width: 100%;
	height: 100%;
	background-image: url(../img/img_bg_noise.png);
	background-position: center;
	background-size: 31.25em 31.25em;
	mix-blend-mode: soft-light;
	opacity: 0.2;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.emp-sct_fig-img > * {
	width: 100%;
}

@media screen and (max-width: 670px) {
	.-rv .emp-sct,
	.-int .emp-sct {
		padding-bottom: 4.28571em;
	}
	.emp-sct_bdy + .emp-sct_bdy {
		margin-top: 4.28571em;
	}
	.emp-sct_inr {
		margin-top: 2.85714em;
	}
	.-mid .emp-sct_fig::after,
	.-lst .emp-sct_fig::after {
		bottom: -4.64286em;
	}
	.-mid .emp-sct_fig::after {
		left: 4.28571em;
	}
	.-lst .emp-sct_fig::after {
		right: 4.28571em;
	}
}

@media screen and (max-width: 425px) {
	.-rv .emp-sct,
	.-int .emp-sct {
		padding-bottom: 14.11765vw;
	}
	.emp-sct_bdy + .emp-sct_bdy {
		margin-top: 14.11765vw;
	}
	.emp-sct_fig-img + .emp-sct_fig-img {
		margin-top: 5.88235vw;
	}
}

@media screen and (min-width: 671px) {
	.-rv .emp-sct,
	.-int .emp-sct {
		padding: 7.5em 0;
	}
	.emp-sct_bdy {
		display: flex;
		justify-content: flex-end;
	}
	.-int .emp-sct_bdy:nth-child(even) {
		flex-direction: row-reverse;
	}
	.-int .emp-sct_bdy:nth-child(even) > *:nth-child(2) {
		padding: 0 2.5em 0 0;
	}
	.-rv .emp-sct_bdy:nth-child(n+2) {
		display: block;
	}
	.-rv .emp-sct_bdy:nth-child(n+2) > * {
		width: auto;
		padding: 0;
	}
	.emp-sct_bdy + .emp-sct_bdy {
		margin-top: 7.5em;
	}
	.-rv .emp-sct_bdy + .emp-sct_bdy {
		margin-top: 3.75em;
	}
	.emp-sct_bdy.-frst {
		align-items: flex-end;
		justify-content: space-between;
	}
	.emp-sct_bdy.-mid {
		flex-direction: row-reverse;
	}
	.emp-sct_bdy.-lst {
		align-items: flex-start;
	}
	.emp-sct_inr {
		width: calc(50% + 2.5em);
		padding-left: 2.5em;
	}
	.-mid .emp-sct_inr {
		padding: 0 2.5em 0 0;
	}
	.emp-sct_fig {
		margin: 0 auto;
		flex-shrink: 0;
	}
	.-frst .emp-sct_fig {
		margin: 0;
	}
	.-mid .emp-sct_fig {
		display: flex;
		align-items: center;
	}
	.-mid .emp-sct_fig::after {
		top: 0;
		left: 7.5em;
	}
	.-lst .emp-sct_fig::after {
		right: 6.25em;
		bottom: -3.75em;
	}
	.-rv .emp-sct_fig + * {
		align-self: center;
	}
	.-int .emp-sct_fig {
		align-self: center;
	}
	.-frst .emp-sct_fig-img,
	.-rv .emp-sct_fig-img,
	.-int .emp-sct_fig-img {
		max-width: 28.75em;
	}
	.-mid .emp-sct_fig-img {
		max-width: 26.25em;
	}
	.-lst .emp-sct_fig-img {
		max-width: 25em;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.emp-sct {
		font-size: 1.52381vw;
	}
}

@media screen and (max-width: 670px) {
	.tt-sct {
		padding: 4.28571em 0;
	}
}

@media screen and (max-width: 425px) {
	.tt-sct {
		padding-top: 14.11765vw;
	}
}

@media screen and (min-width: 671px) {
	.tt-sct {
		padding: 7.5em 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.tt-sct {
		padding: 11.42857vw 0;
	}
}

.tt-lst {
	font-size: 1.125em;
	line-height: 1.7;
}

.tt-lst_itm:nth-child(n+2) {
	margin-top: 1.11111em;
}

.tt-lst_trm {
	color: #7a87a3;
	display: flex;
}

.tt-lst_trm-txt {
	display: block;
}

.tt-lst_trm-txt.-time {
	min-width: 3.11111em;
}

.tt-lst_trm-txt.-act {
	padding: 0 1em;
}

@media screen and (max-width: 670px) {
	.tt-lst {
		margin-top: 2.53968em;
	}
	.tt-lst_itm {
		padding-left: 1.26984em;
		border-left: 4px solid rgba(122, 135, 163, 0.2);
	}
}

@media screen and (min-width: 671px) {
	.tt-lst {
		margin-top: 4.44444em;
	}
	.tt-lst_itm {
		display: flex;
	}
	.tt-lst_trm {
		width: 8.33333em;
		border-right: 6px solid rgba(122, 135, 163, 0.2);
		flex-shrink: 0;
	}
	.tt-lst_trm-txt.-act {
		margin: 0 auto;
	}
	.tt-lst_dsc {
		padding-left: 1.5em;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.tt-lst {
		margin-top: 7.61905vw;
	}
}

.emp-msg-sct {
	position: relative;
}

.emp-msg-sct_bdy {
	position: relative;
	z-index: 5;
}

.emp-msg-sct_inr {
	border-top: 1.125em solid #777;
	background-color: rgba(255, 255, 255, 0.75);
}

.emp-msg-sct_cpy {
	margin-top: 1.875em;
}

.emp-msg-sct_fig {
	width: 100%;
	max-width: 15em;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
}

.emp-msg-sct_fig::before {
	content: '';
	width: 100%;
	height: 100%;
	background-image: url(../img/img_bg_noise.png);
	background-position: center;
	background-size: 31.25em 31.25em;
	mix-blend-mode: soft-light;
	opacity: 0.2;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.emp-msg-sct_fig > * {
	width: 100%;
}

.emp-msg-sct_bg {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.emp-msg-sct_bg::before, .emp-msg-sct_bg::after {
	content: '';
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.emp-msg-sct_bg::before {
	background-image: url(../img/img_bg_noise.png);
	background-position: center;
	background-size: 31.25em 31.25em;
	mix-blend-mode: soft-light;
	opacity: 0.3;
	z-index: 10;
}

.emp-msg-sct_bg::after {
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 5;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
}

.emp-msg-sct_bg > * {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: relative;
	z-index: 0;
}

@media screen and (max-width: 670px) {
	.emp-msg-sct {
		padding: 10.71429em 0 4.28571em;
	}
	.emp-msg-sct_inr {
		padding: 7.85714em 2.14286em 2.14286em;
	}
	.emp-msg-sct_fig {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
	}
	.emp-msg-sct .prm-ttl {
		text-align: center;
	}
}

@media screen and (max-width: 425px) {
	.emp-msg-sct {
		font-size: 3.29412vw;
	}
}

@media screen and (min-width: 671px) {
	.emp-msg-sct {
		padding: 5em 0;
	}
	.emp-msg-sct_inr {
		padding: 3.75em 8.33333%;
		display: flex;
		align-items: center;
	}
	.emp-msg-sct_txt-unt {
		margin-right: 8%;
	}
	.emp-msg-sct_fig {
		flex-shrink: 0;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.emp-msg-sct {
		padding-top: 11.25em;
	}
	.emp-msg-sct_inr {
		padding: 7.5em 2.5em 2.5em;
	}
	.emp-msg-sct_txt-unt {
		margin-right: 0;
	}
	.emp-msg-sct_fig {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
	}
	.emp-msg-sct .prm-ttl {
		text-align: center;
	}
}

.emp-tbl-fig {
	background-color: #eaeaea;
}

.emp-tbl-fig_img {
	border: 2px solid #777;
	background-color: #fff;
	overflow-x: scroll;
}

.emp-tbl-fig_img > * {
	min-width: 51.25em;
}

@media screen and (max-width: 670px) {
	.emp-tbl-fig {
		padding: 2.85714em 0;
	}
}

@media screen and (max-width: 425px) {
	.emp-tbl-fig {
		padding: 9.63855vw 0;
	}
}

@media screen and (min-width: 671px) {
	.emp-tbl-fig {
		padding: 6.25em 0;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.emp-tbl-fig {
		padding: 7.61905vw 0;
	}
}

.int-art {
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.-talk .int-art {
	background-image: url("../emp-img/ps-int-02/img_emp_msg_bg.webp");
}

.no-webp .-talk .int-art {
	background-image: url("../emp-img/ps-int-02/img_emp_msg_bg.jpg");
}

.-project-story .int-art {
	background-image: url("../emp-img/tlk-int-01/img_emp_msg_bg.webp");
}

.no-webp .-project-story .int-art {
	background-image: url("../emp-img/tlk-int-01/img_emp_msg_bg.jpg");
}

.int-art::before, .int-art::after {
	content: '';
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.int-art::before {
	background-image: url(../img/img_bg_noise.png);
	background-position: center;
	background-size: 31.25em 31.25em;
	mix-blend-mode: soft-light;
	opacity: 0.3;
	z-index: 10;
}

.int-art::after {
	background-color: rgba(255, 255, 255, 0.75);
	z-index: 5;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
}

.int-art_bdy {
	position: relative;
	z-index: 20;
}

.int-art_ttl {
	text-align: center;
}

@media screen and (max-width: 670px) {
	.int-art {
		padding: 2.85714em 0;
	}
	.int-art_ttl {
		font-size: 1.28571em;
	}
}

@media screen and (min-width: 671px) {
	.int-art {
		padding: 5em 0;
	}
	.int-art_ttl {
		font-size: 1.5em;
	}
}

.int-lst_itm {
	padding: 1.25em;
	background-color: #fff;
}

.int-lst_bdy {
	height: 100%;
	padding: 0.9375em 0.9375em 3.75em;
	border: 1px solid #1050e2;
	overflow: hidden;
	position: relative;
}

.int-lst_ttl {
	padding: 0.75em 0.5em;
	color: #fff;
	font-size: 1.25em;
	text-align: center;
	background-color: #1050e2;
}

.int-lst_cpy {
	font-weight: 700;
	line-height: 1.4;
	text-align: center;
}

.int-lst_cpy.-tlk {
	display: flex;
	align-items: center;
	justify-content: center;
}

.int-lst_cpy.-tlk::after {
	content: '×';
	margin: 0 0.52632em;
	font-family: 'Noto Sans JP';
	font-size: 2.375em;
	font-weight: 300;
	line-height: 1;
	display: block;
	flex-shrink: 0;
}

.int-lst_cpy.-ps {
	font-size: 1.5em;
	margin: auto 0;
}

.int-lst_cpy-unt, .int-lst_cpy-txt {
	display: block;
}

.int-lst_cpy-unt:nth-child(n+2) {
	order: 5;
}

.int-lst_cpy-txt.-pst {
	margin-bottom: 0.3em;
	font-size: 0.75em;
}

.int-lst_cpy-txt.-jp {
	font-size: 2.25em;
}

.int-lst_cpy-txt.-en {
	color: #7a87a3;
	font-size: 1.25em;
	font-style: italic;
}

.int-lst_fig {
	margin: 0.9375em 0 1.25em;
}

@media screen and (max-width: 670px) {
	.int-lst {
		margin-top: 2.85714em;
		font-size: 2.68657vw;
	}
	.int-lst_itm:nth-child(n+2) {
		margin-top: 1.42857em;
	}
}

@media screen and (min-width: 671px) {
	.int-lst {
		font-size: 1.29032vw;
		margin-top: 5em;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.int-lst_itm {
		width: 48.33333%;
	}
	.int-lst_itm:nth-child(n+3) {
		margin-top: 3.33333%;
	}
	.-project-story .int-lst_bdy {
		display: flex;
		flex-direction: column;
	}
}

@media screen and (min-width: 1240px) {
	.int-lst {
		font-size: inherit;
	}
}

.int-oth-sct {
	position: relative;
}

.int-oth-sct_bdy {
	position: relative;
	z-index: 5;
}

.int-oth-sct_ttl {
	color: #fff;
	text-align: center;
}

.int-oth-sct_bg {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.int-oth-sct_bg::before, .int-oth-sct_bg::after {
	content: '';
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.int-oth-sct_bg::before {
	background-image: url(../img/img_bg_noise.png);
	background-position: center;
	background-size: 31.25em 31.25em;
	mix-blend-mode: soft-light;
	opacity: 0.3;
	z-index: 10;
}

.int-oth-sct_bg::after {
	background-color: rgba(3, 31, 111, 0.8);
	z-index: 5;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
}

.int-oth-sct_bg > * {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: relative;
	z-index: 0;
}

@media screen and (max-width: 670px) {
	.int-oth-sct {
		padding: 12vw 0;
	}
	.int-oth-sct_ttl {
		font-size: 4.70588vw;
	}
}

@media screen and (max-width: 425px) {
	.int-oth-sct_ttl {
		font-size: 1.42857em;
	}
}

@media screen and (min-width: 671px) {
	.int-oth-sct {
		padding: 5em 0;
	}
	.int-oth-sct_ttl {
		font-size: 1.5em;
	}
}

.int-inr-oth-sct {
	max-width: 58.75em;
	margin: 0 auto;
	padding: 1.875em;
	background-color: #fff;
	overflow: hidden;
	position: relative;
}

.-tlk .int-inr-oth-sct_ttl {
	margin: 0 auto;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.-tlk .int-inr-oth-sct_ttl::after {
	content: '×';
	margin: 0 0.52632em;
	font-family: 'Noto Sans JP';
	font-size: 2.375em;
	font-weight: 300;
	line-height: 1;
	display: block;
	flex-shrink: 0;
}

.-ps .int-inr-oth-sct_ttl {
	font-size: 1.5em;
}

.int-inr-oth-sct_ttl-unt, .int-inr-oth-sct_ttl-txt {
	display: block;
}

.int-inr-oth-sct_ttl-unt:nth-child(n+2) {
	order: 5;
}

.int-inr-oth-sct_ttl-txt.-pst {
	margin-bottom: 0.3em;
	font-size: 0.75em;
}

.int-inr-oth-sct_ttl-txt.-jp {
	font-size: 2.25em;
}

.int-inr-oth-sct_ttl-txt.-en {
	color: #7a87a3;
	font-size: 1.125em;
	font-style: italic;
}

@media screen and (max-width: 670px) {
	.int-inr-oth-sct {
		margin-top: 3.57143em;
		padding: 1.42857em;
		font-size: 2.8vw;
	}
	.int-inr-oth-sct + .int-inr-oth-sct {
		margin-top: 1.78571em;
	}
	.int-inr-oth-sct_fig {
		margin-top: 1.42857em;
	}
}

@media screen and (min-width: 671px) {
	.int-inr-oth-sct {
		margin-top: 2.5em;
		padding: 1.875em;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.-ps .int-inr-oth-sct_ttl {
		padding: 0 1em;
	}
	.int-inr-oth-sct_fig {
		max-width: 25em;
		margin-left: 1.875em;
		flex-shrink: 0;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.int-inr-oth-sct {
		font-size: 1.52381vw;
	}
}

/* Data / Recruiter Voice
   ======================================== */
.dt-rv-art_inr {
	position: relative;
}

.dt-rv-art_inr::after {
	content: '';
	width: 100vw;
	height: 100%;
	background-color: #e6eeff;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.-rvrs .dt-rv-art_inr::after {
	right: 0;
	left: auto;
}

.dt-rv-art_inr > * {
	position: relative;
	z-index: 10;
}

.dt-rv-art_cpy {
	margin-top: 1.875em;
}

.dt-rv-art_fig {
	position: absolute;
}

.dt-rv-art_fig > * {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
}

.-rv .dt-rv-art_fig > * {
	object-position: 75% top;
}

@media screen and (max-width: 670px) {
	.dt-rv-art {
		margin-top: 8.95522vw;
	}
	.dt-rv-art.-rv {
		padding-bottom: 2.85714em;
	}
	.dt-rv-art + .dt-rv-art {
		margin-top: 47.76119vw;
		margin-top: 22.85714em;
	}
	.dt-rv-art_inr::after {
		transform: translate3d(0, 5.8vw, 0);
	}
	.dt-rv-art_cnt-unt {
		padding: 0 0 2.14286em 1.42857em;
	}
	.-rvrs .dt-rv-art_cnt-unt {
		padding: 0 1.42857em 2.14286em 0;
	}
	.dt-rv-art_fig {
		width: calc(100% + 20px);
		height: 18.57143em;
		top: 100%;
		left: -20px;
	}
	.-rvrs .dt-rv-art_fig {
		right: -20px;
		left: auto;
	}
}

@media screen and (max-width: 425px) {
	.dt-rv-art + .dt-rv-art {
		margin-top: 72.94118vw;
	}
	.dt-rv-art_fig {
		height: 61.17647vw;
	}
}

@media screen and (min-width: 671px) {
	.dt-rv-art {
		margin-top: 7.5em;
	}
	.dt-rv-art.-rv {
		padding-bottom: 3.75em;
	}
	.dt-rv-art_inr::after {
		transform: translate3d(0, 2.8em, 0);
	}
	.dt-rv-art_cnt-unt {
		width: calc(50% + 3.75em);
		margin-left: auto;
		padding-left: 3.75em;
	}
	.-rvrs .dt-rv-art_cnt-unt {
		padding: 0 3.75em 0 0;
		margin-left: 0;
	}
	.dt-rv-art_fig {
		width: 45.71429vw;
		height: 111.74785%;
		max-width: 40em;
		right: 100%;
	}
	.-rvrs .dt-rv-art_fig {
		right: auto;
		left: 100%;
	}
	.-dt .dt-rv-art_fig {
		bottom: 0;
	}
	.-rv .dt-rv-art_fig {
		top: 5.5em;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.dt-rv-art_cnt-unt {
		width: calc(50% + 2.5em);
		padding-left: 2.5em;
	}
	.-rvrs .dt-rv-art_cnt-unt {
		padding: 0 2.5em 0 0;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.dt-rv-art {
		margin-top: 5em;
	}
	.dt-rv-art + .dt-rv-art {
		margin-top: 26.25em;
	}
	.dt-rv-art_cnt-unt {
		width: 100%;
		padding-bottom: 2.5em !important;
	}
	.dt-rv-art_fig {
		width: calc(100% + 40px);
		height: 21.25em;
		max-width: none;
		right: auto;
		left: -40px;
	}
	.-rvrs .dt-rv-art_fig {
		right: -40px;
		left: auto;
	}
	.-dt .dt-rv-art_fig,
	.-rv .dt-rv-art_fig {
		top: 100%;
	}
	.-dt .dt-rv-art_fig {
		bottom: auto;
	}
}

.wt-sct > * {
	position: relative;
}

.wt-sct_inr {
	margin: -11.66667% 0 0 auto;
	background-color: #fff;
	z-index: 5;
}

.wt-sct_cpy {
	margin-top: 1.25em;
}

.wt-sct_fig {
	z-index: 0;
}

.wt-sct_fig > * {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
}

@media screen and (max-width: 670px) {
	.wt-sct + .wt-sct {
		margin-top: 1.42857em;
	}
	.wt-sct-otr {
		margin-top: 22.85714em;
	}
	.wt-sct_inr, .wt-sct_fig {
		width: calc(100% - 1.42857em);
	}
	.wt-sct_inr {
		padding: 1.42857em;
	}
}

@media screen and (max-width: 425px) {
	.wt-sct-otr {
		margin-top: 75.29412vw;
	}
}

@media screen and (min-width: 671px) {
	.wt-sct {
		width: calc(100% / 2);
	}
	.wt-sct.-tr {
		padding-top: 2.5em;
	}
	.wt-sct-otr {
		margin-top: 10em;
		display: flex;
	}
	.wt-sct_inr, .wt-sct_fig {
		width: 90%;
	}
	.wt-sct_inr {
		width: 90%;
		padding: 1.25em 1.875em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.wt-sct {
		width: 100%;
	}
	.wt-sct + .wt-sct {
		margin-top: 2.5em;
	}
	.wt-sct.-tr {
		padding-top: 0;
	}
	.wt-sct-otr {
		margin-top: 26.25em;
		display: block;
	}
	.wt-sct_inr, .wt-sct_fig {
		width: calc(100% - 2.5em);
	}
}

.sgk-dt-intr {
	padding: 1em;
	font-size: 1.5em;
	font-weight: 700;
	line-height: 1.4;
	text-align: center;
	background-color: #eaeaea;
}

@media screen and (max-width: 425px) {
	.sgk-dt-intr {
		font-size: 4.94118vw;
	}
}

.sgk-dt-art {
	background-color: #0f75be;
	overflow: hidden;
}

@media screen and (max-width: 425px) {
	.sgk-dt-art {
		font-size: 3.29412vw;
	}
}

@media screen and (min-width: 671px) {
	.sgk-dt-art {
		font-size: 1.29032vw;
	}
	.sgk-dt-art_flx {
		display: flex;
	}
	.sgk-dt-art_flx > * {
		width: 50%;
	}
}

@media screen and (min-width: 1240px) {
	.sgk-dt-art {
		font-size: inherit;
	}
}

@media screen and (min-width: 1370px) {
	.sgk-dt-art {
		font-size: 1.16788vw;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.sgk-dt-art {
		font-size: inherit;
	}
	.sgk-dt-art_flx {
		display: block;
	}
	.sgk-dt-art_flx > * {
		width: 100%;
	}
}

.sgk-dt-sct {
	background-color: #031f6f;
}

.sgk-dt-sct.-typ-01 {
	background-color: #727f8e;
}

.sgk-dt-sct.-typ-03 {
	background-color: #0f75be;
}

.sgk-dt-sct.-typ-05 {
	background-color: #657bb5;
}

.sgk-dt-sct.-typ-06 {
	background-color: #2e4469;
}

.sgk-dt-sct.-typ-07 {
	background-color: #3a7e9c;
}

.sgk-dt-sct.-typ-08 {
	background-color: #fff;
}

.sgk-dt-sct-otr {
	background-color: #031f6f;
}

.sgk-dt-sct_ttl {
	color: #031f6f;
	text-align: center;
}

.sgk-dt-sct_ttl.-wht {
	color: #fff;
}

.-typ-04 .sgk-dt-sct_ttl {
	text-align: left;
}

.sgk-dt-sct_ttl-txt {
	padding: 0.25em 0.75em;
	border: 0.16667em solid currentColor;
	border-radius: 100em;
	display: inline-block;
}

.sgk-dt-sct_cpy {
	margin: 0 auto;
	text-align: center;
}

.-typ-01 .sgk-dt-sct_cpy,
.-typ-04 .sgk-dt-sct_cpy,
.-typ-07 .sgk-dt-sct_cpy {
	position: relative;
}

.-typ-01 .sgk-dt-sct_cpy > *:nth-child(2) {
	width: 245.28302%;
	max-width: none;
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
}

.-typ-03 .sgk-dt-sct_cpy {
	max-width: 28.75em;
}

.-typ-04 .sgk-dt-sct_cpy > *:first-child {
	position: relative;
	z-index: 5;
}

.-typ-04 .sgk-dt-sct_cpy > *:nth-child(n+2) {
	position: absolute;
}

.-typ-04 .sgk-dt-sct_cpy > *:nth-child(2) {
	width: 28.1746%;
	left: 35%;
}

.-typ-04 .sgk-dt-sct_cpy > *:nth-child(3) {
	width: 39.68254%;
	right: -18%;
	bottom: -15%;
}

.-typ-05 .sgk-dt-sct_cpy {
	max-width: 20em;
}

.-typ-06 .sgk-dt-sct_cpy {
	max-width: 16em;
}

.-typ-07 .sgk-dt-sct_cpy {
	max-width: 21.25em;
	transform: translate3d(-15%, 0, 0);
}

.-typ-07 .sgk-dt-sct_cpy > *:nth-child(2) {
	position: absolute;
	width: 40%;
	top: 50%;
	transform: translate3d(0, -50%, 0);
}

@media screen and (max-width: 670px) {
	.sgk-dt-sct {
		padding: 2.85714em 0;
	}
	.sgk-dt-sct.-typ-03 {
		padding-bottom: 9.41176%;
	}
	.sgk-dt-sct_ttl {
		margin-bottom: 2.85714em;
	}
	.sgk-dt-sct_ttl-txt {
		font-size: 1.71429em;
	}
	.-typ-01 .sgk-dt-sct_ttl {
		margin-bottom: 1.42857em;
	}
	.-typ-04 .sgk-dt-sct_cpy > *:nth-child(2) {
		top: -26%;
	}
	.-typ-07 .sgk-dt-sct_cpy > *:nth-child(2) {
		left: 92%;
		z-index: -1;
	}
}

@media screen and (min-width: 671px) {
	.sgk-dt-sct {
		padding: 3.75em 0;
	}
	.-typ-04 .sgk-dt-sct_bdy {
		padding-top: 15%;
		padding-top: 6.25em;
	}
	.sgk-dt-sct_ttl {
		margin-bottom: 3.125em;
	}
	.-typ-01 .sgk-dt-sct_ttl {
		text-align: left;
	}
	.-typ-01 .sgk-dt-sct_ttl {
		margin-bottom: -5em;
	}
	.-typ-02 .sgk-dt-sct_ttl {
		margin-bottom: 1.25em;
	}
	.-typ-04 .sgk-dt-sct_ttl {
		margin-bottom: -6.25em;
	}
	.sgk-dt-sct_ttl-txt {
		font-size: 2.25em;
	}
	.-typ-01 .sgk-dt-sct_cpy {
		max-width: 66.25em;
	}
	.-typ-04 .sgk-dt-sct_cpy {
		max-width: 31.5em;
	}
	.-typ-04 .sgk-dt-sct_cpy > *:nth-child(2) {
		top: -30%;
	}
	.-typ-07 .sgk-dt-sct_cpy > *:nth-child(2) {
		left: 105%;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.-typ-01 .sgk-dt-sct_ttl {
		margin-bottom: 1.875em;
		text-align: center;
	}
}

.dt-gndr-cpy {
	color: #fff;
	text-align: center;
}

.dt-gndr-cpy_txt {
	font-size: 2.5em;
	font-weight: 700;
	line-height: 1;
	display: inline-block;
	transform: translate3d(0, 0.1em, 0);
}

.dt-gndr-lst {
	margin-top: 2em;
	text-align: center;
	display: flex;
	align-items: flex-end;
}

.dt-gndr-lst_itm {
	width: 50%;
	position: relative;
}

.dt-gndr-lst_itm > .dt-gndr-lst_txt {
	width: 88%;
	position: relative;
	z-index: 5;
}

.dt-gndr-lst_itm > .dt-gndr-lst_obj {
	width: 100%;
}

.dt-gndr-lst_itm:first-child > .dt-gndr-lst_txt {
	max-width: 25.875em;
}

.dt-gndr-lst_itm:first-child > .dt-gndr-lst_obj {
	max-width: 9.25em;
}

.dt-gndr-lst_itm:nth-child(2) > .dt-gndr-lst_txt {
	max-width: 22.5em;
}

.dt-gndr-lst_itm:nth-child(2) > .dt-gndr-lst_obj {
	max-width: 7.375em;
}

.dt-gndr-lst_obj {
	position: absolute;
	bottom: -50%;
}

@media screen and (max-width: 670px) {
	.dt-gndr-lst_itm {
		font-size: 2.08955vw;
	}
	.dt-gndr-lst_itm:first-child > .dt-gndr-lst_obj {
		left: -3%;
	}
	.dt-gndr-lst_itm:nth-child(2) > .dt-gndr-lst_obj {
		right: 3%;
	}
}

@media screen and (min-width: 671px) {
	.dt-gndr-lst_itm:first-child > .dt-gndr-lst_obj {
		left: 15%;
	}
	.dt-gndr-lst_itm:nth-child(2) > .dt-gndr-lst_obj {
		left: 35%;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.dt-gndr-lst_itm {
		font-size: 1.86047vw;
	}
	.dt-gndr-lst_itm:first-child > .dt-gndr-lst_obj {
		left: 10%;
	}
	.dt-gndr-lst_itm:nth-child(2) > .dt-gndr-lst_obj {
		left: 55%;
	}
}

.dt-rct-lst {
	margin-bottom: 1.66667em;
	color: #fff;
	font-size: 1.5em;
	line-height: 1.5;
	border: 2px solid currentColor;
}

.dt-rct-lst_trm, .dt-rct-lst_dsc {
	padding: 0.5em 0.75em;
}

@media screen and (max-width: 670px) {
	.dt-rct-lst {
		text-align: center;
	}
	.dt-rct-lst_dsc {
		border-top: 2px solid currentColor;
	}
}

@media screen and (min-width: 671px) {
	.dt-rct-lst {
		display: flex;
		align-items: center;
	}
	.dt-rct-lst_trm, .dt-rct-lst_dsc {
		width: 50%;
	}
	.dt-rct-lst_dsc {
		border-left: 2px solid currentColor;
	}
}

/* Office
   ======================================== */
.ofc-atr_fig {
	max-width: 73.125em;
	margin: 1.875em auto 0;
}

@media screen and (max-width: 670px) {
	.ofc-atr {
		padding: 0 0 2.85714em;
	}
	.ofc-atr_ttl {
		font-size: 1.28571em;
	}
}

@media screen and (min-width: 671px) {
	.ofc-atr {
		padding: 2.5em 0 5em;
	}
	.ofc-atr_ttl {
		font-size: 1.5em;
	}
}

.ofc-sct_intr {
	background-color: #f1f1f1;
}

@media screen and (max-width: 670px) {
	.ofc-sct + .ofc-sct {
		margin-top: 2.85714em;
	}
	.ofc-sct_intr {
		padding: 1.42857em;
	}
}

@media screen and (min-width: 671px) {
	.ofc-sct + .ofc-sct {
		margin-top: 5em;
	}
	.ofc-sct_intr {
		padding: 1.875em;
	}
}

.ofc-thmb-lst {
	display: flex;
	flex-wrap: wrap;
}

@media screen and (max-width: 670px) {
	.ofc-thmb-lst {
		margin-top: 1.42857em;
		justify-content: space-between;
	}
	.ofc-thmb-lst_itm {
		width: 48.7013%;
	}
	.ofc-thmb-lst_itm:nth-child(n+3) {
		margin-top: 2.5974%;
	}
}

@media screen and (min-width: 671px) {
	.ofc-thmb-lst {
		margin-top: 2.5em;
	}
	.ofc-thmb-lst_itm {
		width: 23.75%;
	}
	.ofc-thmb-lst_itm:nth-child(n+2) {
		margin-left: 1.66667%;
	}
	.ofc-thmb-lst_itm:nth-child(4n+1) {
		margin-left: 0;
	}
	.ofc-thmb-lst_itm:nth-child(n+5) {
		margin-top: 1.66667%;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.ofc-thmb-lst_itm {
		width: 31.79487%;
	}
	.ofc-thmb-lst_itm:nth-child(n+2) {
		margin-left: 2.30769%;
	}
	.ofc-thmb-lst_itm:nth-child(3n+1) {
		margin-left: 0;
	}
	.ofc-thmb-lst_itm:nth-child(n+4) {
		margin-top: 2.5641%;
	}
}

.ofc-thmb {
	line-height: 1.5;
}

.ofc-thmb_cpt {
	margin-top: 0.75em;
	text-align: center;
}

@media screen and (max-width: 425px) {
	.ofc-thmb {
		font-size: 3.29412vw;
	}
}

/* Training
   ======================================== */
.tr-tbl {
	width: 74.75em;
	line-height: 1.5;
	background-color: #f1f1f1;
	display: block;
}

.tr-tbl > * {
	display: block;
}

.tr-tbl-otr {
	max-width: 75em;
	margin: 1.875em auto 3.75em;
	border: 0.125em solid #bbb;
	overflow-x: scroll;
}

.tr-tbl_tr, .tr-tbl_th, .tr-tbl_td {
	display: block;
}

.tr-tbl_tr {
	display: flex;
}

.tr-tbl_tr.-typ-01::before {
	content: '';
	width: 5em;
	height: 5em;
	background-color: #031f6f;
	display: block;
	flex-shrink: 0;
}

.tr-tbl_tr:nth-child(n+2) {
	border-top: 0.125em solid #bbb;
}

.tr-tbl_th {
	padding: 0.5em 0.25em;
	color: #fff;
	font-size: 1.625em;
	text-align: center;
	background-color: #031f6f;
	display: flex;
	align-items: center;
	justify-content: center;
}

.-typ-01 .tr-tbl_th {
	border-left: 0.07696em solid #bbb;
}

.-tbl-mce .-typ-01 .tr-tbl_th {
	width: calc(100% / 3);
}

.-tbl-ne .-typ-01 .tr-tbl_th:first-child {
	width: 4.61538em;
	flex-shrink: 0;
}

.-typ-02 .tr-tbl_th:first-child {
	width: 3.07692em;
	writing-mode: vertical-rl;
	flex-shrink: 0;
}

.-tbl-ne .-typ-01 .tr-tbl_th:nth-child(n+2) {
	width: calc((100% - 4.61538em) / 3);
}

.tr-tbl_td {
	width: 100%;
	padding: 1.25em 0 1.25em 1.25em;
	border-left: 0.125em solid #bbb;
}

.tr-tbl_flx {
	display: flex;
	align-items: flex-start;
}

.tr-tbl_flx.-typ-ne-01 > *:nth-child(2) {
	margin-left: 2.625em;
}

.tr-tbl_flx.-typ-ne-01 > *:nth-child(n+3) {
	margin-left: 0.625em;
}

.tr-tbl_flx.-typ-ne-02 {
	width: 100%;
	flex-wrap: wrap;
}

.tr-tbl_flx.-typ-ne-02 > *:nth-child(3) {
	margin-top: 0.625em;
}

.tr-tbl_flx.-typ-mce-01 > *:nth-child(n+2) {
	margin-left: 0.625em;
}

.tr-tbl_flx.-typ-mce-02 {
	width: 100%;
	flex-wrap: wrap;
}

.tr-tbl_flx.-typ-mce-02 > *:nth-child(3) {
	margin-top: 0.875em;
}

.-typ-ne-02 .tr-tbl_flx-itm:first-child {
	width: 30.75em;
}

.-typ-ne-02 .tr-tbl_flx-itm:first-child > *:first-child {
	width: 34%;
}

.-typ-ne-02 .tr-tbl_flx-itm:first-child > *:nth-child(2) {
	width: 68%;
}

.-typ-ne-02 .tr-tbl_flx-itm:first-child > *:nth-child(n+2) {
	margin-top: 0.625em;
}

.-typ-ne-02 .tr-tbl_flx-itm:nth-child(2) {
	width: 20.625em;
	margin-left: 0.625em;
}

.-typ-ne-02 .tr-tbl_flx-itm:nth-child(2) > *:nth-child(n+2) {
	margin-top: 0.9375em;
}

.-typ-mce-02 .tr-tbl_flx-itm:first-child {
	width: 40.25em;
}

.-typ-mce-02 .tr-tbl_flx-itm:first-child > *:nth-last-child(n+2) {
	width: 72.5%;
}

.-typ-mce-02 .tr-tbl_flx-itm:nth-child(2) {
	width: 23.125em;
	margin-left: 0.625em;
}

.-typ-mce-02 .tr-tbl_flx-itm > *:nth-child(n+2) {
	margin-top: 0.8125em;
}

@media screen and (max-width: 670px) {
	.tr-tbl-otr {
		font-size: 0.57143em;
	}
}

@media screen and (min-width: 671px) {
	.tr-tbl-otr {
		font-size: 1.16788vw;
	}
}

@media screen and (min-width: 1370px) {
	.tr-tbl-otr {
		font-size: inherit;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.tr-tbl-otr {
		font-size: 0.625em;
	}
}

/* Business
   ======================================== */
.sgk-biz-art_hdr {
	background-image: url(../img/img_sgk_biz_hr.webp);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.sgk-biz-art_hdr-bdy {
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
}

.no-webp .sgk-biz-art_hdr {
	background-image: url(../img/img_sgk_biz_hr.jpg);
}

.sgk-biz-art_ttl {
	color: #fff;
	line-height: 1.8;
	transform: translate3d(0, 25%, 0);
}

.sgk-biz-art_ttl-txt {
	padding: 0 0.25em;
	background-color: #031f6f;
}

.sgk-biz-art_intr {
	margin: auto 0 -3.125em;
	padding: 1.25em 0;
	color: #fff;
	position: relative;
	z-index: 0;
}

.sgk-biz-art_intr::after {
	content: '';
	width: 150vw;
	height: 100%;
	background-color: #031f6f;
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	z-index: -1;
}

.sgk-biz-art_cnt-anch {
	width: 15.625em;
	height: 15.625em;
	line-height: 1;
	text-align: center;
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 5;
	transform: translate3d(0, 50%, 0);
}

.sgk-biz-art_cnt-anch, .sgk-biz-art_cnt-anch-lnk {
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sgk-biz-art_cnt-anch-lnk {
	width: 11.25em;
	height: 7.625em;
}

.sgk-biz-art_cnt-anch-icn {
	display: block;
}

.sgk-biz-art_cnt-anch-icn::before {
	content: '\e901';
	color: #031f6f;
	font-size: 21.25em;
	font-family: 'icomoon';
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	speak: none;
	text-transform: none;
	display: block;
	transform: rotate(90deg);
}

@media screen and (max-width: 670px) {
	.sgk-biz-art_bdy {
		padding-top: 6em;
		padding-bottom: 2.85714em;
	}
	.sgk-biz-art_hdr {
		height: 94.11765vw;
	}
	.sgk-biz-art_ttl {
		font-size: 5.88235vw;
	}
	.sgk-biz-art_intr {
		padding-right: 1.42857em;
	}
	.sgk-biz-art_cnt-anch {
		display: none;
	}
}

@media screen and (max-width: 425px) {
	.sgk-biz-art_bdy {
		padding-top: 19.76471vw;
	}
	.sgk-biz-art_hdr {
		font-size: 3.29412vw;
	}
}

@media screen and (min-width: 671px) {
	.sgk-biz-art_bdy {
		padding-top: 7.5em;
		padding-bottom: 5em;
	}
	.sgk-biz-art_hdr {
		height: 38.75em;
	}
	.sgk-biz-art_ttl {
		font-size: 3.375em;
	}
	.sgk-biz-art_intr {
		width: calc(100% - 15.625em);
		padding-right: 2.5em;
	}
	.sgk-biz-art_cnt-anch {
		right: 60px;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.sgk-biz-art_hdr {
		height: 59.04762vw;
	}
	.sgk-biz-art_ttl {
		font-size: 5.14286vw;
	}
	.sgk-biz-art_cnt-anch {
		right: 40px;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.sgk-biz-art_intr {
		width: 100%;
	}
	.sgk-biz-art_cnt-anch {
		display: none;
	}
}

.sgk-biz-sct_cpy {
	margin-top: 1.5625em;
}

@media screen and (max-width: 670px) {
	.sgk-biz-sct + .sgk-biz-sct {
		margin-top: 2.85714em;
	}
}

@media screen and (min-width: 671px) {
	.sgk-biz-sct + .sgk-biz-sct {
		margin-top: 5em;
	}
}

.rntl-flw {
	margin-top: 3.4375em;
	color: #fff;
	background-color: #657bb5;
	position: relative;
}

.rntl-flw_ttl {
	width: 16.66667em;
	height: 2em;
	color: #657bb5;
	text-align: center;
	background-color: #eaeaea;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	z-index: 5;
}

.rntl-flw_fig-img {
	max-width: 68.75em;
	margin: 0 auto;
}

.rntl-flw_cpt {
	margin-top: 0.625em;
	font-weight: 700;
	text-align: center;
}

@media screen and (max-width: 670px) {
	.rntl-flw {
		padding: 2.85714em 1.42857em 1.07143em;
	}
	.rntl-flw_ttl {
		font-size: 1.42857em;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
	}
	.rntl-flw_cpt {
		font-size: 1.28571em;
	}
}

@media screen and (max-width: 425px) {
	.rntl-flw {
		font-size: 3.29412vw;
	}
}

@media screen and (min-width: 671px) {
	.rntl-flw {
		padding: 3.75em 1.5625em 1.25em;
	}
	.rntl-flw_ttl {
		font-size: 1.875em;
		left: 0;
		transform: translate3d(-0.66667em, -50%, 0);
	}
	.rntl-flw_cpt {
		font-size: 1.5em;
	}
}

.sgk-biz-fig {
	padding: 1.25em 1.875em 1.875em;
	background-color: #eaeaea;
}

.sgk-biz-fig.-cnsl {
	margin-top: 1.5625em;
}

.sgk-biz-fig_img {
	margin-top: 0.5em;
}

.sgk-biz-fig_cpt {
	font-weight: 700;
	line-height: 1.4;
}

@media screen and (max-width: 670px) {
	.sgk-biz-fig {
		padding: 1.07143em 1.42857em 1.42857em;
	}
	.sgk-biz-fig_itm:nth-child(n+2) {
		margin-top: 1.07143em;
	}
	.-rntl .sgk-biz-fig_img > *:nth-child(n+2) {
		margin-top: 1.07143em;
	}
	.sgk-biz-fig_cpt {
		font-size: 1.28571em;
	}
}

@media screen and (min-width: 671px) {
	.sgk-biz-fig {
		padding: 1.25em 1.875em 1.875em;
	}
	.sgk-biz-fig.-cnsl {
		display: flex;
		justify-content: space-between;
	}
	.-cnsl .sgk-biz-fig_itm {
		width: 48.68421%;
	}
	.-rntl .sgk-biz-fig_img {
		display: flex;
		justify-content: space-between;
	}
	.-rntl .sgk-biz-fig_img > * {
		width: 48.68421%;
	}
	.sgk-biz-fig_cpt {
		font-size: 1.5em;
	}
}

/* History
   ======================================== */
.hst-art {
	background-color: #eae6d8;
	background-image: url(../img/img_bg_lttc.svg);
	background-position: center;
	background-size: 1.25em 1.25em;
}

.hst-art_bdy {
	position: relative;
}

@media screen and (max-width: 670px) {
	.hst-art {
		padding: 2.85714em 0 0;
	}
}

@media screen and (min-width: 671px) {
	.hst-art {
		padding: 3.75em 0 0;
	}
}

.hst-bg {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.hst-bg_img {
	width: 100%;
	max-width: 62.5em;
	opacity: 0.2;
	position: absolute;
}

.hst-bg_img:first-child {
	top: 5.5%;
	left: -7%;
}

.hst-bg_img:nth-child(2) {
	top: 21%;
	left: -8%;
}

.hst-bg_img:nth-child(3) {
	top: 34.2%;
	left: 24%;
}

.hst-bg_img:nth-child(4) {
	top: 55%;
	left: 35%;
}

@media screen and (max-width: 670px) {
	.hst-bg {
		display: none;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.hst-bg {
		display: none;
	}
}

.hst-sct {
	position: relative;
	z-index: 10;
}

.hst-sct_ttl {
	color: #fff;
	text-align: center;
	padding: 0.5em;
	background-color: #031f6f;
}

@media screen and (max-width: 670px) {
	.hst-sct_ttl {
		font-size: 1.42857em;
	}
}

@media screen and (min-width: 671px) {
	.hst-sct_ttl {
		font-size: 1.875em;
	}
}

.hst-lst {
	position: relative;
	z-index: 0;
}

.hst-lst::after {
	content: '';
	height: 100%;
	background-color: #031f6f;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.hst-lst_itm {
	position: relative;
}

.hst-lst_itm::before {
	content: '';
	border: solid rgba(255, 255, 255, 0.95);
	border-radius: 50%;
	background-color: #031f6f;
	display: block;
	flex-shrink: 0;
}

.hst-lst_ttl {
	height: 1.42105em;
	color: #fff;
	text-align: center;
	background-color: #031f6f;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.hst-lst_ttl::before {
	content: '';
	width: 1.26316em;
	height: 100%;
	background-color: #031f6f;
	display: block;
	position: absolute;
	top: 50%;
	right: calc(100% + 0.26316em);
	transform: translate3d(0, -50%, 0);
	clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
}

.hst-lst_inr {
	margin-top: 0.625em;
	background-color: #fff;
}

.hst-lst_cpy + .hst-lst_cpy {
	margin-top: 1em;
}

.hst-lst_fig {
	line-height: 1.5;
}

.hst-lst_fig.-flx {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.-flx .hst-lst_fig-itm {
	width: 47.95918%;
	margin-top: 4.08163%;
}

.-flx .hst-lst_fig-itm:nth-child(even) {
	margin-left: auto;
}

.hst-lst_cpt {
	background-color: #fff;
}

@media screen and (max-width: 670px) {
	.hst-lst {
		padding: 2.85714em 0;
	}
	.-typ-04 .hst-lst {
		padding-bottom: 20.89552vw !important;
	}
	.hst-lst::after {
		width: 0.57143em;
		left: 0.92857em;
	}
	.hst-lst_itm {
		display: flex;
		justify-content: space-between;
	}
	.hst-lst_itm::before {
		width: 2.42857em;
		height: 2.42857em;
		border-width: 0.5em;
	}
	.hst-lst_itm:nth-child(n+2) {
		margin-top: 2.14286em;
	}
	.hst-lst_bdy {
		width: calc(100% - 5.71429em);
	}
	.hst-lst_ttl {
		font-size: 1.71429em;
	}
	.hst-lst_inr {
		padding: 1.42857em;
	}
	.hst-lst_cpt {
		padding: 0.5em 0.75em;
	}
}

@media screen and (max-width: 374px) {
	.hst-lst {
		font-size: 3.73333vw;
	}
}

@media screen and (min-width: 671px) {
	.hst-lst {
		padding: 3.75em 0;
	}
	.-typ-01 .hst-lst {
		padding-bottom: 10em;
	}
	.-typ-02 .hst-lst {
		padding-bottom: 23.125em;
	}
	.-typ-03 .hst-lst {
		padding-bottom: 34.375em;
	}
	.-typ-04 .hst-lst {
		padding-bottom: 37.5em;
	}
	.hst-lst::after {
		width: 0.625em;
		left: 50%;
		transform: translate3d(-50%, 0, 0);
	}
	.hst-lst_itm.-yr-1955 {
		margin-top: 9.375em;
	}
	.hst-lst_itm.-yr-1971 {
		margin-top: 48.75em;
	}
	.hst-lst_itm.-yr-1978 {
		margin-top: 32.5em;
	}
	.hst-lst_itm.-yr-1979 {
		margin-top: 6.25em;
	}
	.hst-lst_itm.-yr-2002 {
		margin-top: 7.5em;
	}
	.hst-lst_itm.-yr-2004 {
		margin-top: 7.5em;
	}
	.hst-lst_itm.-yr-2007 {
		margin-top: 11.25em;
	}
	.hst-lst_itm.-yr-2008 {
		margin-top: 9.375em;
	}
	.hst-lst_itm.-yr-2010 {
		margin-top: 15.625em;
	}
	.hst-lst_itm.-yr-2011 {
		margin-top: 5.625em;
	}
	.hst-lst_itm.-yr-2012-2 {
		margin-top: 6.25em;
	}
	.hst-lst_itm.-yr-2014 {
		margin-top: 28.75em;
	}
	.hst-lst_itm.-yr-2016 {
		margin-top: 7.5em;
	}
	.hst-lst_itm.-yr-2017 {
		margin-top: 9.375em;
	}
	.hst-lst_itm.-yr-2019 {
		margin-top: 22.5em;
	}
	.hst-lst_itm.-yr-2020 {
		margin-top: 13.125em;
	}
	.hst-lst_itm::before {
		width: 3.375em;
		height: 3.375em;
		margin: 0 auto;
		border-width: 0.8125em;
	}
	.hst-lst_bdy {
		width: calc(50% - 6.875em);
		position: absolute;
		top: 0;
	}
	.-lft .hst-lst_bdy {
		left: 0;
	}
	.-rgt .hst-lst_bdy {
		right: 0;
	}
	.hst-lst_ttl {
		font-size: 2.375em;
	}
	.-lft .hst-lst_ttl::before {
		right: auto;
		left: calc(100% + 0.26316em);
		clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
	}
	.hst-lst_inr {
		padding: 1.25em;
	}
	.hst-lst_cpt {
		padding: 0.5em 0.75em;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.hst-lst {
		padding-bottom: 3.75em !important;
	}
	.-typ-04 .hst-lst {
		padding-bottom: 17.14286vw !important;
	}
	.hst-lst::after {
		left: 1.375em;
		transform: translate3d(0, 0, 0);
	}
	.hst-lst_itm {
		display: flex;
		justify-content: space-between;
	}
	.hst-lst_itm::before {
		margin: 0;
	}
	.hst-lst_itm:nth-child(n+2) {
		margin-top: 1.875em;
	}
	.hst-lst_bdy {
		width: calc(100% - 7.8125em);
		position: static;
	}
	.hst-lst_ttl {
		font-size: 2.375em;
	}
	.-lft .hst-lst_ttl::before {
		right: calc(100% + 0.26316em);
		left: auto;
		clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
	}
}

.hst-min-lst {
	position: relative;
	z-index: 0;
}

.hst-min-lst::before, .hst-min-lst::after {
	content: '';
	display: block;
	position: absolute;
}

.hst-min-lst::before {
	width: 2.625em;
	height: 8.75em;
	background-image: url(../img/img_min_hst_arw.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	top: 100%;
	z-index: 0;
}

.hst-min-lst::after {
	height: 100%;
	background-color: #031f6f;
	top: 2px;
	left: 0;
	z-index: -1;
}

.hst-min-lst_itm {
	position: relative;
}

.hst-min-lst_itm::before {
	content: '';
	border: solid rgba(255, 255, 255, 0.95);
	border-radius: 50%;
	background-color: #031f6f;
	display: block;
	order: 5;
	flex-shrink: 0;
}

.hst-min-lst_ttl {
	height: 1.42105em;
	color: #fff;
	text-align: center;
	background-color: #031f6f;
	display: flex;
	align-items: center;
	justify-content: center;
}

.hst-min-lst_inr {
	background-color: #fff;
}

.hst-min-lst_cpy + .hst-min-lst_cpy {
	margin-top: 1em;
}

.hst-min-lst_fig {
	line-height: 1.5;
}

.hst-min-lst_fig.-flx {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.-flx .hst-min-lst_fig-itm {
	width: 47.95918%;
}

.-flx .hst-min-lst_fig-itm:nth-child(even) {
	margin-left: auto;
}

.-flx .hst-min-lst_fig-itm:nth-child(n+3) {
	margin-top: 4.08163%;
}

.hst-min-lst_cpt {
	background-color: #fff;
}

@media screen and (max-width: 670px) {
	.hst-min-lst {
		padding: 0 0 2.85714em;
	}
	.hst-min-lst::before {
		left: -0.10714em;
		transform: translate3d(0, -75%, 0);
	}
	.hst-min-lst::after {
		width: 0.57143em;
		left: 0.92857em;
	}
	.hst-min-lst_itm::before {
		width: 2.42857em;
		height: 2.42857em;
		border-width: 0.5em;
		position: absolute;
		top: 0;
		left: 0;
	}
	.hst-min-lst_itm:nth-child(n+2) {
		margin-top: 2.14286em;
	}
	.hst-min-lst_itm > * {
		width: calc(100% - 3.57143em);
		margin-left: auto;
	}
	.hst-min-lst_ttl {
		font-size: 1.71429em;
	}
	.hst-min-lst_inr {
		padding: 1.42857em;
	}
	.hst-min-lst_cpt {
		padding: 0.5em 0.75em;
	}
}

@media screen and (max-width: 374px) {
	.hst-min-lst {
		font-size: 3.73333vw;
	}
}

@media screen and (min-width: 671px) {
	.hst-min-lst {
		padding: 0 0 3.75em;
	}
	.hst-min-lst::before {
		left: 50%;
		transform: translate3d(-50%, -75%, 0);
	}
	.hst-min-lst::after {
		width: 0.625em;
		left: 50%;
		transform: translate3d(-50%, 0, 0);
	}
	.hst-min-lst_itm {
		display: flex;
	}
	.hst-min-lst_itm::before {
		width: 3.375em;
		height: 3.375em;
		margin: 0 auto;
		border-width: 0.8125em;
	}
	.hst-min-lst_itm:nth-child(n+2) {
		margin-top: 1.875em;
	}
	.hst-min-lst_itm > * {
		width: calc(50% - 3.75em);
	}
	.hst-min-lst_itm > *:nth-child(2) {
		order: 10;
	}
	.hst-min-lst_ttl {
		font-size: 2.375em;
	}
	.hst-min-lst_inr {
		padding: 1.25em;
	}
	.hst-min-lst_cpt {
		padding: 0.5em 0.75em;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.hst-min-lst::before {
		left: 0.375em;
		transform: translate3d(0, -75%, 0);
	}
	.hst-min-lst::after {
		left: 1.375em;
		transform: translate3d(0, 0, 0);
	}
	.hst-min-lst_itm {
		display: block;
	}
	.hst-min-lst_itm::before {
		margin: 0;
		position: absolute;
		top: 0;
		left: 0;
	}
	.hst-min-lst_itm > * {
		width: calc(100% - 5.25em);
		margin-left: auto;
	}
	.hst-min-lst_ttl {
		font-size: 2.375em;
	}
}

.hst-bynd {
	color: #fff;
	text-align: center;
	background-color: #031f6f;
	position: relative;
}

.hst-bynd::before {
	content: '';
	width: 100vw;
	min-width: 85.625em;
	height: 100%;
	background-color: inherit;
	display: block;
	position: absolute;
	bottom: calc(100% - 1px);
	left: 50%;
	transform: translate3d(-50%, 0, 0);
	clip-path: polygon(0% 0%, 50% calc(100% - 1px), 100% 0%, 100% 100%, 0% 100%);
}

.hst-bynd_cpy {
	margin-top: 1.57895em;
	font-size: 2.375em;
	font-weight: 700;
	line-height: 1.4;
}

@media screen and (max-width: 670px) {
	.hst-bynd {
		padding: 2.85714em 1.42857em;
	}
	.hst-bynd::before {
		height: 9.375em;
	}
	.hst-bynd_cpy {
		margin-top: 1.20301em;
	}
}

@media screen and (max-width: 425px) {
	.hst-bynd {
		font-size: 3.29412vw;
	}
}

@media screen and (min-width: 671px) {
	.hst-bynd {
		padding: 5em 2.5em;
	}
	.hst-bynd::before {
		height: 18.75em;
	}
	.hst-bynd_cpy {
		margin-top: 1.57895em;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.hst-bynd::before {
		height: 9.375em;
	}
}

/* SDGs
   ======================================== */
.sdgs-art_cpy {
	margin-top: 1.875em;
}

@media screen and (max-width: 670px) {
	.sdgs-art {
		padding: 4.70588vw 0 0;
	}
}

@media screen and (min-width: 671px) {
	.sdgs-art {
		padding: 2.5em 0 0;
	}
}

.apr-sdgs-sct {
	margin-top: 1.875em;
	background-color: #f1f1f1;
}

.sgk-abt-art .apr-sdgs-sct {
	padding: 0;
	background-color: transparent;
}

.apr-sdgs-sct_ttl {
	color: #031f6f;
	font-size: 1.875em;
}

.apr-sdgs-sct_cpy {
	margin-top: 1em;
}

@media screen and (max-width: 670px) {
	.apr-sdgs-sct {
		padding: 1.42857em;
	}
	.apr-sdgs-sct_fig {
		margin-bottom: 2.5em;
	}
}

@media screen and (min-width: 671px) {
	.apr-sdgs-sct {
		padding: 2.5em;
	}
	.apr-sdgs-sct_fig {
		margin-bottom: 3.75em;
	}
}

.sdgs-sct + .sdgs-sct {
	margin-top: 0;
}

.sdgs-sct_ttl {
	border-bottom: 2px solid #031f6f;
}

.-sgk-m-grn .sdgs-sct_ttl {
	border-color: #1f6500;
}

.sdgs-sct_ttl-txt {
	padding: 0 0.75em;
	color: #fff;
	background-color: #031f6f;
}

.-sgk-m-grn .sdgs-sct_ttl-txt {
	background-color: #1f6500;
}

@media screen and (max-width: 670px) {
	.sdgs-sct {
		margin-top: 2.85714em;
	}
	.sdgs-sct_ttl {
		font-size: 1.71429em;
	}
}

@media screen and (max-width: 425px) {
	.sdgs-sct_ttl {
		font-size: 5.64706vw;
	}
}

@media screen and (min-width: 671px) {
	.sdgs-sct {
		margin-top: 3.75em;
	}
	.sdgs-sct_ttl {
		font-size: 2.125em;
	}
}

.sdgs-dtl-sct + .sdgs-dtl-sct {
	border-top: 2px solid #031f6f;
}

.sdgs-dtl-sct_inr {
	background-color: #f1f1f1;
}

* + .sdgs-dtl-sct_inr {
	margin-top: 1.875em;
}

.sdgs-dtl-sct_ttl {
	color: #031f6f;
	font-size: 1.875em;
}

.-sgk-m-grn .sdgs-dtl-sct_ttl {
	color: #1f6500;
}

.sdgs-dtl-sct_ttl.-min {
	font-size: 1.25em;
	text-align: justify;
}

.sdgs-dtl-sct_cpy {
	margin-top: 1.25em;
}

@media screen and (max-width: 670px) {
	.sdgs-dtl-sct {
		padding: 2.85714em 0;
	}
	.sdgs-dtl-sct_inr {
		padding: 1.42857em;
	}
	.sdgs-dtl-sct_ttl {
		text-align: center;
	}
}

@media screen and (min-width: 671px) {
	.sdgs-dtl-sct {
		padding: 3.75em 2.5em;
	}
	.sdgs-dtl-sct_inr {
		padding: 1.5625em 2.5em;
	}
}

.gls-lst {
	margin-top: 1.875em;
	font-weight: 700;
	line-height: 1.4;
}

.gls-lst_trm, .gls-lst_dsc {
	font-size: 1.875em;
}

.gls-lst_trm {
	width: 5.33333em;
	height: 1.83333em;
	color: #fff;
	text-align: center;
	background-color: #777;
	display: flex;
	align-items: center;
	justify-content: center;
}

.-no7 .gls-lst_trm {
	background-color: #f8bb06;
}

.-no11 .gls-lst_trm {
	background-color: #f3a121;
}

.-no12 .gls-lst_trm {
	background-color: #d29121;
}

.-no13 .gls-lst_trm {
	background-color: #427837;
}

.gls-lst_dsc {
	margin-top: 0.66667em;
}

@media screen and (max-width: 670px) {
	.gls-lst {
		text-align: center;
	}
	.gls-lst_itm:nth-child(n+2) {
		margin-top: 2.85714em;
	}
	.gls-lst_trm {
		margin: 0 auto;
	}
	.gls-lst_fig {
		width: 9.14286em;
		margin: 0.7619em auto 0;
	}
}

@media screen and (max-width: 425px) {
	.gls-lst_dsc {
		font-size: 6.17647vw;
	}
}

@media screen and (min-width: 671px) {
	.gls-lst_itm {
		padding-left: 12.1875em;
		position: relative;
	}
	.gls-lst_itm:nth-child(n+2) {
		margin-top: 1.875em;
	}
	.gls-lst_fig {
		width: 100%;
		max-width: 5.5em;
		position: absolute;
		top: 0;
		left: 0;
	}
}

.ysdgs-sct {
	background-color: #e6eeff;
}

.ysdgs-sct_ttl {
	color: #031f6f;
	font-size: 1.875em;
	text-align: center;
}

.ysdgs-sct_ttl.-min {
	width: 9.16667em;
	margin-top: 1.25em;
	color: #fff;
	font-size: 1.5em;
	background-color: #031f6f;
}

.ysdgs-sct_fig {
	text-align: center;
	background-color: #fff;
}

.ysdgs-sct_fig > * {
	max-width: 35em;
}

@media screen and (max-width: 670px) {
	.ysdgs-sct {
		padding: 2.85714em 0;
	}
	.ysdgs-sct_fig {
		margin-top: 1.42857em;
		padding: 2.14286em 2.85714em;
	}
}

@media screen and (max-width: 425px) {
	.ysdgs-sct_ttl {
		font-size: 6.17647vw;
	}
}

@media screen and (min-width: 671px) {
	.ysdgs-sct {
		padding: 3.75em 0;
	}
	.ysdgs-sct_fig {
		margin-top: 2.5em;
		padding: 3.75em 2.5em;
	}
}

/* Network
   ======================================== */
.nw-art_bdy {
	position: relative;
	z-index: 50;
}

.nw-art_flx {
	margin-top: 1em;
}

.nw-art_unt {
	padding: 1.25em;
	background-color: rgba(16, 80, 226, 0.75);
}

.-nw-02 .nw-art_unt {
	background-color: rgba(3, 31, 111, 0.75);
}

.-nw-03 .nw-art_unt {
	background-color: rgba(2, 36, 64, 0.75);
}

.-nw-04 .nw-art_unt {
	background-color: rgba(31, 44, 116, 0.75);
}

.nw-art_ttl {
	color: #1050e2;
	line-height: 1.2;
}

.nw-art_ttl-txt {
	min-width: 4.41176em;
	padding: 0 0.5em;
	font-size: 2.125em;
	text-align: center;
	background-color: #fff;
	display: inline-block;
}

.nw-art_ttl.-min {
	color: #c1efff;
	font-size: 1.5em;
}

* + .nw-art_ttl.-min {
	margin-top: 0.83333em;
}

.nw-art_fig {
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
}

.nw-art_fig.-jp {
	width: 55em;
	left: 56%;
	z-index: 40;
}

.nw-art_fig.-vn {
	width: 175em;
	z-index: 0;
}

@media screen and (max-width: 670px) {
	.nw-art {
		padding: 2.85714em 0;
	}
	.nw-art_inr:nth-child(n+2) {
		margin-top: 0.625em;
	}
	.nw-art_unt + .nw-art_unt {
		margin-top: 0.71429em;
	}
	.nw-art_fig {
		display: none;
	}
}

@media screen and (min-width: 671px) {
	.nw-art {
		padding: 2.5em 0;
	}
	.nw-art_flx {
		display: flex;
	}
	.nw-art_inr {
		width: 100%;
		max-width: 16.25em;
	}
	.nw-art_inr:nth-child(n+2) {
		margin-left: 0.625em;
	}
	.nw-art_unt + .nw-art_unt {
		margin-top: 0.625em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.nw-art_flx {
		display: block;
	}
	.nw-art_inr {
		max-width: none;
	}
	.nw-art_inr:nth-child(n+2) {
		margin: 0.625em 0 0;
	}
	.nw-art_fig {
		display: none;
	}
}

.nw-lst {
	color: #fff;
}

* + .nw-lst {
	margin-top: 0.625em;
}

/* Contents
   ======================================== */
@media screen and (max-width: 670px) {
	.rct-cnt-art {
		padding: 8.95522vw 0 2.85714em;
	}
}

@media screen and (min-width: 671px) {
	.rct-cnt-art {
		padding: 5em 0;
	}
}

.rct-cnt-tab-lst {
	color: #fff;
	display: flex;
	flex-wrap: wrap;
}

.rct-cnt-tab-lst_trm {
	max-width: 13.75em;
	order: 0;
}

.rct-cnt-tab-lst_trm > * {
	height: 100%;
}

.rct-cnt-tab-lst_dsc {
	width: 100%;
	border: 1px solid currentColor;
	order: 5;
	display: none;
}

.rct-cnt-tab-lst_dsc.is-shw {
	display: block;
}

@media screen and (max-width: 670px) {
	.rct-cnt-tab-lst {
		margin-top: 8.95522vw;
		justify-content: space-between;
	}
	.rct-cnt-tab-lst_trm {
		width: calc((100% - 0.28571em * 3) / 4);
	}
	.rct-cnt-tab-lst_dsc {
		margin-top: 2.85714em;
	}
}

@media screen and (max-width: 425px) {
	.rct-cnt-tab-lst {
		font-size: 3.29412vw;
	}
}

@media screen and (min-width: 671px) {
	.rct-cnt-tab-lst {
		justify-content: center;
		margin-top: 2.5em;
	}
	.rct-cnt-tab-lst_trm {
		width: calc((100% - 0.625em * 3) / 4);
	}
	.rct-cnt-tab-lst_trm:nth-child(n+2) {
		margin-left: 0.625em;
	}
	.rct-cnt-tab-lst_dsc {
		margin-top: 3.75em;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.rct-cnt-tab-lst_dsc {
		margin-top: 5.71429vw;
	}
}

.rct-cnt-tab-inr-lst {
	color: #fff;
}

.rct-cnt-tab-inr-lst_itm {
	min-height: 16.875em;
	padding: 1.25em;
	border: 1px solid currentColor;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.rct-cnt-tab-inr-lst_itm::after {
	content: '';
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	opacity: 0.2;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.is-load .rct-cnt-tab-inr-lst_itm::after {
	transition: opacity 450ms cubic-bezier(0.22, 1, 0.36, 1), transform 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.-sgk .rct-cnt-tab-inr-lst_itm:first-child::after {
	background-image: url(../img/img_cnt_tab_thmb_01_1.jpg);
}

.-emp .rct-cnt-tab-inr-lst_itm:first-child::after {
	background-image: url(../img/img_cnt_tab_thmb_02_1.jpg);
}

.-sys .rct-cnt-tab-inr-lst_itm:first-child::after {
	background-image: url(../img/img_cnt_tab_thmb_03_1.jpg);
}

.-cmp .rct-cnt-tab-inr-lst_itm:first-child::after {
	background-image: url(../img/img_cnt_tab_thmb_04_1.jpg);
}

.-sgk .rct-cnt-tab-inr-lst_itm:nth-child(2)::after {
	background-image: url(../img/img_cnt_tab_thmb_01_2.jpg);
}

.-emp .rct-cnt-tab-inr-lst_itm:nth-child(2)::after {
	background-image: url(../img/img_cnt_tab_thmb_02_2.jpg);
}

.-sys .rct-cnt-tab-inr-lst_itm:nth-child(2)::after {
	background-image: url(../img/img_cnt_tab_thmb_03_2.jpg);
}

.-cmp .rct-cnt-tab-inr-lst_itm:nth-child(2)::after {
	background-image: url(../img/img_cnt_tab_thmb_04_2.jpg);
}

.-sgk .rct-cnt-tab-inr-lst_itm:nth-child(3)::after {
	background-image: url(../img/img_cnt_tab_thmb_01_3.jpg);
}

.-emp .rct-cnt-tab-inr-lst_itm:nth-child(3)::after {
	background-image: url(../img/img_cnt_tab_thmb_02_3.jpg);
}

.-cmp .rct-cnt-tab-inr-lst_itm:nth-child(3)::after {
	background-image: url(../img/img_cnt_tab_thmb_04_3.jpg);
}

.-sgk .rct-cnt-tab-inr-lst_itm:nth-child(4)::after {
	background-image: url(../img/img_cnt_tab_thmb_01_4.jpg);
}

.-cmp .rct-cnt-tab-inr-lst_itm:nth-child(4)::after {
	background-image: url(../img/img_cnt_tab_thmb_04_4.jpg);
}

.-sgk .rct-cnt-tab-inr-lst_itm:nth-child(5)::after {
	background-image: url(../img/img_cnt_tab_thmb_01_5.jpg);
}

.-cmp .rct-cnt-tab-inr-lst_itm:nth-child(5)::after {
	background-image: url(../img/img_cnt_tab_thmb_04_5.jpg);
}

.-sgk .rct-cnt-tab-inr-lst_itm:nth-child(6)::after {
	background-image: url(../img/img_cnt_tab_thmb_01_6.jpg);
}

.is-load .rct-cnt-tab-inr-lst_itm {
	transition: filter 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.ua-mbl .rct-cnt-tab-inr-lst_itm:active {
	filter: brightness(125%);
}

.ua-mbl .rct-cnt-tab-inr-lst_itm:active::after {
	opacity: 0.4;
	transform: scale(1.075);
}

body:not(.ua-mbl) .rct-cnt-tab-inr-lst_itm:hover {
	filter: brightness(125%);
}

body:not(.ua-mbl) .rct-cnt-tab-inr-lst_itm:hover::after {
	opacity: 0.4;
	transform: scale(1.075);
}

.rct-cnt-tab-inr-lst_itm > * {
	position: relative;
	z-index: 5;
}

@media screen and (min-width: 671px) {
	.rct-cnt-tab-inr-lst {
		display: flex;
		flex-wrap: wrap;
	}
	.rct-cnt-tab-inr-lst_itm {
		width: calc(100% / 3);
	}
	.-col-2 .rct-cnt-tab-inr-lst_itm {
		width: 50%;
	}
	.-col-3-2 .rct-cnt-tab-inr-lst_itm:nth-child(n+4) {
		width: 50%;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.rct-cnt-tab-inr-lst {
		font-size: 1.52381vw;
	}
}

/* Entry
   ======================================== */
.ent-atr.-gry {
	background-color: #f1f1f1;
}

@media screen and (max-width: 670px) {
	.ent-atr {
		padding: 2.85714em 0;
	}
}

@media screen and (min-width: 671px) {
	.ent-atr {
		padding: 5em 0;
	}
}

.not-rct {
	margin: 0 auto;
	font-size: 1.5em;
	font-weight: 700;
	line-height: 1.5;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.not-rct_txt {
	padding: 0 1em;
	color: #fff;
	background-color: #031f6f;
	display: block;
}

@media screen and (max-width: 425px) {
	.not-rct {
		font-size: 4.94118vw;
	}
}

.ent-lnk-unt {
	background-color: #cedbf4;
}

@media screen and (max-width: 670px) {
	.ent-lnk-unt {
		padding: 1.42857em 0;
	}
}

@media screen and (min-width: 671px) {
	.ent-lnk-unt {
		padding: 1.875em 0;
	}
}

.rct-typ-lst {
	line-height: 1.5;
}

.rct-typ-lst_itm:nth-child(n+2) {
	margin-top: 1.25em;
}

.rct-typ-lst_trm {
	width: 100%;
	max-width: 12.5em;
	padding-left: 0.625em;
	color: #fff;
	text-align: center;
	background-color: #0f75be;
	flex-shrink: 0;
}

.-cntr .rct-typ-lst_trm {
	background-color: #657bb5;
}

.rct-typ-lst_trm-txt {
	padding: 0.5em 1em;
	border-left: 0.25em solid #fff;
	display: block;
}

.rct-typ-lst_dsc {
	display: flex;
}

.rct-typ-lst_dsc > * {
	margin: 0;
}

@media screen and (max-width: 670px) {
	.rct-typ-lst_dsc {
		margin-top: 0.85714em;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.rct-typ-lst_dsc > * {
		max-width: none !important;
	}
	.rct-typ-lst_dsc.-odd > * {
		width: calc((100% - 0.85714em) / 3);
	}
	.rct-typ-lst_dsc.-odd > *:nth-child(n+4) {
		margin-top: 0.42857em;
	}
	.rct-typ-lst_dsc.-even > * {
		width: calc((100% - 0.42857em) / 2);
	}
	.rct-typ-lst_dsc.-even > *:nth-child(n+3) {
		margin-top: 0.42857em;
	}
}

@media screen and (max-width: 425px) {
	.rct-typ-lst_dsc > * {
		font-size: 3.29412vw;
	}
}

@media screen and (min-width: 671px) {
	.rct-typ-lst_itm {
		display: flex;
		align-items: center;
	}
	.rct-typ-lst_dsc {
		width: 100%;
		padding-left: 0.9375em;
	}
	.rct-typ-lst_dsc > *:nth-child(n+2) {
		margin-left: 0.9375em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.rct-typ-lst_itm {
		display: block;
	}
	.rct-typ-lst_dsc {
		margin-top: 0.9375em;
		padding: 0;
	}
}

.new-grad-kv {
	margin: 1.875em 0;
}

.new-grad-kv > * {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media screen and (max-width: 670px) {
	.new-grad-kv {
		height: 14.28571em;
	}
}

@media screen and (max-width: 425px) {
	.new-grad-kv {
		height: 47.05882vw;
		margin: 6.11765vw 0;
	}
}

@media screen and (min-width: 671px) {
	.new-grad-kv {
		height: 21.25em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.new-grad-kv {
		height: 39.53488vw;
	}
}

.ent-new-grad-lst_itm {
	padding: 2em;
	border: 1px solid #222;
	border-radius: 1.875em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ent-new-grad-lst_lnk {
	display: block;
}

.ent-new-grad-lst_lnk.-line {
	width: 100%;
	max-width: 41.42857em;
}

@media screen and (max-width: 670px) {
	.ent-new-grad-lst_itm:nth-child(n+2) {
		margin-top: 1.42857em;
	}
}

@media screen and (min-width: 671px) {
	.ent-new-grad-lst {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.ent-new-grad-lst_itm {
		width: calc((100% - 1.25em) / 2);
	}
	.ent-new-grad-lst_itm:nth-child(n+3) {
		margin-top: 1.25em;
	}
	.ent-new-grad-lst_itm.-line {
		width: 100%;
	}
	.-has-line .ent-new-grad-lst_itm:nth-child(n+2) {
		margin-top: 1.25em;
	}
	.ent-new-grad-lst_lnk.-line {
		width: 90%;
	}
}

.ent-inf-sct_ttl {
	color: #fff;
	font-size: 1.5em;
	display: flex;
	background-color: #0f75be;
}

.ent-inf-sct_ttl.-sngl {
	background-color: #031f6f;
}

.ent-inf-sct_ttl-txt {
	width: 100%;
	padding: 0.25em 0.5em;
	display: block;
}

.ent-inf-sct_ttl-txt.-job {
	background-color: #031f6f;
}

.ent-inf-sct_ttl-txt.-cat {
	width: 60%;
	max-width: 7.5em;
	margin-left: 0.41667em;
	text-align: center;
	border-left: 0.16667em solid #fff;
}

@media screen and (max-width: 670px) {
	.ent-inf-sct {
		margin-top: 1.42857em;
	}
	.ent-inf-sct + .ent-inf-sct {
		margin-top: 2.85714em;
	}
	.ent-inf-sct_ttl {
		font-size: 1.28571em;
	}
}

@media screen and (min-width: 671px) {
	.ent-inf-sct {
		margin-top: 3.75em;
	}
	.ent-inf-sct + .ent-inf-sct {
		margin-top: 5em;
	}
	.ent-inf-sct_ttl {
		font-size: 1.5em;
	}
}

.ent-inf-inr-sct + .ent-inf-inr-sct {
	margin-top: 1.875em;
}

.ent-inf-inr-sct.-gry {
	background-color: #f1f1f1;
}

.ent-inf-inr-sct_ttl {
	color: #031f6f;
	font-size: 1.25em;
}

.ent-inf-inr-sct_fig {
	margin: 0 auto;
}

@media screen and (max-width: 670px) {
	.ent-inf-inr-sct.-gry {
		padding: 1.42857em;
	}
	.-flx .ent-inf-inr-sct_inr {
		margin-top: 1.42857em;
	}
}

@media screen and (min-width: 671px) {
	.ent-inf-inr-sct.-flx {
		display: flex;
		align-items: center;
	}
	.ent-inf-inr-sct.-gry {
		padding: 1.5625em 2.5em;
	}
	.-flx .ent-inf-inr-sct_inr {
		padding-left: 1.875em;
	}
	.-flx .ent-inf-inr-sct_fig {
		width: 45vw;
		max-width: 28.125em;
		flex-shrink: 0;
		align-self: flex-start;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.ent-inf-inr-sct.-flx {
		display: block;
	}
	.-flx .ent-inf-inr-sct_inr {
		margin-top: 1.25em;
		padding-left: 0;
	}
	.-flx .ent-inf-inr-sct_fig {
		width: 75%;
		max-width: none;
	}
}

.wl-lst {
	color: #031f6f;
	font-weight: 700;
}

.wl-lst_itm {
	display: flex;
}

.wl-lst_trm {
	color: #5f5f5f;
	flex-shrink: 0;
}

.wl-lst_trm::after {
	content: '：';
	padding: 0em 0.25em;
}

.emp-rd-lst {
	margin: 0 auto;
	display: flex;
	justify-content: center;
}

.emp-rd-lst_itm {
	width: 9.375em;
	border-radius: 50%;
	overflow: hidden;
}

.emp-rd-lst_lnk {
	width: 100%;
	padding-top: 100%;
	border-radius: inherit;
	overflow: hidden;
	display: block;
	position: relative;
}

.emp-rd-lst_lnk > * {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

@media screen and (max-width: 670px) {
	.emp-rd-lst {
		max-width: 35.71429em;
		flex-wrap: wrap;
		align-items: flex-end;
	}
	.emp-rd-lst_itm {
		width: 44%;
		margin: 0 2%;
	}
	.emp-rd-lst_itm:nth-child(2n+4) {
		margin-top: 4%;
	}
}

@media screen and (min-width: 671px) {
	.emp-rd-lst_itm {
		width: 9.375em;
	}
	.emp-rd-lst_itm:nth-child(n+2) {
		margin-left: 1.25em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.emp-rd-lst_itm:nth-child(n+2) {
		margin-left: 0.625em;
	}
}

.ent-flw-lst {
	color: #fff;
	font-weight: 700;
	line-height: 1.4;
	text-align: center;
}

.ent-flw-lst_itm {
	padding: 2.5em 0;
	background-color: #0f75be;
}

.ent-flw-lst_itm.-cntr {
	background-color: #657bb5;
}

.ent-flw-lst_trm {
	color: #0f75be;
	font-size: 2.125em;
	background-color: #fff;
}

.-cntr .ent-flw-lst_trm {
	color: #657bb5;
}

.ent-flw-lst_dsc {
	margin-top: 2.5em;
	padding: 0 1em;
}

.ent-flw-lst_dsc + .ent-flw-lst_dsc {
	margin-top: 0.9375em;
}

.ent-flw-lst_dsc:nth-last-child(n+2)::after {
	content: '\e901';
	height: 0.6em;
	margin: 0 auto;
	font-size: 5em;
	font-family: 'icomoon';
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	speak: none;
	text-transform: none;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: rotate(90deg);
}

.ent-flw-lst_fig-img {
	width: 80%;
	max-width: 15.625em;
	margin: 0 auto;
	border-radius: 50%;
	overflow: hidden;
}

.ent-flw-lst_cpt {
	margin-top: 0.5em;
	font-size: 1.5em;
}

@media screen and (max-width: 670px) {
	.ent-flw-lst_itm:nth-child(n+2) {
		margin-top: 1.42857em;
	}
	.ent-flw-lst_trm {
		font-size: 1.71429em;
	}
}

@media screen and (min-width: 671px) {
	.ent-flw-lst {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
	.ent-flw-lst_itm {
		width: calc((100% - 1.875em) / 2);
	}
}

@media screen and (max-width: 670px) {
	.ent-frm-atr {
		padding: 0 0 2.85714em;
	}
}

@media screen and (min-width: 671px) {
	.ent-frm-atr {
		padding: 1.25em 0 5em;
	}
}

.app-req_ttl {
	padding: 0.25em 0.5em;
	color: #fff;
	background-color: #031f6f;
}

@media screen and (max-width: 670px) {
	.app-req_ttl {
		font-size: 1.28571em;
	}
}

@media screen and (min-width: 671px) {
	.app-req_ttl {
		font-size: 1.5em;
	}
}

.app-req-lst {
	line-height: 1.5;
	border: 1px solid #777;
}

.app-req-lst_itm:nth-child(n+2) {
	border-top: 1px solid #777;
}

.app-req-lst_trm {
	padding: 0.83333em 1.11111em;
	font-size: 1.125em;
	line-height: 1.3;
	background-color: #f1f1f1;
}

.app-req-lst_dsc {
	padding: 0.9375em 1.25em;
}

@media screen and (min-width: 671px) {
	.app-req-lst_itm {
		display: flex;
	}
	.app-req-lst_trm {
		width: 10em;
		flex-shrink: 0;
	}
	.app-req-lst_dsc {
		width: 100%;
	}
}

.rls-sct_ttl {
	color: #031f6f;
	font-size: 1.875em;
}

.rls-sct_inr {
	height: 26.25em;
	padding: 1.25em;
	border: 1px solid #777;
	overflow: scroll;
}

@media screen and (max-width: 670px) {
	* + .rls-sct {
		margin-top: 2.85714em;
	}
}

@media screen and (min-width: 671px) {
	* + .rls-sct {
		margin-top: 3.75em;
	}
}

.rls-ent-sct_ttl {
	font-size: 1.125em;
}

* + .rls-ent-sct_ttl {
	margin-top: 1.11111em;
}

* + .rls-ent-sct_cpy {
	margin-top: 0.625em;
}

.prv-sct_ttl {
	padding-bottom: 0.38462em;
	color: #031f6f;
	font-size: 1.625em;
	border-bottom: 2px solid currentColor;
}

* + .prv-sct_ttl {
	margin-top: 1em;
}

.prv-sct_ttl.-min {
	padding-bottom: 0;
	color: #222;
	font-size: 1.25em;
	border-bottom: 0;
}

* + .prv-sct_cpy {
	margin-top: 0.625em;
}

.prv-addr-lst_itm {
	padding: 1em;
	background-color: #f1f1f1;
}

.prv-addr-lst_dsc {
	margin-top: 0.625em;
}

@media screen and (max-width: 670px) {
	.prv-addr-lst_itm:nth-child(n+2) {
		margin-top: 0.71429em;
	}
}

@media screen and (min-width: 671px) {
	.prv-addr-lst {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.prv-addr-lst_itm {
		width: calc((100% - 1.25em) / 2);
	}
	.prv-addr-lst_itm:nth-child(n+3) {
		margin-top: 1.25em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.prv-addr-lst {
		display: block;
	}
	.prv-addr-lst_itm {
		width: 100%;
	}
	.prv-addr-lst_itm:nth-child(n+2) {
		margin-top: 1.25em;
	}
}

/* FAQ
   ======================================== */
.faq-unt {
	max-width: 70em;
	margin: 0 auto;
}

.faq-unt_ttl {
	font-weight: 700;
	line-height: 1.4;
	display: flex;
	align-items: baseline;
}

.faq-unt_ttl-txt {
	display: block;
}

.faq-unt_ttl-txt.-en {
	font-size: 2.5em;
	font-style: italic;
	line-height: 1.1;
	transform: translate3d(0, 0.075em, 0);
}

.faq-unt_ttl-txt.-jp {
	margin-left: 0.9375em;
}

@media screen and (min-width: 671px) {
	.faq-unt {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.faq-unt {
		font-size: 1.52381vw;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.faq-unt {
		font-size: inherit;
		display: block;
	}
}

.faq-cat-lst {
	line-height: 1.5;
}

.faq-cat-lst_lnk {
	display: flex;
	align-items: center;
}

.faq-cat-lst_lnk::after {
	content: '';
	width: 100%;
	height: 1px;
	min-width: 2.5em;
	margin-left: 0.625em;
	background-color: currentColor;
}

.faq-cat-lst_txt {
	flex-shrink: 0;
}

@media screen and (max-width: 670px) {
	.faq-cat-lst {
		margin-top: 1.42857em;
	}
	.faq-cat-lst_itm:nth-child(n+2) {
		margin-top: 1em;
	}
}

@media screen and (min-width: 671px) {
	.faq-cat-lst {
		display: flex;
	}
	.faq-cat-lst_itm:nth-child(n+2) {
		margin-left: 2.1875em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.faq-cat-lst {
		margin-top: 1.875em;
		display: block;
	}
	.faq-cat-lst_itm:nth-child(n+2) {
		margin: 1em 0 0;
	}
}

/* Main header styles
   ========================================================================== */
.mn-hdr {
	position: relative;
}

.mn-hdr.-top {
	color: #fff;
	background-color: #060d1f;
}

.mn-hdr.-sub {
	background-color: #fff;
}

.mn-hdr_bdy {
	padding-top: 1.5625em;
	padding-bottom: 2.1875em;
}

.mn-hdr_intr {
	font-weight: 700;
	line-height: 1.5;
}

@media screen and (max-width: 670px) {
	.mn-hdr.-sub {
		padding-top: 4.28571em;
	}
	.mn-hdr_intr {
		margin-top: 1em;
		font-size: 1.42857em;
	}
}

@media screen and (max-width: 425px) {
	.mn-hdr_bdy {
		padding-top: 5.17647vw;
		padding-bottom: 7.05882vw;
	}
}

@media screen and (max-width: 374px) {
	.mn-hdr_intr {
		font-size: 5.33333vw;
	}
}

@media screen and (min-width: 671px) {
	.mn-hdr.-sub {
		padding-top: 5.625em;
	}
	.-mbr-top .mn-hdr_bdy {
		padding-top: 5em;
	}
	.-mbr-top .mn-hdr_inr {
		width: 45.83333%;
	}
	.mn-hdr_intr {
		margin-top: 1.45833em;
		font-size: 1.5em;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.mn-hdr_bdy {
		font-size: 1.52381vw;
	}
}

.kv-hdr {
	height: 100vh;
	background-image: url(../img/img_bg_kv.webp);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	overflow: hidden;
	position: relative;
}

.no-webp .kv-hdr {
	background-image: url(../img/img_bg_kv.jpg);
}

.kv-hdr::before, .kv-hdr::after {
	content: '';
	display: block;
	position: absolute;
}

.kv-hdr::before {
	width: 100%;
	height: 100%;
	background-image: url(../img/img_bg_noise.png);
	background-position: center;
	background-size: 31.25em 31.25em;
	mix-blend-mode: soft-light;
	opacity: 0.25;
	top: 0;
	left: 0;
	z-index: 5;
}

.kv-hdr::after {
	width: 100em;
	height: 75em;
	background-image: url(../img/img_kv_sgk_rct.webp);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	left: 50%;
	z-index: 0;
	transform: translate3d(-50%, 0, 0);
}

.no-webp .kv-hdr::after {
	background-image: url(../img/img_kv_sgk_rct.png);
}

.kv-hdr_ttl {
	width: 100%;
	max-width: 28.125em;
	line-height: 1;
	position: absolute;
	left: 50%;
	z-index: 100;
	transform: translate3d(-50%, 0, 0);
}

@media screen and (max-width: 670px) {
	.kv-hdr {
		max-height: 67.69231em;
		min-height: 55.38462em;
		font-size: 1.52941vw;
	}
	.kv-hdr::after {
		top: 40px;
	}
	.kv-hdr_ttl {
		top: calc(19.07692em + 40px);
	}
}

@media screen and (min-width: 671px) {
	.kv-hdr {
		max-height: 62.5em;
		min-height: 46.25em;
	}
	.kv-hdr::after {
		top: 1.25em;
	}
	.kv-hdr_ttl {
		top: 20.3125em;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.kv-hdr {
		font-size: 1.52381vw;
	}
}

.hdr-nws {
	color: #222;
	line-height: 1.5;
	display: flex;
	align-items: center;
}

.hdr-nws-unt {
	width: 100%;
	padding: 1.25em 4.875em 1.25em 1.875em;
	border: 2px solid #1050e2;
	border-radius: 100em;
	background-color: #fff;
	position: absolute;
	z-index: 500;
}

.hdr-nws_trm {
	color: #777;
	font-size: 0.875em;
	flex-shrink: 0;
}

.hdr-nws_dsc {
	width: 100%;
	margin-left: 1.25em;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

@media screen and (max-width: 670px) {
	.hdr-nws-unt {
		width: calc(100% - 2.14286em);
		bottom: 1.42857em;
		left: 50%;
		transform: translate3d(-50%, 0, 0);
	}
}

@media screen and (min-width: 671px) {
	.hdr-nws-unt {
		max-width: 37.5em;
		right: 1.875em;
		bottom: 2.5em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.hdr-nws-unt {
		width: calc(100% - 3.75em);
		max-width: none;
		right: auto;
		left: 50%;
		transform: translate3d(-50%, 0, 0);
	}
}

/* Main navigation styles
   ========================================================================== */
.mn-nav {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2500;
}

.mn-nav_bdy {
	width: 100%;
	display: flex;
	align-items: center;
}

.mn-nav_bdy.-add-bg {
	background-color: #031f6f;
	background-image: linear-gradient(90deg, rgba(16, 80, 226, 0.9) 0%, rgba(3, 31, 111, 0.9) 100%);
}

.mn-nav_bdy.-fxd {
	position: fixed;
	top: 0;
	left: 0;
	transform: translate3d(0, -100%, 0);
	backface-visibility: hidden;
	transition: opacity 450ms cubic-bezier(0.22, 1, 0.36, 1), visibility 450ms cubic-bezier(0.22, 1, 0.36, 1), transform 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.mn-nav_bdy.-fxd.is-shw {
	transform: translate3d(0, 0, 0);
}

.ua-mbl .mn-nav_bdy.-fxd.is-shw {
	transition: opacity 450ms 300.15ms cubic-bezier(0.22, 1, 0.36, 1), visibility 450ms 300.15ms cubic-bezier(0.22, 1, 0.36, 1), transform 450ms 300.15ms cubic-bezier(0.22, 1, 0.36, 1);
}

@media screen and (max-width: 670px) {
	.mn-nav_bdy {
		height: 4.28571em;
		padding: 0 1.07143em;
	}
}

@media screen and (min-width: 671px) {
	.mn-nav_bdy {
		height: 5.625em;
		padding: 0 1.875em;
	}
}

.nav-mdl {
	width: 100vw;
	height: 100vh;
	color: #222;
	background-color: rgba(255, 255, 255, 0.8);
	opacity: 0;
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9000;
	transform: translate3d(0, 0, 0);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
}

.nav-mdl.is-opn {
	opacity: 1;
	visibility: visible;
}

.is-load .nav-mdl.is-opn {
	transition: opacity 250ms cubic-bezier(0.22, 1, 0.36, 1), visibility 250ms cubic-bezier(0.22, 1, 0.36, 1);
}

.is-load .nav-mdl {
	transition: opacity 500ms 83.33333ms cubic-bezier(0.22, 1, 0.36, 1), visibility 500ms 83.33333ms cubic-bezier(0.22, 1, 0.36, 1);
}

.ua-mbl .nav-mdl {
	height: 100dvh;
}

.nav-mdl_bdy {
	height: 100%;
	max-width: 80em;
	margin: 0 auto;
	opacity: 0;
	overflow: scroll;
	position: relative;
	z-index: 10;
	transform: scale(0.75);
}

.is-opn .nav-mdl_bdy {
	opacity: 1;
	transform: scale(1);
}

.is-load .is-opn .nav-mdl_bdy {
	transition: opacity 250ms 208.33333ms cubic-bezier(0.34, 1.56, 0.64, 1), transform 250ms 208.33333ms cubic-bezier(0.34, 1.56, 0.64, 1);
}

.is-load .nav-mdl_bdy {
	transition: opacity 250ms cubic-bezier(0.34, 1.56, 0.64, 1), transform 250ms cubic-bezier(0.34, 1.56, 0.64, 1);
}

.nav-mdl_inr {
	display: flex;
	flex-wrap: wrap;
}

.nav-mdl_cls {
	overflow: hidden;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 15;
	cursor: pointer;
	transform: translate3d(-50%, 50%, 0) rotate(45deg);
}

.nav-mdl_cls::before, .nav-mdl_cls::after {
	content: '';
	background-color: #1050e2;
	opacity: 0;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
}

.is-opn .nav-mdl_cls::before, .is-opn .nav-mdl_cls::after {
	opacity: 1;
}

.is-load .is-opn .nav-mdl_cls::before, .is-load .is-opn .nav-mdl_cls::after {
	transition: opacity 250ms 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.is-load .nav-mdl_cls::before, .is-load .nav-mdl_cls::after {
	transition: opacity 250ms cubic-bezier(0.22, 1, 0.36, 1);
}

.nav-mdl_cls::before {
	width: 100%;
	height: 2px;
}

.nav-mdl_cls::after {
	width: 2px;
	height: 100%;
}

@media screen and (max-width: 670px) {
	.nav-mdl_bdy {
		padding: 2.14286em 1.42857em 2.85714em;
	}
	.nav-mdl_inr {
		margin-top: 1.07143em;
		flex-direction: column;
	}
	.nav-mdl_inr > * {
		width: 100%;
	}
	.nav-mdl_inr > *:nth-child(2) {
		margin-top: 3.88889em;
	}
	.nav-mdl_inr > *:nth-child(3) {
		margin-top: 2.14286em;
		order: 25;
	}
	.nav-mdl_inr > *:nth-child(4) {
		margin-top: 5.88235vw;
	}
	.nav-mdl_cls {
		width: 36px;
		height: 36px;
	}
}

@media screen and (min-width: 671px) {
	.nav-mdl_bdy {
		padding: 2.5em 2.5em 3.75em;
	}
	.nav-mdl_inr {
		margin-top: 1.875em;
	}
	.nav-mdl_inr > *:first-child {
		width: calc(100% - 15.625em);
		order: 5;
	}
	.nav-mdl_inr > *:nth-child(2) {
		width: 100%;
		margin-top: 3.75em;
		order: 10;
	}
	.nav-mdl_inr > *:nth-child(3) {
		width: 15.625em;
		flex-shrink: 0;
		order: 0;
	}
	.nav-mdl_inr > *:nth-child(4) {
		width: 100%;
		margin-top: 1.875em;
		order: 15;
	}
	.nav-mdl_cls {
		width: 46px;
		height: 46px;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.nav-mdl_inr {
		font-size: 1.52381vw;
	}
	.nav-mdl_inr > *:first-child {
		width: calc(100% - 13.75em);
	}
	.nav-mdl_inr > *:nth-child(3) {
		width: 13.75em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.nav-mdl_inr {
		font-size: inherit;
	}
}

.rct-n-cnt-lst {
	line-height: 1.5;
}

.rct-n-cnt-lst_trm {
	color: #1050e2;
	font-size: 1.25em;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.rct-n-cnt-lst_trm::after {
	display: block;
}

@media screen and (max-width: 670px) {
	.rct-n-cnt-lst_itm {
		border-bottom: 2px solid rgba(16, 80, 226, 0.15);
	}
	.rct-n-cnt-lst_trm {
		padding: 1.14286em 0;
		justify-content: space-between;
	}
	.rct-n-cnt-lst_trm::after {
		content: '\e902';
		font-size: 1.37143em;
		font-family: 'icomoon';
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		line-height: 1;
		speak: none;
		text-transform: none;
	}
	.is-load .rct-n-cnt-lst_trm::after {
		transition: transform 450ms cubic-bezier(0.22, 1, 0.36, 1);
	}
	.rct-n-cnt-lst_trm.is-opn::after {
		transform: rotate(45deg);
	}
	.rct-n-cnt-lst_dsc {
		padding: 0.42857em 0 2.14286em;
		display: none;
	}
}

@media screen and (min-width: 671px) {
	.rct-n-cnt-lst_itm:nth-child(n+2) {
		margin-top: 1.625em;
	}
	.rct-n-cnt-lst_trm {
		pointer-events: none;
	}
	.rct-n-cnt-lst_trm::after {
		content: '';
		width: 100%;
		height: 1px;
		margin-left: 0.625em;
		background-color: currentColor;
		opacity: 0.75;
	}
	.rct-n-cnt-lst_trm-txt {
		display: block;
		flex-shrink: 0;
	}
	.rct-n-cnt-lst_dsc {
		margin-top: 0.75em;
	}
}

.rct-n-cnt-inr-lst {
	line-height: 1.5;
}

@media screen and (max-width: 670px) {
	.rct-n-cnt-inr-lst_itm:nth-child(n+2) {
		margin-top: 1.07143em;
	}
	.rct-n-cnt-inr-lst_lnk {
		display: flex;
		justify-content: space-between;
	}
	.rct-n-cnt-inr-lst_lnk::after {
		content: '\e901';
		font-size: 1.42857em;
		font-family: 'icomoon';
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		line-height: 1;
		speak: none;
		text-transform: none;
		align-self: center;
		transform: translate3d(-0.1em, 0, 0);
	}
}

@media screen and (min-width: 671px) {
	.rct-n-cnt-inr-lst {
		display: flex;
		flex-wrap: wrap;
	}
	.rct-n-cnt-inr-lst_itm {
		width: calc((100% - 2em * 2) / 3);
	}
	.rct-n-cnt-inr-lst_itm:nth-child(n+2) {
		margin: 0 0 0 2em;
	}
	.rct-n-cnt-inr-lst_itm:nth-child(n+4) {
		margin-top: 0.75em;
	}
	.rct-n-cnt-inr-lst_itm:nth-child(3n+1) {
		margin-left: 0em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.rct-n-cnt-inr-lst {
		font-size: 0.9375em;
		justify-content: space-between;
	}
	.rct-n-cnt-inr-lst_itm {
		width: calc((100% - 2em) / 2);
	}
	.rct-n-cnt-inr-lst_itm:nth-child(n+2) {
		margin: 0;
	}
	.rct-n-cnt-inr-lst_itm:nth-child(n+3) {
		margin-top: 0.75em;
	}
}

.rct-n-stlt-unt {
	line-height: 1.5;
}

.rct-n-stlt-unt_crp {
	font-style: italic;
	font-weight: 700;
}

.rct-n-stlt-unt_crp-lnk::after {
	color: #1050e2;
}

.rct-n-stlt-lst {
	font-size: 0.875em;
	line-height: 1.5;
}

@media screen and (max-width: 670px) {
	.rct-n-stlt-lst {
		margin-top: 1.63265em;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.rct-n-stlt-lst_itm {
		width: calc((100% - 1.63265em) / 2);
	}
	.rct-n-stlt-lst_itm:nth-child(n+3) {
		margin-top: 0.75em;
	}
}

@media screen and (max-width: 374px) {
	.rct-n-stlt-lst {
		font-size: 3.26667vw;
	}
}

@media screen and (min-width: 671px) {
	.rct-n-stlt-lst {
		margin-top: 2.85714em;
	}
	.rct-n-stlt-lst_itm:nth-child(n+2) {
		margin-top: 0.75em;
	}
}

.ssgl-lst {
	max-width: 77.5em;
	margin: 0 auto;
	display: flex;
}

.ssgl-lst::before, .ssgl-lst::after {
	content: '';
	display: block;
	flex-shrink: 0;
}

.-network .ssgl-lst {
	width: 100%;
	position: relative;
	z-index: 50;
}

.ssgl-lst-otr {
	background-color: #031f6f;
	overflow: scroll;
	display: flex;
}

.-network .ssgl-lst-otr {
	background-color: rgba(3, 31, 111, 0.8);
}

.-network.-nw-04 .ssgl-lst-otr {
	position: relative;
	z-index: 25;
}

.ssgl-lst_itm {
	min-width: 8.75em;
	flex-shrink: 0;
}

.-welfare .ssgl-lst_itm {
	min-width: 0;
}

.ssgl-lst_lnk {
	display: block;
}

@media screen and (max-width: 670px) {
	.ssgl-lst::before, .ssgl-lst::after {
		width: 0.71429em;
	}
	.ssgl-lst-otr {
		padding: 0.71429em 0;
	}
	.-welfare .ssgl-lst_itm {
		width: 7.14286em;
	}
	.ssgl-lst_itm:nth-child(n+2) {
		margin-left: 0.71429em;
	}
}

@media screen and (min-width: 671px) {
	.ssgl-lst::before, .ssgl-lst::after {
		width: 1.25em;
	}
	.-data .ssgl-lst {
		width: 95%;
		max-width: 53.75em;
		flex-wrap: wrap;
		justify-content: center;
	}
	.-data .ssgl-lst::before, .-data .ssgl-lst::after {
		display: none;
	}
	.ssgl-lst-otr {
		padding: 1.25em 0;
	}
	.-data .ssgl-lst_itm {
		margin: 0.3125em;
	}
	.-welfare .ssgl-lst_itm {
		width: 11.875em;
	}
	.ssgl-lst_itm:nth-child(n+2) {
		margin-left: 1.25em;
	}
	.-data .ssgl-lst_itm:nth-child(n+2) {
		margin-left: 0.3125em;
	}
	.-welfare .ssgl-lst_itm:nth-child(n+2) {
		margin-left: 0.75em;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.-welfare .ssgl-lst_itm {
		width: 8.75em;
	}
	.ssgl-lst_itm:nth-child(n+2) {
		margin-left: 0.625em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.-data .ssgl-lst {
		width: 100%;
		max-width: 77.5em;
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.-data .ssgl-lst::before, .-data .ssgl-lst::after {
		display: block;
	}
	.-data .ssgl-lst_itm {
		margin: 0;
	}
	.-data .ssgl-lst_itm:nth-child(n+2) {
		margin-left: 0.625em;
	}
}

/* Main footer styles
   ========================================================================== */
.mn-ftr_bdy {
	background-color: #fff;
}

@media screen and (max-width: 670px) {
	.mn-ftr_bdy {
		padding: 1.78571em 0 2.14286em;
	}
}

@media screen and (min-width: 671px) {
	.mn-ftr_bdy {
		padding: 2.5em 0 3.125em;
	}
}

.rct-f-cnt-sct {
	border-top: 1px solid #bbb;
}

@media screen and (max-width: 670px) {
	.rct-f-cnt-sct {
		margin-top: 1.78571em;
		padding-top: 2.14286em;
	}
	.rct-f-cnt-sct_inr:nth-child(n+2) {
		margin-top: 1.28571em;
	}
}

@media screen and (min-width: 671px) {
	.rct-f-cnt-sct {
		margin-top: 2.1875em;
		padding-top: 2.5em;
		display: flex;
		justify-content: space-between;
	}
	.rct-f-cnt-sct_inr {
		width: 100%;
	}
	.rct-f-cnt-sct_inr.-min {
		max-width: 16.875em;
		flex-shrink: 0;
	}
	.rct-f-cnt-sct_inr.-lrg {
		padding: 0.625em 0 0 6.25%;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.rct-f-cnt-sct_inr.-lrg {
		padding-left: 8.24742%;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.rct-f-cnt-sct_inr.-min {
		width: 40.60914%;
	}
}

.rct-f-cnt-lst {
	line-height: 1.5;
	display: flex;
}

.rct-f-cnt-lst_trm {
	color: #1050e2;
	display: flex;
	align-items: center;
}

.rct-f-cnt-lst_trm::after {
	content: '';
	width: 100%;
	height: 1px;
	margin-left: 0.625em;
	background-color: currentColor;
	opacity: 0.75;
	display: none;
}

.rct-f-cnt-lst_trm-txt {
	display: block;
	flex-shrink: 0;
}

.rct-f-cnt-lst_dsc {
	margin-top: 0.75em;
}

@media screen and (max-width: 670px) {
	.rct-f-cnt-lst {
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.rct-f-cnt-lst_itm {
		width: calc((100% - 1.42857em) / 2);
	}
	.rct-f-cnt-lst_itm:nth-child(n+3) {
		margin-top: 1.28571em;
	}
	.rct-f-cnt-lst_trm::after {
		display: block;
	}
}

@media screen and (max-width: 425px) {
	.rct-f-cnt-lst {
		display: block;
	}
	.rct-f-cnt-lst_itm {
		width: 100%;
	}
	.rct-f-cnt-lst_itm:nth-child(n+2) {
		margin-top: 1.28571em;
	}
}

@media screen and (min-width: 671px) {
	.rct-f-cnt-lst {
		justify-content: flex-end;
	}
	.rct-f-cnt-lst_itm:nth-child(n+2) {
		margin-left: 8.77193%;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.rct-f-cnt-lst {
		display: block;
	}
	.rct-f-cnt-lst_itm:nth-child(n+2) {
		margin: 1.25em 0 0;
	}
	.rct-f-cnt-lst_trm::after {
		display: block;
	}
}

.rct-f-cnt-inr-lst {
	font-size: 0.75em;
	line-height: 1.5;
}

@media screen and (max-width: 670px) {
	.rct-f-cnt-inr-lst_itm:nth-child(n+2) {
		margin-top: 0.75em;
	}
}

@media screen and (max-width: 425px) {
	.rct-f-cnt-inr-lst {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.rct-f-cnt-inr-lst_itm {
		width: calc((100% - 1.90476em) / 2);
	}
	.rct-f-cnt-inr-lst_itm:nth-child(n+2) {
		margin-top: 0;
	}
	.rct-f-cnt-inr-lst_itm:nth-child(n+3) {
		margin-top: 0.75em;
	}
}

@media screen and (min-width: 671px) {
	.rct-f-cnt-inr-lst_itm:nth-child(n+2) {
		margin-top: 0.75em;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.rct-f-cnt-inr-lst {
		display: flex;
		flex-wrap: wrap;
	}
	.rct-f-cnt-inr-lst_itm {
		width: calc((100% - 2em * 2) / 3);
	}
	.rct-f-cnt-inr-lst_itm:nth-child(n+2) {
		margin: 0 0 0 2em;
	}
	.rct-f-cnt-inr-lst_itm:nth-child(n+4) {
		margin-top: 0.75em;
	}
	.rct-f-cnt-inr-lst_itm:nth-child(3n+1) {
		margin-left: 0em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.rct-f-cnt-inr-lst {
		justify-content: space-between;
	}
	.rct-f-cnt-inr-lst_itm {
		width: calc((100% - 2em) / 2);
	}
	.rct-f-cnt-inr-lst_itm:nth-child(n+2) {
		margin: 0;
	}
	.rct-f-cnt-inr-lst_itm:nth-child(n+3) {
		margin-top: 0.75em;
	}
}

.rct-f-stlt-unt {
	line-height: 1.5;
	display: none;
}

.rct-f-stlt-unt_crp {
	font-style: italic;
	font-weight: 700;
}

.rct-f-stlt-unt_crp-lnk::after {
	color: #1050e2;
}

@media screen and (max-width: 670px) {
	.rct-f-stlt-unt {
		margin-top: 1.78571em;
	}
	.-lrg .rct-f-stlt-unt {
		display: block;
	}
}

@media screen and (min-width: 671px) {
	.rct-f-stlt-unt {
		margin-top: 1.25em;
	}
	.-min .rct-f-stlt-unt {
		display: block;
	}
}

.rct-f-stlt-lst {
	font-size: 0.75em;
	line-height: 1.5;
}

@media screen and (max-width: 670px) {
	.rct-f-stlt-lst {
		margin-top: 1.90476em;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.rct-f-stlt-lst_itm {
		width: calc((100% - 1.90476em) / 2);
	}
	.rct-f-stlt-lst_itm:nth-child(n+3) {
		margin-top: 0.75em;
	}
}

@media screen and (min-width: 671px) {
	.rct-f-stlt-lst {
		margin-top: 3.33333em;
	}
	.sns-acct + .rct-f-stlt-lst {
		margin-top: 2.08333em;
	}
	.rct-f-stlt-lst_itm:nth-child(n+2) {
		margin-top: 0.75em;
	}
}

.rct-f-add-lst {
	width: 100%;
	margin-top: 4.0625em;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 1.875em;
}

.rct-f-add-lst.-inr {
	margin-top: 0;
}

.rct-f-add-lst.-sgk-os {
	position: relative;
}

.rct-f-add-lst.-sgk-os::before {
	content: 'SUGIKOは下記スポーツチームの\Aオフィシャルサポーターです';
	width: 100%;
	font-size: 0.625em;
	font-weight: 700;
	line-height: 1.3;
	text-align: center;
	white-space: pre;
	display: block;
	position: absolute;
	left: 0;
	bottom: calc(100% + 0.5em);
}

.rct-f-add-lst_lnk {
	width: 5em;
	display: block;
}

.rct-f-add-lst_lnk.-hbp {
	width: 12.5em;
}

.rct-f-add-lst_cr {
	margin-top: 0.75em;
	font-size: 0.625em;
	line-height: 1;
	display: block;
}

@media screen and (max-width: 670px) {
	.rct-f-add-lst {
		font-size: clamp(1.2rem, 3.29412vw, 1.4rem);
	}
	.rct-f-cnt-sct_inr .rct-f-add-lst {
		display: none;
	}
}

@media screen and (min-width: 671px) {
	.rct-f-add-lst {
		display: none;
		justify-content: flex-end;
	}
	.rct-f-cnt-sct_inr .rct-f-add-lst {
		display: flex;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.rct-f-add-lst {
		display: flex;
		justify-content: center;
	}
	.rct-f-cnt-sct_inr .rct-f-add-lst {
		display: none;
	}
}

.fxd-ftr {
	width: 100%;
	background-color: #fff;
	display: flex;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 2000;
	backface-visibility: hidden;
}

.is-actv .fxd-ftr {
	transform: translate3d(0, 100%, 0) !important;
}

.is-load .fxd-ftr {
	transition: opacity 450ms cubic-bezier(0.22, 1, 0.36, 1), visibility 450ms cubic-bezier(0.22, 1, 0.36, 1), transform 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

@media screen and (max-width: 670px) {
	.fxd-ftr {
		height: 4.42857em;
		padding: 0.42857em;
	}
	.fxd-ftr > *:nth-child(n+2) {
		margin-left: 0.28571em;
	}
}

@media screen and (min-width: 671px) {
	.fxd-ftr {
		height: 5em;
		padding: 0.5em;
		transform: translate3d(0, 100%, 0);
	}
	.fxd-ftr.is-shw {
		transform: translate3d(0, 0, 0);
	}
	.fxd-ftr > *:nth-child(n+2) {
		margin-left: 0.375em;
	}
}

@media screen and (min-width: 671px) and (max-width: 860px) {
	.fxd-ftr {
		transform: translate3d(0, 0, 0);
	}
}

.cr {
	height: 5em;
	color: #fff;
	font-size: 0.75em;
	line-height: 1.3;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}
